
import { Badge } from "@/components/ui/badge";

type StatusType = 
  | 'updated' 
  | 'created' 
  | 'error' 
  | 'skipped' 
  | 'profile_created' 
  | 'customer_ids_updated'
  | 'customer_ids_linked'
  | 'active' 
  | 'trialing' 
  | 'past_due' 
  | 'canceled' 
  | 'incomplete' 
  | 'incomplete_expired' 
  | 'unpaid' 
  | 'pending' 
  | 'ok' 
  | 'not_needed';

interface StatusBadgeProps {
  status: StatusType | string;
}

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  // Déterminer la variante et le texte en fonction du statut
  const getVariant = (): "default" | "secondary" | "destructive" | "outline" => {
    switch (status) {
      case 'active':
      case 'trialing':
      case 'updated':
      case 'created':
      case 'profile_created':
      case 'customer_ids_updated':
      case 'customer_ids_linked':
      case 'ok':
        return "default";
      case 'past_due':
      case 'pending':
      case 'incomplete':
      case 'skipped':
      case 'not_needed':
        return "secondary";
      case 'error':
      case 'canceled':
      case 'incomplete_expired':
      case 'unpaid':
        return "destructive";
      default:
        return "outline";
    }
  };

  // Traduire le statut pour l'affichage
  const getDisplayText = (): string => {
    switch (status) {
      case 'updated': return "Mis à jour";
      case 'created': return "Créé";
      case 'error': return "Erreur";
      case 'skipped': return "Ignoré";
      case 'profile_created': return "Profil créé";
      case 'customer_ids_updated': return "Customer IDs mis à jour";
      case 'customer_ids_linked': return "Comptes clients liés";
      case 'active': return "Actif";
      case 'trialing': return "Essai";
      case 'past_due': return "Paiement en retard";
      case 'canceled': return "Annulé";
      case 'incomplete': return "Incomplet";
      case 'incomplete_expired': return "Expiré";
      case 'unpaid': return "Impayé";
      case 'pending': return "En attente";
      case 'ok': return "OK";
      case 'not_needed': return "Non nécessaire";
      default: return status as string;
    }
  };

  return (
    <Badge variant={getVariant()}>
      {getDisplayText()}
    </Badge>
  );
};
