
import { useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Loader2, AlertTriangle, CheckCircle, Info } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";

type VerificationResult = {
  processed: number;
  fixed: number;
  errors: number;
  details: Array<{
    id: string;
    email: string;
    profile_status: 'ok' | 'created' | 'error';
    subscription_status: 'ok' | 'not_needed' | 'error';
    message?: string;
  }>;
};

export const DataIntegrityCheck = () => {
  const { session } = useAuth();
  const { toast } = useToast();
  const [isChecking, setIsChecking] = useState(false);
  const [verificationResult, setVerificationResult] = useState<VerificationResult | null>(null);
  const [error, setError] = useState<string | null>(null);

  const checkDataIntegrity = async () => {
    if (!session?.access_token) {
      toast({
        title: "Erreur",
        description: "Vous devez être connecté pour vérifier l'intégrité des données",
        variant: "destructive"
      });
      return;
    }

    try {
      setIsChecking(true);
      setError(null);
      
      console.log("Vérification de l'intégrité des données...");
      console.log("Token auth disponible:", !!session.access_token);
      
      // Envoyer la requête à l'Edge Function avec plus d'informations de débogage
      const { data, error: fnError } = await supabase.functions.invoke('verify-data-integrity', {
        headers: {
          Authorization: `Bearer ${session.access_token}`,
        },
      });

      if (fnError) {
        console.error("Erreur détaillée lors de la vérification d'intégrité:", fnError);
        setError(`Erreur: ${fnError.message || "Erreur de communication avec la fonction"}`);
        toast({
          title: "Échec de la vérification",
          description: fnError.message || "Erreur de communication avec le serveur",
          variant: "destructive"
        });
        return;
      }

      console.log("Réponse brute de la vérification:", data);
      
      if (data && data.results) {
        setVerificationResult(data.results);
        toast({
          title: "Vérification terminée",
          description: `${data.results.processed} utilisateurs vérifiés, ${data.results.fixed} corrigés, ${data.results.errors} erreurs`,
        });
      } else {
        setError("La réponse du serveur n'a pas le format attendu");
        console.error("Format de réponse inattendu:", data);
        toast({
          title: "Réponse invalide",
          description: "Format de réponse inattendu du serveur",
          variant: "destructive"
        });
      }
    } catch (err) {
      console.error("Exception lors de la vérification d'intégrité des données:", err);
      setError(`Exception: ${err.message || "Erreur inconnue"}`);
      toast({
        title: "Erreur",
        description: err.message || "Une erreur inconnue s'est produite",
        variant: "destructive"
      });
    } finally {
      setIsChecking(false);
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'ok':
        return <CheckCircle className="h-4 w-4 text-green-500" />;
      case 'created':
        return <Info className="h-4 w-4 text-blue-500" />;
      case 'error':
        return <AlertTriangle className="h-4 w-4 text-red-500" />;
      case 'not_needed':
        return <Info className="h-4 w-4 text-gray-500" />;
      default:
        return null;
    }
  };

  return (
    <Card className="mt-8">
      <CardHeader>
        <CardTitle className="text-xl flex justify-between items-center">
          Vérification de l'intégrité des données
          <Button 
            onClick={checkDataIntegrity} 
            disabled={isChecking}
            variant="outline"
            className="ml-2"
          >
            {isChecking ? (
              <>
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                Vérification...
              </>
            ) : (
              "Vérifier maintenant"
            )}
          </Button>
        </CardTitle>
        <CardDescription>
          Vérifie que chaque utilisateur a un profil et que les données sont cohérentes
        </CardDescription>
      </CardHeader>
      <CardContent>
        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md mb-4">
            <div className="flex items-center">
              <AlertTriangle className="h-5 w-5 mr-2" />
              <span className="font-medium">Erreur</span>
            </div>
            <p className="mt-1 text-sm">{error}</p>
          </div>
        )}

        {verificationResult && (
          <div className="space-y-4">
            <div className="flex space-x-4">
              <div className="bg-gray-50 p-3 rounded-md flex-1">
                <div className="text-xs font-medium text-gray-500 uppercase">Utilisateurs vérifiés</div>
                <div className="text-2xl font-bold">{verificationResult.processed}</div>
              </div>
              <div className="bg-blue-50 p-3 rounded-md flex-1">
                <div className="text-xs font-medium text-blue-500 uppercase">Profils créés</div>
                <div className="text-2xl font-bold">{verificationResult.fixed}</div>
              </div>
              <div className="bg-red-50 p-3 rounded-md flex-1">
                <div className="text-xs font-medium text-red-500 uppercase">Erreurs</div>
                <div className="text-2xl font-bold">{verificationResult.errors}</div>
              </div>
            </div>

            {verificationResult.details.length > 0 && (
              <div className="mt-4 border rounded-md">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Email</TableHead>
                      <TableHead>Profil</TableHead>
                      <TableHead>Abonnement</TableHead>
                      <TableHead>Message</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {verificationResult.details.map((detail) => (
                      <TableRow key={detail.id}>
                        <TableCell className="font-medium">{detail.email || "N/A"}</TableCell>
                        <TableCell>
                          <div className="flex items-center space-x-1">
                            {getStatusIcon(detail.profile_status)}
                            <Badge 
                              variant={detail.profile_status === 'error' ? "destructive" : 
                                        detail.profile_status === 'created' ? "outline" : "default"}
                            >
                              {detail.profile_status}
                            </Badge>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center space-x-1">
                            {getStatusIcon(detail.subscription_status)}
                            <Badge 
                              variant={detail.subscription_status === 'error' ? "destructive" : 
                                        detail.subscription_status === 'not_needed' ? "outline" : "default"}
                            >
                              {detail.subscription_status}
                            </Badge>
                          </div>
                        </TableCell>
                        <TableCell className="text-sm text-gray-500">
                          {detail.message || "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        )}

        {!verificationResult && !error && !isChecking && (
          <div className="text-center p-6 text-gray-500">
            Cliquez sur "Vérifier maintenant" pour lancer la vérification des données
          </div>
        )}
      </CardContent>
    </Card>
  );
};
