
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Check, Wifi, CreditCard, ShoppingCart, Tv, X, PlusCircle } from "lucide-react";
import { useLocation } from "react-router-dom";
import Navbar from "@/components/Navbar";
import { OnboardingForm } from "@/components/onboarding/OnboardingForm";
import { useAuth } from "@/contexts/AuthContext";
import { SubscriptionInfo } from "@/components/profile/SubscriptionInfo";
import { OnboardingProvider, useOnboarding } from "@/contexts/OnboardingContext";
import { WelcomeStep } from "@/components/onboarding/WelcomeStep";
import { AddressStep } from "@/components/onboarding/AddressStep";
import { SurveyStep } from "@/components/onboarding/SurveyStep";
import { EmailStep } from "@/components/onboarding/EmailStep";
import { PhoneStep } from "@/components/onboarding/PhoneStep";
import { PasswordStep } from "@/components/onboarding/PasswordStep";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { useSubscriptionHandler } from "@/components/profile/subscription/useSubscriptionHandler";
import { useState } from "react";
import { SubscriptionErrorDialog } from "@/components/profile/subscription/SubscriptionErrorDialog";
import NewsletterSubscribeModal from "@/components/NewsletterSubscribeModal";
import { useIsMobile } from "@/hooks/use-mobile";
import { DataPlanModal } from "@/components/profile/subscription/DataPlanModal";

const Commander = () => {
  const location = useLocation();
  const { user } = useAuth();
  const showPayment = location.state?.showPayment || user;
  const [newsletterModalOpen, setNewsletterModalOpen] = useState(false);

  if (!showPayment) {
    return (
      <>
        <Navbar />
        <div className="container mx-auto px-4 py-24">
          <Card className="max-w-2xl mx-auto">
            <OnboardingProvider>
              <CardContent className="pt-6">
                <OnboardingContent />
              </CardContent>
            </OnboardingProvider>
          </Card>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 pt-24 pb-16">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">
            Box Internet Oneli
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Choisissez l'offre qui correspond le mieux à vos besoins sans contrainte.
          </p>
        </div>
        
        <ConnectedSubscriptionOptions newsletterModalOpen={newsletterModalOpen} setNewsletterModalOpen={setNewsletterModalOpen} />
        <NewsletterSubscribeModal open={newsletterModalOpen} onOpenChange={setNewsletterModalOpen} />
      </div>
    </>
  );
};

const ConnectedSubscriptionOptions = ({ newsletterModalOpen, setNewsletterModalOpen }) => {
  const { 
    selectedPlan, 
    setSelectedPlan, 
    isLoading, 
    handleSubscribe, 
    errorDialogOpen, 
    setErrorDialogOpen, 
    errorMessage 
  } = useSubscriptionHandler();
  
  const isMobile = useIsMobile();
  const [dataPlanModalOpen, setDataPlanModalOpen] = useState(false);
  
  const plansConfig = [
    {
      name: "Achat du matériel",
      price: "179,90€",
      priceDetail: "",
      engagement: "",
      badge: "",
      icon: <ShoppingCart className="h-10 w-10 text-primary" />,
      description: "Achat du matériel sans abonnement",
      highlight: "Abonnement 5G non inclus",
      features: [
        "WiFi 6 nouvelle génération",
        "Achat définitif de la box",
        "Compatible tout opérateurs",
        {
          text: "Voir les forfaits",
          button: <Button 
            variant="ghost" 
            size="icon" 
            className="h-5 w-5 ml-1 -mr-6" 
            onClick={(e) => {
              e.stopPropagation();
              window.open("https://bonforfait.fr/forfait-mobile?5g&internet=100", "_blank");
            }}
          >
            <PlusCircle className="h-4 w-4 text-primary" />
          </Button>
        },
        "Support client standard",
        "Installation plug & play",
      ],
      negativeFeatures: [
        "Forfait à souscrire soi-même",
        "Pas de chaînes TV"
      ],
      value: "PURCHASE",
      popular: false,
    },
    {
      name: "Forfait mensuel",
      price: "29,90€",
      priceDetail: "/mois",
      engagement: "",
      badge: "Plus populaire",
      icon: <CreditCard className="h-10 w-10 text-primary" />,
      description: "Accès internet sans fil avec data incluse",
      highlight: "Frais de démarrage 29€\nSans engagement",
      features: [
        "WiFi 6 nouvelle génération",
        {
          text: "100 Go de data inclus par mois",
          button: <Button 
            variant="ghost" 
            size="icon" 
            className="h-5 w-5 ml-1 -mr-6" 
            onClick={(e) => {
              e.stopPropagation();
              setDataPlanModalOpen(true);
            }}
          >
            <PlusCircle className="h-4 w-4 text-primary" />
          </Button>
        },
        "Support client prioritaire",
        "Appareil en location incluse",
        "Installation plug & play",
      ],
      negativeFeatures: ["Pas de chaînes TV"],
      value: "MONTHLY",
      popular: true,
    },
    {
      name: "Bientôt - Offre TV",
      price: "Bientôt",
      priceDetail: "",
      engagement: "",
      badge: "Bientôt",
      icon: <Tv className="h-10 w-10 text-gray-400" />,
      description: "Notre offre TV arrive bientôt",
      highlight: "Restez informés",
      features: [
        "WiFi 6 nouvelle génération",
        "Chaînes TV incluses",
        "Contenu à la demande",
        "Support client prioritaire",
        "Installation facile",
        "Sans engagement",
      ],
      negativeFeatures: [],
      value: "ANNUAL",
      popular: false,
      isNewsletter: true,
      disabled: true,
    }
  ];

  const plans = isMobile 
    ? [plansConfig[1], plansConfig[0], plansConfig[2]]
    : plansConfig;

  const handleSelectPlan = (planValue: string) => {
    setSelectedPlan(planValue as "MONTHLY" | "ANNUAL" | "PURCHASE");
  };

  return (
    <>
      <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {plans.map((plan, index) => (
          <Card 
            key={index} 
            className={`relative overflow-hidden transition-all duration-300 ${
              plan.popular 
                ? 'border-primary shadow-lg md:scale-110 z-10' 
                : 'border-gray-200 hover:border-gray-300 hover:shadow-md'
            } ${plan.name.includes('Bientôt') ? 'opacity-80' : ''}`}
          >
            {plan.badge && (
              <Badge className={`absolute top-4 right-4 ${plan.popular ? 'bg-primary' : 'bg-gray-500'}`}>
                {plan.badge}
              </Badge>
            )}
            <CardContent className="text-center pt-6">
              <div className="mx-auto mb-4">{plan.icon}</div>
              <h3 className="text-2xl font-semibold">{plan.name}</h3>
              <p className="text-muted-foreground">{plan.description}</p>

              <div className="mt-4">
                <div className="flex flex-col items-center">
                  <div className="text-4xl font-bold">
                    {plan.price}
                    <span className="text-lg text-muted-foreground">{plan.priceDetail}</span>
                  </div>
                  {plan.engagement && (
                    <div className="text-sm text-muted-foreground">{plan.engagement}</div>
                  )}
                </div>
                <p className="text-sm font-medium text-primary mt-2 whitespace-pre-line">
                  {plan.highlight}
                </p>
              </div>

              <Separator className="my-6" />

              <ul className="space-y-3 mb-6 text-left">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-start gap-2">
                    <Check className={`h-5 w-5 flex-shrink-0 mt-0.5 ${
                      plan.name.includes('Bientôt') ? 'text-gray-400' : 'text-green-500'
                    }`} />
                    {typeof feature === 'string' ? (
                      <span className={plan.name.includes('Bientôt') ? 'text-gray-500' : ''}>
                        {feature}
                      </span>
                    ) : (
                      <div className="flex items-center">
                        <span className={plan.name.includes('Bientôt') ? 'text-gray-500' : ''}>
                          {feature.text}
                        </span>
                        {feature.button}
                      </div>
                    )}
                  </li>
                ))}
                {plan.negativeFeatures && plan.negativeFeatures.map((feature, featureIndex) => (
                  <li key={`neg-${featureIndex}`} className="flex items-start gap-2">
                    <X className="h-5 w-5 flex-shrink-0 mt-0.5 text-gray-400" />
                    <span className="text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </CardContent>
            <CardFooter className="pt-0 pb-6">
              {plan.isNewsletter ? (
                <Button 
                  className="w-full"
                  variant="outline"
                  onClick={() => setNewsletterModalOpen(true)}
                  disabled={plan.disabled}
                >
                  Rester informé
                </Button>
              ) : (
                <Button 
                  className={`w-full ${plan.popular ? 'bg-primary' : ''}`}
                  variant={plan.popular ? "default" : "outline"}
                  disabled={plan.disabled || isLoading}
                  onClick={() => {
                    handleSelectPlan(plan.value);
                    handleSubscribe();
                  }}
                >
                  {isLoading && selectedPlan === plan.value ? "Chargement..." : plan.popular ? "Choisir cette offre" : "Sélectionner"}
                </Button>
              )}
            </CardFooter>
          </Card>
        ))}
      </div>

      <div className="max-w-4xl mx-auto mt-24">
        <div className="relative rounded-xl overflow-hidden">
          <img 
            src="/lovable-uploads/8121f16e-5885-4007-a489-6897c95e3313.png" 
            alt="Enceinte Marshall et Google Home" 
            className="w-full h-auto object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent"></div>
          <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
            <h3 className="text-2xl font-bold mb-2">Connectez tous vos appareils</h3>
            <p className="text-lg">Profitez d'une connexion stable et rapide pour tous vos objets connectés</p>
          </div>
        </div>
      </div>

      <DataPlanModal 
        open={dataPlanModalOpen} 
        onOpenChange={setDataPlanModalOpen} 
      />

      <SubscriptionErrorDialog 
        open={errorDialogOpen} 
        onOpenChange={setErrorDialogOpen}
        errorMessage={errorMessage}
      />
    </>
  );
};

const OnboardingContent = () => {
  const { currentStep } = useOnboarding();

  switch (currentStep) {
    case 1:
      return <WelcomeStep />;
    case 2:
      return <AddressStep />;
    case 3:
      return <SurveyStep />;
    case 4:
      return <EmailStep />;
    case 5:
      return <PhoneStep />;
    case 6:
      return <PasswordStep />;
    case 7:
      return <SubscriptionOptions />;
    default:
      return null;
  }
};

const SubscriptionOptions = () => {
  const { data, nextStep, saveStep } = useOnboarding();
  const isMobile = useIsMobile();
  const [dataPlanModalOpen, setDataPlanModalOpen] = useState(false);
  
  const plansConfig = [
    {
      name: "Achat du matériel",
      price: "179,90€",
      period: "",
      engagement: "",
      badge: "",
      icon: <ShoppingCart className="h-10 w-10 text-primary" />,
      description: "Achat du matériel sans abonnement",
      highlight: "Abonnement 5G non inclus",
      features: [
        "WiFi 6 nouvelle génération",
        "Achat définitif de la box",
        "Compatible tout opérateurs",
        {
          text: "Voir les forfaits",
          button: <Button 
            variant="ghost" 
            size="icon" 
            className="h-5 w-5 ml-1 -mr-6" 
            onClick={(e) => {
              e.stopPropagation();
              window.open("https://bonforfait.fr/forfait-mobile?5g&internet=100", "_blank");
            }}
          >
            <PlusCircle className="h-4 w-4 text-primary" />
          </Button>
        },
        "Support client standard",
        "Installation plug & play",
      ],
      negativeFeatures: [
        "Forfait à souscrire soi-même",
        "Pas de chaînes TV"
      ],
      value: "PURCHASE",
      popular: false,
      cta: "Acheter le matériel",
    },
    {
      name: "Forfait mensuel",
      price: "29,90€",
      period: "/mois",
      engagement: "",
      badge: "Plus populaire",
      icon: <CreditCard className="h-10 w-10 text-primary" />,
      description: "Accès internet sans fil avec data incluse",
      highlight: "Frais de démarrage 29€\nSans engagement",
      features: [
        "WiFi 6 nouvelle génération",
        {
          text: "100 Go de data inclus par mois",
          button: <Button 
            variant="ghost" 
            size="icon" 
            className="h-5 w-5 ml-1 -mr-6" 
            onClick={(e) => {
              e.stopPropagation();
              setDataPlanModalOpen(true);
            }}
          >
            <PlusCircle className="h-4 w-4 text-primary" />
          </Button>
        },
        "Support client prioritaire",
        "Appareil en location incluse",
        "Installation plug & play",
      ],
      negativeFeatures: ["Pas de chaînes TV"],
      value: "MONTHLY",
      popular: true,
    },
    {
      name: "Bientôt - Offre TV",
      price: "Bientôt",
      period: "",
      engagement: "",
      badge: "Bientôt",
      icon: <Tv className="h-10 w-10 text-gray-400" />,
      description: "Notre offre TV arrive bientôt",
      highlight: "Restez informés",
      features: [
        "WiFi 6 nouvelle génération",
        "Chaînes TV incluses",
        "Contenu à la demande",
        "Support client prioritaire",
        "Installation facile",
        "Sans engagement",
      ],
      negativeFeatures: [],
      value: "ANNUAL",
      popular: false,
      disabled: true,
    }
  ];

  const plans = isMobile 
    ? [plansConfig[1], plansConfig[0], plansConfig[2]]
    : plansConfig;

  const handleSelectPlan = async (planValue: string) => {
    await saveStep();
    nextStep();
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold">Choisissez votre offre</h1>
        <p className="text-muted-foreground">
          Sélectionnez l'offre qui correspond le mieux à vos besoins
        </p>
      </div>

      <div className="bg-primary/10 rounded-lg p-4 flex items-center gap-3">
        <Wifi className="h-5 w-5 text-primary" />
        <div>
          <div className="font-medium">Internet partout, simple et immédiat</div>
          <div className="text-sm text-muted-foreground">
            Des solutions adaptées à vos besoins sans contrainte
          </div>
        </div>
      </div>

      <div className="grid gap-6">
        {plans.map((plan, index) => (
          <Card 
            key={index} 
            className={`relative overflow-hidden transition-all duration-300 ${
              plan.popular 
                ? 'border-primary shadow-md' 
                : 'border-gray-200 hover:border-gray-300 hover:shadow-sm'
            } ${plan.name.includes('Bientôt') ? 'opacity-80' : ''}`}
          >
            {plan.badge && (
              <Badge className={`absolute top-4 right-4 ${plan.popular ? 'bg-primary' : 'bg-gray-500'}`}>
                {plan.badge}
              </Badge>
            )}
            <CardContent className="p-6">
              <div className="flex items-start gap-4">
                <div>{plan.icon}</div>
                <div>
                  <h3 className="text-xl font-semibold">{plan.name}</h3>
                  <p className="text-muted-foreground">{plan.description}</p>
                </div>
              </div>
              
              <div className="mt-4">
                <div className="text-2xl font-bold">
                  {plan.price}
                  <span className="text-base text-muted-foreground">{plan.period}</span>
                </div>
                {plan.engagement && (
                  <div className="text-sm text-muted-foreground">{plan.engagement}</div>
                )}
                <p className="text-sm font-medium text-primary mt-1">
                  {plan.highlight}
                </p>
              </div>
              
              <Separator className="my-4" />
              
              <ul className="space-y-3">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-start gap-2">
                    <Check className={`h-5 w-5 flex-shrink-0 mt-0.5 ${
                      plan.name.includes('Bientôt') ? 'text-gray-400' : 'text-green-500'
                    }`} />
                    {typeof feature === 'string' ? (
                      <span className={plan.name.includes('Bientôt') ? 'text-gray-500' : ''}>
                        {feature}
                      </span>
                    ) : (
                      <div className="flex items-center">
                        <span className={plan.name.includes('Bientôt') ? 'text-gray-500' : ''}>
                          {feature.text}
                        </span>
                        {feature.button}
                      </div>
                    )}
                  </li>
                ))}
                {plan.negativeFeatures && plan.negativeFeatures.length > 0 && plan.negativeFeatures.map((feature, featureIndex) => (
                  <li key={`neg-${featureIndex}`} className="flex items-start gap-2">
                    <X className="h-5 w-5 flex-shrink-0 mt-0.5 text-gray-400" />
                    <span className="text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
              
              <Button 
                className="w-full mt-6"
                variant={plan.popular ? "default" : "outline"}
                disabled={plan.disabled}
                onClick={() => handleSelectPlan(plan.value)}
              >
                {plan.popular ? "Choisir cette offre" : "Sélectionner"}
              </Button>
            </CardContent>
          </Card>
        ))}
      </div>

      <DataPlanModal 
        open={dataPlanModalOpen} 
        onOpenChange={setDataPlanModalOpen} 
      />
    </div>
  );
};

export default Commander;
