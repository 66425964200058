
import { useState, useEffect } from "react";
import type { Tables } from "@/integrations/supabase/types";

type Profile = Tables<"profiles">;
type Subscription = Tables<"subscriptions">;

interface UserWithDetails extends Profile {
  subscriptions?: Subscription[] | null;
  trial_end?: string | null;
  email?: string;
  documents?: {
    bank_details_path: string | null;
    identity_doc_path: string | null;
  } | null;
}

type SortKey = keyof UserWithDetails | 'trial_end' | 'subscription_status';

type SortConfig = {
  key: SortKey;
  direction: 'asc' | 'desc';
} | null;

// Status priority for sorting (higher = more important)
const statusPriority: Record<string, number> = {
  'active': 100,
  'trialing': 90,
  'past_due': 80,
  'incomplete': 70,
  'unpaid': 60,
  'pending': 50,
  'incomplete_expired': 40,
  'canceled': 30,
  'no_subscription': 10,
  'unknown': 0,
};

export const useSortableData = (initialData: UserWithDetails[]) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'created_at',
    direction: 'desc'
  });
  const [data, setData] = useState<UserWithDetails[]>([]);

  // Appliquer le tri initial quand les données changent
  useEffect(() => {
    if (initialData.length > 0) {
      const sortedData = applySorting([...initialData], sortConfig?.key || 'created_at', sortConfig?.direction || 'desc');
      setData(sortedData);
      
      // Débogage pour vérifier les documents dans les données triées
      const richardUser = sortedData.find(user => user.email === 'richardwinckels@gmail.com');
      if (richardUser) {
        console.log("Documents de Richard après tri:", richardUser.documents);
      }
    }
  }, [initialData, sortConfig]);

  const getUserSubscriptionStatus = (user: UserWithDetails): string => {
    if (!user.subscriptions || user.subscriptions.length === 0) {
      return 'no_subscription';
    }
    
    // Vérifier si l'utilisateur a un abonnement actif
    for (const subscription of user.subscriptions) {
      // Si le statut est vide ou null mais que nous avons un stripe_subscription_id, 
      // considérons cela comme 'active' car c'est probablement un problème de synchronisation
      if ((!subscription.status || subscription.status === 'no_subscription') && subscription.stripe_subscription_id) {
        return 'active';
      }
      
      // Si nous avons un statut valide et non annulé, retourner ce statut
      if (subscription.status && 
          subscription.status !== 'no_subscription' && 
          subscription.status !== 'canceled' &&
          subscription.status !== 'incomplete_expired') {
        return subscription.status;
      }
    }
    
    // Si nous avons un abonnement avec un statut annulé, retourner ce statut
    const canceledSub = user.subscriptions.find(sub => sub.status === 'canceled');
    if (canceledSub) return 'canceled';
    
    // Si nous avons un abonnement avec un statut expiré, retourner ce statut
    const expiredSub = user.subscriptions.find(sub => sub.status === 'incomplete_expired');
    if (expiredSub) return 'incomplete_expired';
    
    // Retourner le statut du premier abonnement s'il existe, sinon 'unknown'
    return user.subscriptions[0]?.status || 'unknown';
  };

  // Helper function to apply sorting to an array
  const applySorting = (items: UserWithDetails[], key: SortKey, direction: 'asc' | 'desc'): UserWithDetails[] => {
    return [...items].sort((a, b) => {
      let aValue: any = a[key as keyof UserWithDetails];
      let bValue: any = b[key as keyof UserWithDetails];

      if (key === 'subscription_status') {
        // Sort by subscription status priority
        const aStatus = getUserSubscriptionStatus(a);
        const bStatus = getUserSubscriptionStatus(b);
        aValue = statusPriority[aStatus] || 0;
        bValue = statusPriority[bStatus] || 0;
      }

      if (key === 'trial_end' || key === 'created_at') {
        aValue = aValue ? new Date(aValue).getTime() : 0;
        bValue = bValue ? new Date(bValue).getTime() : 0;
      }

      if (aValue === null || aValue === undefined) return direction === 'asc' ? -1 : 1;
      if (bValue === null || bValue === undefined) return direction === 'asc' ? 1 : -1;
      
      if (aValue === bValue) return 0;

      const result = aValue < bValue ? -1 : 1;
      return direction === 'asc' ? result : -result;
    });
  };

  const sortData = (key: SortKey) => {
    console.log(`Sorting by ${key} (current: ${sortConfig?.key}, ${sortConfig?.direction})`);
    
    // Toggle direction if clicking the same column
    let direction: 'asc' | 'desc' = 'asc';
    
    if (sortConfig?.key === key) {
      direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    }
    
    // Update sort configuration
    const newSortConfig = { key, direction };
    setSortConfig(newSortConfig);
  };

  return { data, setData, sortData, sortConfig, getUserSubscriptionStatus };
};
