
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { ArrowUpDown, ChevronDown, ChevronUp, Download, Settings } from "lucide-react";
import { type Tables } from "@/integrations/supabase/types";
import { useState } from "react";
import { EditSubscriptionStatus } from "./EditSubscriptionStatus";
import { EditTrialEnd } from "./EditTrialEnd";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

type Profile = Tables<"profiles">;
type Subscription = Tables<"subscriptions">;

interface UserWithDetails extends Profile {
  subscriptions?: Subscription[] | null;
  trial_end?: string | null;
  email?: string;
  documents?: {
    bank_details_path: string | null;
    identity_doc_path: string | null;
  } | null;
}

type SortKey = keyof UserWithDetails | 'trial_end' | 'subscription_status';

type SortConfig = {
  key: SortKey;
  direction: 'asc' | 'desc';
} | null;

interface UserTableProps {
  users: UserWithDetails[];
  onSort: (key: SortKey) => void;
  sortConfig: SortConfig;
  getUserSubscriptionStatus: (user: UserWithDetails) => string;
}

export const UserTable = ({ users, onSort, sortConfig, getUserSubscriptionStatus }: UserTableProps) => {
  const [editStatusUserId, setEditStatusUserId] = useState<string | null>(null);
  const [editTrialEndUserId, setEditTrialEndUserId] = useState<string | null>(null);
  const { toast } = useToast();
  
  // Helper function to render the sort indicator
  const getSortIcon = (key: SortKey) => {
    if (sortConfig?.key !== key) {
      return <ArrowUpDown className="ml-2 h-4 w-4" />;
    }
    
    return sortConfig.direction === 'asc' 
      ? <ChevronUp className="ml-2 h-4 w-4" /> 
      : <ChevronDown className="ml-2 h-4 w-4" />;
  };
  
  const getStatusBadgeStyle = (status: string) => {
    switch (status) {
      case 'active':
        return "bg-green-50 text-green-700 border-green-200";
      case 'trialing':
        return "bg-blue-50 text-blue-700 border-blue-200";
      case 'past_due':
        return "bg-amber-50 text-amber-700 border-amber-200";
      case 'canceled':
        return "bg-red-50 text-red-700 border-red-200";
      case 'incomplete':
      case 'incomplete_expired':
        return "bg-orange-50 text-orange-700 border-orange-200";
      case 'unpaid':
        return "bg-purple-50 text-purple-700 border-purple-200";
      case 'pending':
        return "bg-gray-50 text-gray-700 border-gray-200";
      case 'no_subscription':
      default:
        return "bg-gray-50 text-gray-700 border-gray-200";
    }
  };

  const formatStatusLabel = (status: string) => {
    switch (status) {
      case 'active': return 'Actif';
      case 'trialing': return 'Période d\'essai';
      case 'past_due': return 'Paiement en retard';
      case 'canceled': return 'Annulé';
      case 'incomplete': return 'Incomplet';
      case 'incomplete_expired': return 'Expiré';
      case 'unpaid': return 'Impayé';
      case 'pending': return 'En attente';
      case 'no_subscription': return 'Pas d\'abonnement';
      case 'unknown': return 'Inconnu';
      default: return status;
    }
  };
  
  const getUserTrialEnd = (user: UserWithDetails) => {
    return user.trial_end ? new Date(user.trial_end) : null;
  };

  const handleEditStatus = (userId: string) => {
    setEditStatusUserId(userId);
  };

  const handleEditTrialEnd = (userId: string) => {
    setEditTrialEndUserId(userId);
  };

  // Improved handle download function with better debugging and error handling
  const handleDownload = async (filePath: string | null, fileName: string) => {
    if (!filePath) {
      toast({
        title: "Erreur",
        description: "Aucun fichier disponible",
        variant: "destructive",
      });
      return;
    }

    try {
      // Enhanced debugging
      console.log("[UserTable] Tentative de téléchargement du fichier:", filePath);
      
      // Parse the filepath to get the actual file name (without user_id prefix)
      // This helps if the path includes a directory structure
      const filePathParts = filePath.split('/');
      const actualFilePath = filePathParts.length > 1 ? filePath : filePath;
      
      // Determine which bucket to use based on the file path
      const isIdentityDoc = filePath.includes('identity') || filePath.includes('_identity.');
      const isBankDetails = filePath.includes('bank') || filePath.includes('_bank.');
      
      let bucket = 'identity_documents'; // Default bucket
      
      if (isIdentityDoc) {
        bucket = 'identity_documents';
        console.log("[UserTable] Document type: Pièce d'identité, Bucket:", bucket);
      } else if (isBankDetails) {
        bucket = 'bank_details';
        console.log("[UserTable] Document type: RIB, Bucket:", bucket);
      } else {
        console.log("[UserTable] Type de document non reconnu, utilisation du bucket par défaut:", bucket);
      }
      
      console.log(`[UserTable] Téléchargement depuis le bucket: ${bucket}, chemin: ${actualFilePath}`);
      
      // Attempt to download the file
      const { data, error } = await supabase.storage
        .from(bucket)
        .download(actualFilePath);

      if (error) {
        console.error("[UserTable] Erreur de téléchargement:", error);
        
        // If the first attempt fails, try with the alternative bucket
        const alternativeBucket = bucket === 'identity_documents' ? 'bank_details' : 'identity_documents';
        console.log(`[UserTable] Tentative avec le bucket alternatif: ${alternativeBucket}`);
        
        const secondAttempt = await supabase.storage
          .from(alternativeBucket)
          .download(actualFilePath);
          
        if (secondAttempt.error) {
          console.error("[UserTable] Seconde tentative échouée:", secondAttempt.error);
          throw new Error(`Échec du téléchargement: ${error.message}. Seconde tentative: ${secondAttempt.error.message}`);
        }
        
        // If second attempt is successful
        if (secondAttempt.data) {
          console.log("[UserTable] Téléchargement réussi avec le bucket alternatif");
          // Create a download link for second attempt
          const url = window.URL.createObjectURL(secondAttempt.data);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          
          toast({
            title: "Succès",
            description: "Le fichier a été téléchargé",
          });
          return;
        }
      }

      // Create a download link for first successful attempt
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      toast({
        title: "Succès",
        description: "Le fichier a été téléchargé",
      });
    } catch (error: any) {
      console.error('[UserTable] Erreur détaillée lors du téléchargement:', error);
      
      // Show more details in the error toast
      toast({
        title: "Erreur",
        description: `Impossible de télécharger le fichier: ${error.message || "Erreur inconnue"}`,
        variant: "destructive",
      });
      
      // Try to list files in both buckets to debug
      try {
        console.log("[UserTable] Listing des fichiers dans les buckets pour déboguer:");
        
        const identityList = await supabase.storage.from('identity_documents').list();
        console.log("Fichiers dans identity_documents:", identityList);
        
        const bankList = await supabase.storage.from('bank_details').list();
        console.log("Fichiers dans bank_details:", bankList);
      } catch (listError) {
        console.error("Erreur lors du listing des fichiers:", listError);
      }
    }
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>
              <Button 
                variant="ghost" 
                onClick={() => onSort('last_name')}
              >
                Nom
                {getSortIcon('last_name')}
              </Button>
            </TableHead>
            <TableHead>
              <Button 
                variant="ghost" 
                onClick={() => onSort('first_name')}
              >
                Prénom
                {getSortIcon('first_name')}
              </Button>
            </TableHead>
            <TableHead>
              <Button 
                variant="ghost" 
                onClick={() => onSort('email')}
              >
                Email
                {getSortIcon('email')}
              </Button>
            </TableHead>
            <TableHead>
              <Button 
                variant="ghost" 
                onClick={() => onSort('phone_number')}
              >
                Téléphone
                {getSortIcon('phone_number')}
              </Button>
            </TableHead>
            <TableHead>
              <Button 
                variant="ghost" 
                onClick={() => onSort('address')}
              >
                Adresse
                {getSortIcon('address')}
              </Button>
            </TableHead>
            <TableHead>RIB</TableHead>
            <TableHead>Pièce d'identité</TableHead>
            <TableHead>
              <Button 
                variant="ghost" 
                onClick={() => onSort('subscription_status')}
              >
                Abonnement
                {getSortIcon('subscription_status')}
              </Button>
            </TableHead>
            <TableHead>
              <Button 
                variant="ghost" 
                onClick={() => onSort('trial_end')}
              >
                Fin de la période d'essai
                {getSortIcon('trial_end')}
              </Button>
            </TableHead>
            <TableHead>
              <Button 
                variant="ghost" 
                onClick={() => onSort('created_at')}
              >
                Date d'inscription
                {getSortIcon('created_at')}
              </Button>
            </TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        
        <TableBody>
          {users.map((user) => {
            const status = getUserSubscriptionStatus(user);
            const hasStripeSubscription = user.subscriptions?.[0]?.stripe_subscription_id;
            
            // Enhanced debugging for specific users
            if (user.email?.includes('richard') || user.email?.includes('winckels')) {
              console.log(`[UserTable] Documents de ${user.email} dans UserTable:`, 
                JSON.stringify({
                  documents: user.documents,
                  userId: user.id,
                  bank_path: user.documents?.bank_details_path,
                  identity_path: user.documents?.identity_doc_path
                }, null, 2)
              );
            }
            
            return (
              <TableRow key={user.id}>
                <TableCell className="font-medium">{user.last_name}</TableCell>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>
                  {user.email ? (
                    <span className="text-blue-600">{user.email}</span>
                  ) : (
                    <span className="text-muted-foreground">Non renseigné</span>
                  )}
                </TableCell>
                <TableCell>
                  {user.phone_number ? (
                    user.phone_number
                  ) : (
                    <span className="text-muted-foreground">Non renseigné</span>
                  )}
                </TableCell>
                <TableCell>
                  {user.address ? (
                    <span className="text-sm">{user.address}</span>
                  ) : (
                    <span className="text-muted-foreground text-sm">Non renseigné</span>
                  )}
                </TableCell>
                <TableCell>
                  {user.documents?.bank_details_path ? (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        console.log("[UserTable] Clic pour télécharger RIB:", user.documents?.bank_details_path);
                        handleDownload(user.documents?.bank_details_path, `RIB_${user.last_name}_${user.first_name}.pdf`);
                      }}
                      title="Télécharger le RIB"
                    >
                      <Download className="h-4 w-4" />
                    </Button>
                  ) : (
                    <span className="text-muted-foreground text-sm">Non fourni</span>
                  )}
                </TableCell>
                <TableCell>
                  {user.documents?.identity_doc_path ? (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        console.log("[UserTable] Clic pour télécharger pièce d'identité:", user.documents?.identity_doc_path);
                        handleDownload(user.documents?.identity_doc_path, `ID_${user.last_name}_${user.first_name}.pdf`);
                      }}
                      title="Télécharger la pièce d'identité"
                    >
                      <Download className="h-4 w-4" />
                    </Button>
                  ) : (
                    <span className="text-muted-foreground text-sm">Non fourni</span>
                  )}
                </TableCell>
                <TableCell>
                  <Badge 
                    variant="outline" 
                    className={getStatusBadgeStyle(status)}
                    title={hasStripeSubscription ? `ID: ${hasStripeSubscription}` : ""}
                  >
                    {formatStatusLabel(status)}
                    {hasStripeSubscription && status === 'no_subscription' && " (ID présent)"}
                  </Badge>
                </TableCell>
                <TableCell>
                  {user.trial_end ? (
                    new Date(user.trial_end).toLocaleDateString('fr-FR')
                  ) : (
                    <span className="text-muted-foreground">-</span>
                  )}
                </TableCell>
                <TableCell>
                  {new Date(user.created_at).toLocaleString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon">
                        <Settings className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem onClick={() => handleEditStatus(user.id)}>
                        Modifier le statut
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => handleEditTrialEnd(user.id)}>
                        Modifier la fin d'essai
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {users.map((user) => (
        <div key={`${user.id}-modals`}>
          {editStatusUserId === user.id && (
            <EditSubscriptionStatus
              userId={user.id}
              isActive={getUserSubscriptionStatus(user) === 'active' || getUserSubscriptionStatus(user) === 'trialing'}
              open={editStatusUserId === user.id}
              onOpenChange={(open) => {
                if (!open) setEditStatusUserId(null);
              }}
            />
          )}
          
          {editTrialEndUserId === user.id && (
            <EditTrialEnd
              userId={user.id}
              currentTrialEnd={getUserTrialEnd(user)}
              open={editTrialEndUserId === user.id}
              onOpenChange={(open) => {
                if (!open) setEditTrialEndUserId(null);
              }}
            />
          )}
        </div>
      ))}
    </>
  );
};
