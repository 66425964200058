
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "@/components/ui/select";
import { useOnboarding } from "@/contexts/OnboardingContext";
import { Smartphone, Tv, Video, Gamepad } from "lucide-react";

export const SurveyStep = () => {
  const { data, updateData, nextStep, previousStep, saveStep } = useOnboarding();
  const [errors, setErrors] = useState<{
    householdSize?: string;
    streamingTime?: string;
  }>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Reset errors
    setErrors({});
    
    // Validation
    const newErrors: {
      householdSize?: string;
      streamingTime?: string;
    } = {};
    
    if (!data.householdSize) {
      newErrors.householdSize = "Ce champ est obligatoire";
    }
    
    if (!data.streamingTime) {
      newErrors.streamingTime = "Ce champ est obligatoire";
    }
    
    // If there are errors, display them and stop
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    await saveStep();
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold">Dites-nous en plus sur vos habitudes</h1>
        <p className="text-muted-foreground">
          Ces informations nous aideront à vous proposer la meilleure offre
        </p>
      </div>

      {/* 5G Coverage Question */}
      <div className="space-y-4">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-primary/10 rounded-full">
            <Smartphone className="h-5 w-5 text-primary" />
          </div>
          <Label className="text-lg font-medium">Est-ce que vous captez bien la 5G chez vous ?</Label>
        </div>
        
        <RadioGroup
          value={data.has5GCoverage || "unknown"}
          onValueChange={(value) => updateData({ has5GCoverage: value as "yes" | "no" | "unknown" })}
          className="grid grid-cols-1 md:grid-cols-3 gap-4"
        >
          <Label
            htmlFor="5g-yes"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="yes" id="5g-yes" />
              <span>Oui</span>
            </div>
          </Label>
          <Label
            htmlFor="5g-no"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="no" id="5g-no" />
              <span>Non</span>
            </div>
          </Label>
          <Label
            htmlFor="5g-unknown"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="unknown" id="5g-unknown" />
              <span>Je ne sais pas</span>
            </div>
          </Label>
        </RadioGroup>
      </div>

      {/* Household Size Question */}
      <div className="space-y-4">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-primary/10 rounded-full">
            <svg className="h-5 w-5 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
          </div>
          <Label className="text-lg font-medium">
            Combien de personnes vivent dans votre foyer ? <span className="text-red-500">*</span>
          </Label>
        </div>
        
        <Select 
          value={data.householdSize || ""} 
          onValueChange={(value) => {
            updateData({ householdSize: value });
            if (errors.householdSize) {
              setErrors(prev => ({ ...prev, householdSize: undefined }));
            }
          }}
        >
          <SelectTrigger className={`w-full ${errors.householdSize ? "border-red-500 ring-1 ring-red-500" : ""}`}>
            <SelectValue placeholder="Sélectionnez le nombre de personnes" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="1">1 personne</SelectItem>
            <SelectItem value="2">2 personnes</SelectItem>
            <SelectItem value="3">3 personnes</SelectItem>
            <SelectItem value="4">4 personnes</SelectItem>
            <SelectItem value="5">5 personnes</SelectItem>
            <SelectItem value="more">Plus de 5 personnes</SelectItem>
          </SelectContent>
        </Select>
        {errors.householdSize && <p className="text-sm text-red-500">{errors.householdSize}</p>}
      </div>

      {/* TV Method Question */}
      <div className="space-y-4">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-primary/10 rounded-full">
            <Tv className="h-5 w-5 text-primary" />
          </div>
          <Label className="text-lg font-medium">Comment regardez-vous la TV ?</Label>
        </div>
        
        <RadioGroup
          value={data.tvMethod || "unknown"}
          onValueChange={(value) => updateData({ tvMethod: value as "box" | "smart_tv" | "tnt" | "dont_watch" | "unknown" })}
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
        >
          <Label
            htmlFor="tv-box"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="box" id="tv-box" />
              <span>Via une box internet</span>
            </div>
          </Label>
          <Label
            htmlFor="tv-smart"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="smart_tv" id="tv-smart" />
              <span>Via une Smart TV / apps (Molotov, myCanal…)</span>
            </div>
          </Label>
          <Label
            htmlFor="tv-tnt"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="tnt" id="tv-tnt" />
              <span>Par TNT via l'antenne</span>
            </div>
          </Label>
          <Label
            htmlFor="tv-dont-watch"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="dont_watch" id="tv-dont-watch" />
              <span>Je ne regarde pas la TV</span>
            </div>
          </Label>
          <Label
            htmlFor="tv-unknown"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="unknown" id="tv-unknown" />
              <span>Je ne sais pas</span>
            </div>
          </Label>
        </RadioGroup>
      </div>

      {/* Streaming Time Question */}
      <div className="space-y-4">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-primary/10 rounded-full">
            <Video className="h-5 w-5 text-primary" />
          </div>
          <Label className="text-lg font-medium">
            Chaque jour, combien de temps votre foyer passe-t-il (en tout) à regarder des vidéos en streaming comme YouTube ou Netflix ? <span className="text-red-500">*</span>
          </Label>
        </div>
        
        <Select 
          value={data.streamingTime || ""} 
          onValueChange={(value) => {
            updateData({ streamingTime: value });
            if (errors.streamingTime) {
              setErrors(prev => ({ ...prev, streamingTime: undefined }));
            }
          }}
        >
          <SelectTrigger className={`w-full ${errors.streamingTime ? "border-red-500 ring-1 ring-red-500" : ""}`}>
            <SelectValue placeholder="Sélectionnez une durée" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="less-than-1h">Moins d'1 heure</SelectItem>
            <SelectItem value="1-2h">1 à 2 heures</SelectItem>
            <SelectItem value="2-4h">2 à 4 heures</SelectItem>
            <SelectItem value="4-6h">4 à 6 heures</SelectItem>
            <SelectItem value="more-than-6h">Plus de 6 heures</SelectItem>
          </SelectContent>
        </Select>
        {errors.streamingTime && <p className="text-sm text-red-500">{errors.streamingTime}</p>}
      </div>

      {/* Gaming Question */}
      <div className="space-y-4">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-primary/10 rounded-full">
            <Gamepad className="h-5 w-5 text-primary" />
          </div>
          <Label className="text-lg font-medium">Y a-t-il des personnes dans votre foyer qui jouent aux jeux vidéo sur PC, PlayStation ou Xbox ?</Label>
        </div>
        
        <RadioGroup
          value={data.playsGames || "unknown"}
          onValueChange={(value) => updateData({ playsGames: value as "yes" | "no" | "unknown" })}
          className="grid grid-cols-1 md:grid-cols-3 gap-4"
        >
          <Label
            htmlFor="games-yes"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="yes" id="games-yes" />
              <span>Oui</span>
            </div>
          </Label>
          <Label
            htmlFor="games-no"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="no" id="games-no" />
              <span>Non</span>
            </div>
          </Label>
          <Label
            htmlFor="games-unknown"
            className="flex items-center justify-between rounded-lg border p-4 cursor-pointer hover:bg-gray-50"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem value="unknown" id="games-unknown" />
              <span>Je ne sais pas</span>
            </div>
          </Label>
        </RadioGroup>
      </div>

      <div className="flex gap-4">
        <Button type="button" variant="outline" onClick={previousStep} className="w-full">
          Retour
        </Button>
        <Button type="submit" className="w-full">
          Continuer
        </Button>
      </div>
    </form>
  );
};
