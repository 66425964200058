
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useOnboarding } from "@/contexts/OnboardingContext";
import { useState } from "react";

export const WelcomeStep = () => {
  const { data, updateData, nextStep, saveStep } = useOnboarding();
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!data.first_name || !data.last_name) {
      setError("Veuillez remplir tous les champs");
      return;
    }
    await saveStep();
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold">Bienvenue chez Oneli</h1>
        <p className="text-muted-foreground">Comment vous appelez-vous ?</p>
      </div>

      <div className="space-y-4">
        <div>
          <Label htmlFor="first_name">Prénom</Label>
          <Input
            id="first_name"
            value={data.first_name}
            onChange={(e) => updateData({ first_name: e.target.value })}
            placeholder="Jean"
          />
        </div>

        <div>
          <Label htmlFor="last_name">Nom</Label>
          <Input
            id="last_name"
            value={data.last_name}
            onChange={(e) => updateData({ last_name: e.target.value })}
            placeholder="Dupont"
          />
        </div>
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}

      <Button type="submit" className="w-full">
        Continuer
      </Button>
    </form>
  );
};
