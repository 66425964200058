import Navbar from "@/components/Navbar";

const CGV = () => {
  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      
      <main className="container mx-auto px-4 py-24 prose prose-slate max-w-4xl">
        <h1>Conditions Générales de Vente</h1>

        <h2>1. Objet</h2>
        <p>
          Les présentes Conditions Générales de Vente (CGV) régissent les relations 
          contractuelles entre Oneli et ses clients dans le cadre de la fourniture 
          de services d'accès à Internet.
        </p>

        <h2>2. Services</h2>
        <p>
          Oneli propose des services d'accès à Internet sans fil. Les caractéristiques 
          et modalités de chaque offre sont détaillées sur le site oneli.fr.
        </p>

        <h2>3. Prix et paiement</h2>
        <p>
          Les prix sont indiqués en euros TTC. Le paiement s'effectue mensuellement 
          par prélèvement automatique ou carte bancaire.
        </p>
        <p>
          Oneli se réserve le droit de modifier ses tarifs à tout moment, sous réserve 
          d'en informer le client un mois avant l'entrée en vigueur des nouveaux tarifs.
        </p>

        <h2>4. Durée et résiliation</h2>
        <p>
          Les services sont proposés sans engagement de durée. Le client peut résilier 
          son abonnement à tout moment, la résiliation prenant effet à la fin du mois 
          en cours.
        </p>

        <h2>5. Obligations du client</h2>
        <p>
          Le client s'engage à :
        </p>
        <ul>
          <li>Fournir des informations exactes lors de son inscription</li>
          <li>Utiliser le service conformément à la législation en vigueur</li>
          <li>Ne pas revendre ou partager sa connexion avec des tiers</li>
          <li>Maintenir ses équipements en bon état de fonctionnement</li>
        </ul>

        <h2>6. Obligations d'Oneli</h2>
        <p>
          Oneli s'engage à :
        </p>
        <ul>
          <li>Fournir un service conforme aux caractéristiques annoncées</li>
          <li>Assurer le support technique du service</li>
          <li>Informer le client de toute modification substantielle du service</li>
        </ul>

        <h2>7. Responsabilité</h2>
        <p>
          Oneli ne pourra être tenue responsable des interruptions de service dues à 
          des cas de force majeure ou à des opérations de maintenance.
        </p>

        <h2>8. Service client</h2>
        <p>
          Le service client est accessible par email à contact@oneli.fr.
        </p>

        <h2>9. Droit applicable</h2>
        <p>
          Les présentes CGV sont soumises au droit français. Tout litige relatif à 
          leur interprétation ou à leur exécution relève de la compétence exclusive 
          des tribunaux français.
        </p>
      </main>
    </div>
  );
};

export default CGV;