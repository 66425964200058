
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Label } from "@/components/ui/label";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Loader2 } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { format } from "date-fns";
import { fr } from "date-fns/locale/fr";
import { CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";

interface EditTrialEndProps {
  userId: string;
  currentTrialEnd: Date | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: () => void;
}

export function EditTrialEnd({ 
  userId, 
  currentTrialEnd, 
  open, 
  onOpenChange, 
  onSuccess 
}: EditTrialEndProps) {
  const [date, setDate] = useState<Date | undefined>(
    currentTrialEnd ? new Date(currentTrialEnd) : undefined
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      
      const { data: existingSubscription } = await supabase
        .from("subscriptions")
        .select("*")
        .eq("user_id", userId)
        .maybeSingle();
      
      if (existingSubscription) {
        // Update existing subscription
        await supabase
          .from("subscriptions")
          .update({ 
            trial_end: date ? date.toISOString() : null,
          })
          .eq("id", existingSubscription.id);
      } else if (date) {
        // Create new subscription if we're setting a trial end date
        await supabase
          .from("subscriptions")
          .insert({
            user_id: userId,
            stripe_customer_id: `admin_created_${userId}`,
            status: "trialing",
            trial_end: date.toISOString()
          });
      }

      // Refresh data
      queryClient.invalidateQueries({ queryKey: ["users"] });
      
      toast({
        title: "Date de fin d'essai mise à jour",
        description: date ? `La période d'essai se terminera le ${format(date, 'dd/MM/yyyy')}` : "La période d'essai a été supprimée",
      });
      
      if (onSuccess) onSuccess();
      onOpenChange(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la date:", error);
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour la date de fin d'essai",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsSubmitting(true);
      
      const { data: existingSubscription } = await supabase
        .from("subscriptions")
        .select("*")
        .eq("user_id", userId)
        .maybeSingle();
      
      if (existingSubscription) {
        // Remove trial end date from existing subscription
        await supabase
          .from("subscriptions")
          .update({ trial_end: null })
          .eq("id", existingSubscription.id);
          
        // Refresh data
        queryClient.invalidateQueries({ queryKey: ["users"] });
        
        toast({
          title: "Date de fin d'essai supprimée",
          description: "La période d'essai a été supprimée"
        });
        
        if (onSuccess) onSuccess();
        onOpenChange(false);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de la date:", error);
      toast({
        title: "Erreur",
        description: "Impossible de supprimer la date de fin d'essai",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Modifier la date de fin d'essai</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="trial-end">Date de fin d'essai</Label>
            <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
              <PopoverTrigger asChild>
                <Button
                  id="trial-end"
                  variant="outline"
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date ? (
                    format(date, "PPP", { locale: fr })
                  ) : (
                    <span>Sélectionner une date</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={(date) => {
                    setDate(date);
                    setCalendarOpen(false);
                  }}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <DialogFooter className="flex justify-between sm:justify-between">
          <div>
            {currentTrialEnd && (
              <Button 
                type="button" 
                variant="destructive" 
                onClick={handleDelete}
                disabled={isSubmitting}
              >
                Supprimer la date
              </Button>
            )}
          </div>
          <div className="flex space-x-2">
            <Button 
              type="button" 
              variant="outline" 
              onClick={() => onOpenChange(false)}
              disabled={isSubmitting}
            >
              Annuler
            </Button>
            <Button 
              type="button" 
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              ) : null}
              Enregistrer
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
