
import { ScrollArea } from "@/components/ui/scroll-area";
import { VerifyResult } from "../types/subscription-sync.types";
import { StatusBadge } from "./StatusBadge";

interface VerifyResultsTableProps {
  results: VerifyResult | null;
}

export const VerifyResultsTable = ({ results }: VerifyResultsTableProps) => {
  if (!results) return null;

  return (
    <>
      <div className="mb-4 flex flex-wrap gap-2">
        <div>
          <span className="font-semibold">Utilisateurs vérifiés:</span> {results.processed}
        </div>
        <div>
          <span className="font-semibold">Corrections appliquées:</span> {results.fixed}
        </div>
        <div>
          <span className="font-semibold">Erreurs:</span> {results.errors}
        </div>
      </div>
      
      <div className="rounded-md border">
        <ScrollArea className="h-[400px]">
          <table className="w-full">
            <thead className="bg-muted/50">
              <tr className="sticky top-0 bg-muted/50">
                <th className="py-2 px-4 text-left font-medium">Email</th>
                <th className="py-2 px-4 text-left font-medium">Status profil</th>
                <th className="py-2 px-4 text-left font-medium">Status abonnement</th>
                <th className="py-2 px-4 text-left font-medium">Message</th>
              </tr>
            </thead>
            <tbody>
              {results.details.map((detail, index) => (
                <tr key={index} className="border-t">
                  <td className="py-2 px-4">{detail.email}</td>
                  <td className="py-2 px-4"><StatusBadge status={detail.profile_status} /></td>
                  <td className="py-2 px-4"><StatusBadge status={detail.subscription_status} /></td>
                  <td className="py-2 px-4">
                    {detail.message && <div className="text-sm text-muted-foreground">{detail.message}</div>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ScrollArea>
      </div>
    </>
  );
};
