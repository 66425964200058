
import { ScrollArea } from "@/components/ui/scroll-area";
import { SyncResult } from "../types/subscription-sync.types";
import { StatusBadge } from "./StatusBadge";

interface SyncResultsTableProps {
  results: SyncResult | null;
}

export const SyncResultsTable = ({ results }: SyncResultsTableProps) => {
  if (!results) return null;

  return (
    <>
      <div className="mb-4 flex flex-wrap gap-2">
        <div>
          <span className="font-semibold">Utilisateurs traités:</span> {results.processed}
        </div>
        <div>
          <span className="font-semibold">Abonnements mis à jour:</span> {results.updated}
        </div>
        <div>
          <span className="font-semibold">Erreurs:</span> {results.errors}
        </div>
        {results.profiles_created ? (
          <div>
            <span className="font-semibold">Profils créés:</span> {results.profiles_created}
          </div>
        ) : null}
        {results.customer_ids_updated ? (
          <div>
            <span className="font-semibold">Customer IDs mis à jour:</span> {results.customer_ids_updated}
          </div>
        ) : null}
        {results.linked_customer_ids ? (
          <div>
            <span className="font-semibold">Comptes clients liés:</span> {results.linked_customer_ids}
          </div>
        ) : null}
      </div>
      
      <div className="rounded-md border">
        <ScrollArea className="h-[400px]">
          <table className="w-full">
            <thead className="bg-muted/50">
              <tr className="sticky top-0 bg-muted/50">
                <th className="py-2 px-4 text-left font-medium">Email</th>
                <th className="py-2 px-4 text-left font-medium">Résultat</th>
                <th className="py-2 px-4 text-left font-medium">Statut abonnement</th>
                <th className="py-2 px-4 text-left font-medium">Détails</th>
              </tr>
            </thead>
            <tbody>
              {results.details.map((detail, index) => (
                <tr key={index} className="border-t">
                  <td className="py-2 px-4">{detail.email}</td>
                  <td className="py-2 px-4"><StatusBadge status={detail.status} /></td>
                  <td className="py-2 px-4">
                    {detail.subscription_status ? 
                      <StatusBadge status={detail.subscription_status} /> : 
                      <span className="text-muted-foreground">-</span>
                    }
                  </td>
                  <td className="py-2 px-4">
                    {detail.message && <div className="text-sm text-muted-foreground">{detail.message}</div>}
                    {detail.subscription_id && <div className="text-sm truncate max-w-[200px]">ID: {detail.subscription_id}</div>}
                    {detail.has_trial && <div className="text-sm text-blue-600">Période d'essai</div>}
                    {detail.customer_ids && detail.customer_ids.length > 0 && (
                      <div className="text-sm">
                        <span className="font-semibold">Customer IDs:</span> {detail.customer_ids.length}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ScrollArea>
      </div>
    </>
  );
};
