
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import Navbar from "@/components/Navbar";
import { CheckCircle2, ExternalLink, Phone } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";
import { PhoneNumberModal } from "@/components/profile/PhoneNumberModal";

const Success = () => {
  const { user } = useAuth();
  const emailSent = useRef(false);
  const [phoneModalOpen, setPhoneModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<{ phone_number?: string | null } | null>(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  useEffect(() => {
    // Google Ads conversion tracking
    const script = document.createElement("script");
    script.innerHTML = `
      gtag('event', 'conversion', {
          'send_to': 'AW-16851399508/VUdcCJLb5pgaENTmr-M-',
          'value': 1.0,
          'currency': 'EUR',
          'transaction_id': ''
      });
    `;
    document.body.appendChild(script);

    // Récupérer le profil de l'utilisateur
    const fetchUserProfile = async () => {
      if (user) {
        try {
          setIsLoadingProfile(true);
          const { data, error } = await supabase
            .from("profiles")
            .select("phone_number")
            .eq("id", user.id)
            .single();

          if (error) throw error;
          
          setUserProfile(data);
          setIsLoadingProfile(false);
        } catch (error) {
          console.error("Erreur lors de la récupération du profil:", error);
          setIsLoadingProfile(false);
        }
      }
    };

    fetchUserProfile();

    // Envoyer l'email de confirmation d'achat
    const sendConfirmationEmails = async () => {
      if (user && !emailSent.current) {
        try {
          // Email pour le client
          const { error: customerEmailError } = await supabase.functions.invoke("send-welcome-email", {
            body: {
              email: user.email,
              firstName: user.user_metadata?.first_name,
              type: "purchase_confirmation"
            },
          });

          if (customerEmailError) {
            console.error("Erreur lors de l'envoi de l'email de confirmation au client:", customerEmailError);
          }

          // Email pour l'admin
          const { error: adminEmailError } = await supabase.functions.invoke("send-welcome-email", {
            body: {
              email: "richardwinckels@gmail.com",
              type: "admin_notification",
              customerEmail: user.email,
              customerName: user.user_metadata?.first_name
            },
          });

          if (adminEmailError) {
            console.error("Erreur lors de l'envoi de l'email à l'admin:", adminEmailError);
          }

          // Marquer les emails comme envoyés
          emailSent.current = true;
        } catch (error) {
          console.error("Erreur lors de l'envoi des emails:", error);
        }
      }
    };

    sendConfirmationEmails();

    return () => {
      document.body.removeChild(script);
    };
  }, [user]);

  // Vérifier si l'utilisateur a un numéro de téléphone
  const hasPhoneNumber = userProfile?.phone_number && userProfile.phone_number.length >= 10;

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-24">
        <Card className="max-w-2xl mx-auto p-8">
          <div className="text-center space-y-6">
            <CheckCircle2 className="mx-auto h-16 w-16 text-primary" />
            <h1 className="text-3xl font-bold">Félicitations !</h1>
            <p className="text-lg text-muted-foreground">
              Votre box Oneli a été commandée avec succès. Dès sa réception, vous pourrez profiter d'un accès Internet sans fil, à la fois simple et rapide.
            </p>

            {/* Notification de contact téléphonique */}
            <div className="bg-secondary/40 p-6 rounded-lg border border-secondary flex flex-col items-center">
              <Phone className="h-8 w-8 text-primary mb-2" />
              {isLoadingProfile ? (
                <p>Chargement de vos informations...</p>
              ) : hasPhoneNumber ? (
                <div className="space-y-2">
                  <p className="font-medium">Nous allons vous contacter par téléphone</p>
                  <p className="text-muted-foreground">
                    Un membre de notre équipe vous contactera dans les 24h pour confirmer votre commande et organiser la livraison.
                  </p>
                </div>
              ) : (
                <div className="space-y-4">
                  <p className="font-medium">Nous avons besoin de votre numéro de téléphone</p>
                  <p className="text-muted-foreground mb-2">
                    Pour finaliser votre commande, nous devons vous contacter par téléphone.
                  </p>
                  <Button onClick={() => setPhoneModalOpen(true)} className="flex items-center gap-2">
                    <Phone className="h-4 w-4" />
                    Ajouter mon numéro
                  </Button>
                </div>
              )}
            </div>

            {/* Timeline Steps */}
            <div className="py-8">
              <div className="flex justify-between items-center">
                {[
                  "Choix de la box",
                  "Box commandée",
                  "Sélection du forfait",
                  "Box livrée",
                  "Box connectée"
                ].map((step, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      index === 1 ? 'bg-primary text-white' : 
                      index < 1 ? 'bg-primary/20 text-primary' : 'bg-gray-200 text-gray-400'
                    }`}>
                      {index <= 1 ? '✓' : (index + 1)}
                    </div>
                    <span className={`mt-2 text-sm whitespace-nowrap ${
                      index === 1 ? 'font-semibold text-primary' : 
                      index < 1 ? 'text-primary/80' : 'text-gray-400'
                    }`}>
                      {step}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-secondary p-6 rounded-lg mt-8">
              <h2 className="text-xl font-semibold mb-3">Dernière étape</h2>
              <p className="text-muted-foreground mb-4">
                Une carte SIM est incluse gratuitement pour une durée de 30 jours avec 20 Go de test. Pensez à commander votre propre carte SIM <strong>dans les 10 prochains jours</strong>.
              </p>
              <div className="flex items-center justify-center gap-4 flex-wrap">
                <Link to="/rendezvous">
                  <Button variant="outline" className="inline-flex items-center gap-2">
                    Prendre rendez-vous
                  </Button>
                </Link>
                <a 
                  href="https://bonforfait.fr/forfait-mobile?5g&internet=100" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-block"
                >
                  <Button className="inline-flex items-center gap-2">
                    Découvrir les forfaits <ExternalLink className="h-4 w-4" />
                  </Button>
                </a>
              </div>
              <div className="flex items-center justify-center gap-8 mt-6 flex-wrap">
                <img src="/lovable-uploads/74465d5b-b19d-4c60-939d-b1a1b4a58263.png" alt="Logo Orange" className="h-8" />
                <img src="/lovable-uploads/fc3b2bfc-eb37-4bab-bcde-f7faa0ace997.png" alt="Logo Orange" className="h-8" />
                <img src="/lovable-uploads/12f32b7e-5fae-498e-827f-e274411382bf.png" alt="Logo Free" className="h-8" />
                <img src="/lovable-uploads/3fb6f1d0-6d98-4be2-9fe9-af7f9ff89f02.png" alt="Logo Bouygues" className="h-8" />
              </div>
            </div>

            <div className="pt-4">
              <Link to="/profile">
                <Button variant="secondary">Accéder à mon compte</Button>
              </Link>
            </div>
          </div>
        </Card>
      </div>

      <PhoneNumberModal 
        open={phoneModalOpen} 
        onOpenChange={setPhoneModalOpen}
        onSuccess={() => {
          // Update userProfile when phone is successfully added
          // Instead of referring to form, we update the profile directly
          setUserProfile(prev => ({...prev, phone_number: "added" }));
        }}
      />
    </>
  );
};

export default Success;
