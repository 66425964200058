import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useSyncSubscriptions } from "@/components/profile/subscription/useSyncSubscriptions";
import { SyncResult, VerifyResult } from "./types/subscription-sync.types";
import { SyncActionButtons } from "./subscription-sync/SyncActionButtons";
import { SyncResultsTable } from "./subscription-sync/SyncResultsTable";
import { VerifyResultsTable } from "./subscription-sync/VerifyResultsTable";

export const SubscriptionSync = () => {
  const [syncLoading, setSyncLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [syncResults, setSyncResults] = useState<SyncResult | null>(null);
  const [verifyResults, setVerifyResults] = useState<VerifyResult | null>(null);
  const [activeTab, setActiveTab] = useState<string>("sync");
  const { toast } = useToast();
  const { syncSubscriptions, isSyncing } = useSyncSubscriptions();
  
  // Options for sync
  const [syncOptions, setSyncOptions] = useState({
    limit: 100,
    processAll: true,
    createMissingProfiles: true,
    updateAllCustomerIds: true,
  });
  
  const updateSyncOptions = (options: Partial<typeof syncOptions>) => {
    setSyncOptions({...syncOptions, ...options});
  };
  
  const runSync = async () => {
    try {
      setSyncLoading(true);
      setSyncResults(null);

      // Using the custom hook with options
      const data = await syncSubscriptions({
        showToast: false, // We'll handle toasts here
        limit: syncOptions.limit,
        processAll: syncOptions.processAll,
        createMissingProfiles: syncOptions.createMissingProfiles,
        updateAllCustomerIds: syncOptions.updateAllCustomerIds,
        onSuccess: (data) => {
          console.log("Sync successful:", data);
        },
        onError: (error) => {
          console.error("Sync error:", error);
        }
      });

      if (data && data.results) {
        setSyncResults(data.results);
        
        const profilesCreated = data.results.profiles_created || 0;
        const customerIdsUpdated = data.results.customer_ids_updated || 0;
        let description = `${data.results.processed} utilisateurs traités, ${data.results.updated} abonnements mis à jour, ${data.results.errors} erreurs`;
        
        if (profilesCreated > 0) {
          description += `, ${profilesCreated} profils créés`;
        }
        
        if (customerIdsUpdated > 0) {
          description += `, ${customerIdsUpdated} customer IDs mis à jour`;
        }
        
        toast({
          title: "Synchronisation terminée",
          description: description,
        });
      }
      
      setActiveTab("sync");
    } catch (error) {
      console.error("Erreur lors de la synchronisation des abonnements:", error);
      toast({
        title: "Erreur",
        description: "Impossible de synchroniser les abonnements",
        variant: "destructive",
      });
    } finally {
      setSyncLoading(false);
    }
  };

  const runVerification = async () => {
    try {
      setVerifyLoading(true);
      setVerifyResults(null);

      const { data, error } = await supabase.functions.invoke("verify-data-integrity");

      if (error) {
        throw error;
      }

      setVerifyResults(data.results);
      
      toast({
        title: "Vérification terminée",
        description: `${data.results.processed} utilisateurs vérifiés, ${data.results.fixed} corrections, ${data.results.errors} erreurs`,
      });
      
      setActiveTab("verify");
    } catch (error) {
      console.error("Erreur lors de la vérification des données:", error);
      toast({
        title: "Erreur",
        description: "Impossible de vérifier l'intégrité des données",
        variant: "destructive",
      });
    } finally {
      setVerifyLoading(false);
    }
  };

  return (
    <Card className="mb-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Synchroniser et vérifier les données</CardTitle>
        <SyncActionButtons 
          syncLoading={syncLoading}
          verifyLoading={verifyLoading}
          isSyncing={isSyncing}
          options={syncOptions}
          setOptions={updateSyncOptions}
          onSync={runSync}
          onVerify={runVerification}
        />
      </CardHeader>
      
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <div className="px-6">
          <TabsList className="grid grid-cols-2 mb-4">
            <TabsTrigger value="sync">Synchronisation Stripe</TabsTrigger>
            <TabsTrigger value="verify">Vérification d'intégrité</TabsTrigger>
          </TabsList>
        </div>
        
        <TabsContent value="sync">
          {syncResults && (
            <CardContent>
              <SyncResultsTable results={syncResults} />
            </CardContent>
          )}
        </TabsContent>
        
        <TabsContent value="verify">
          {verifyResults && (
            <CardContent>
              <VerifyResultsTable results={verifyResults} />
            </CardContent>
          )}
        </TabsContent>
      </Tabs>
    </Card>
  );
};
