
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import { useSubscriptionData } from "./subscription/useSubscriptionData";
import { ActiveSubscriptionDetails } from "./subscription/ActiveSubscriptionDetails";
import { SubscriptionPlans } from "./subscription/SubscriptionPlans";
import { SubscriptionErrorDialog } from "./subscription/SubscriptionErrorDialog";
import { useSubscriptionHandler } from "./subscription/useSubscriptionHandler";

export const SubscriptionInfo = () => {
  const { 
    selectedPlan, 
    setSelectedPlan, 
    isLoading, 
    errorDialogOpen, 
    setErrorDialogOpen, 
    errorMessage, 
    handleSubscribe 
  } = useSubscriptionHandler();
  
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscriptionData();

  if (isLoadingSubscription) {
    return (
      <div className="flex justify-center p-4">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Choisissez votre offre Oneli</CardTitle>
          <CardDescription>Internet sans fil, haut débit, partout</CardDescription>
        </CardHeader>
        <CardContent>
          {subscription?.subscribed ? (
            <ActiveSubscriptionDetails subscription={subscription} />
          ) : (
            <div className="space-y-6">
              <SubscriptionPlans 
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
              />

              <Button 
                onClick={handleSubscribe} 
                className="w-full"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 className="h-4 w-4 animate-spin mr-2" />
                ) : null}
                {selectedPlan === "PURCHASE" 
                  ? "Acheter ma box Oneli" 
                  : "Commander ma box Oneli"
                }
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      <SubscriptionErrorDialog
        open={errorDialogOpen}
        onOpenChange={setErrorDialogOpen}
        errorMessage={errorMessage}
      />
    </>
  );
};
