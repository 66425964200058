import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import type { Tables } from "@/integrations/supabase/types";

type Profile = Tables<"profiles">;
type Subscription = Tables<"subscriptions">;

interface UserWithDetails extends Profile {
  subscriptions?: Subscription[] | null;
  trial_end?: string | null;
  email?: string;
  documents?: {
    bank_details_path: string | null;
    identity_doc_path: string | null;
  } | null;
}

export const useUserData = (userId: string | undefined) => {
  const [users, setUsers] = useState<UserWithDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const loadUsers = async () => {
      if (!userId) {
        navigate("/login");
        return;
      }

      const { data: profile } = await supabase
        .from("profiles")
        .select("is_admin")
        .eq("id", userId)
        .single();

      if (!profile?.is_admin) {
        navigate("/");
        toast({
          title: "Accès refusé",
          description: "Vous n'avez pas les droits administrateur",
          variant: "destructive",
        });
        return;
      }

      const { data: profilesData, error: profilesError } = await supabase
        .from("profiles")
        .select("*");

      if (profilesError) {
        console.error("Erreur lors de la récupération des profils:", profilesError);
        toast({
          title: "Erreur",
          description: "Impossible de charger la liste des utilisateurs",
          variant: "destructive",
        });
        return;
      }

      try {
        // Récupérer les emails des utilisateurs avec nouvelle tentative en cas d'échec
        let authData;
        let authError;
        
        for (let attempts = 0; attempts < 3; attempts++) {
          const response = await supabase.functions.invoke('get-users-emails');
          authData = response.data;
          authError = response.error;
          
          if (!authError && authData?.users && authData.users.length > 0) {
            console.log(`Tentative ${attempts+1}: Récupéré ${authData.users.length} emails avec succès`);
            break;
          } else {
            console.warn(`Tentative ${attempts+1}: Échec de récupération des emails`, authError);
            // Attendre avant de réessayer
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
        
        console.log("Auth data:", authData);

        if (authError || !authData?.users) {
          console.error("Erreur lors de la récupération des emails:", authError);
          toast({
            title: "Attention",
            description: "Impossible de récupérer certains emails d'utilisateurs",
            variant: "destructive",
          });
        }

        // Créer une map pour associer les IDs aux emails
        const emailMap = new Map<string, string>();
        if (authData?.users) {
          authData.users.forEach((user: any) => {
            if (user.id && user.email) {
              emailMap.set(user.id, user.email);
            }
          });
        }

        console.log(`Email map contient ${emailMap.size} emails`);

        // Récupérer TOUS les abonnements pour améliorer la performance
        const { data: allSubscriptions, error: subscriptionsError } = await supabase
          .from("subscriptions")
          .select("*")
          .order('created_at', { ascending: false });
          
        if (subscriptionsError) {
          console.error("Erreur lors de la récupération des abonnements:", subscriptionsError);
        }
        
        // Créer une map pour associer les utilisateurs à leurs abonnements
        const subscriptionsMap = new Map<string, Subscription[]>();
        if (allSubscriptions) {
          allSubscriptions.forEach((subscription) => {
            const userSubs = subscriptionsMap.get(subscription.user_id) || [];
            userSubs.push(subscription);
            subscriptionsMap.set(subscription.user_id, userSubs);
          });
        }
        
        console.log(`Subscription map contient ${subscriptionsMap.size} utilisateurs avec abonnements`);

        // Récupérer TOUS les documents utilisateurs avec une requête unique
        const { data: allDocuments, error: documentsError } = await supabase
          .from("user_documents")
          .select("*");
          
        if (documentsError) {
          console.error("Erreur lors de la récupération des documents:", documentsError);
        } else {
          console.log(`Récupéré ${allDocuments?.length || 0} documents utilisateurs`);
          
          // Log des détails pour richardwinckels@gmail.com
          const richardId = Array.from(emailMap.entries()).find(([id, email]) => 
            email === 'richardwinckels@gmail.com'
          )?.[0];
          
          if (richardId) {
            const richardDocs = allDocuments?.find(doc => doc.user_id === richardId);
            console.log("Documents pour richardwinckels@gmail.com:", richardDocs);
          }
        }
        
        // Créer une map pour les documents
        const documentsMap = new Map();
        if (allDocuments) {
          allDocuments.forEach((doc) => {
            documentsMap.set(doc.user_id, doc);
          });
        }

        const usersWithSubscriptions = await Promise.all(
          (profilesData || []).map(async (profile) => {
            // Récupérer les abonnements depuis la map
            const subscriptions = subscriptionsMap.get(profile.id) || [];
            
            // Si nous avons un abonnement avec un ID Stripe, vérifions Stripe pour obtenir des informations actualisées
            let trialEnd = subscriptions?.[0]?.trial_end || null;
            const hasStripeSubscription = subscriptions?.[0]?.stripe_subscription_id;
            
            if (hasStripeSubscription) {
              try {
                const { data, error } = await supabase.functions.invoke('check-subscription', {
                  body: { 
                    subscriptionId: subscriptions[0].stripe_subscription_id,
                    updateStripeCustomerId: true,
                    storeAllCustomerIds: true
                  }
                });

                if (!error && data?.trial_end) {
                  trialEnd = new Date(data.trial_end * 1000).toISOString();
                  
                  // Mise à jour de la période d'essai dans la base
                  await supabase
                    .from('subscriptions')
                    .update({ trial_end: trialEnd })
                    .eq('id', subscriptions[0].id);
                }
              } catch (error) {
                console.error("Erreur lors de la vérification de l'abonnement:", error);
              }
            }

            // Récupérer l'email de la map
            const email = emailMap.get(profile.id) || '';
            
            // Journaliser les utilisateurs problématiques
            if (email === 'c.ri@live.be' || email === 'e.mouhoubi@yahoo.fr' || email === 'sebastien.lindecker@live.fr') {
              console.log(`Détails de ${email}:`, {
                id: profile.id,
                subscriptions,
                has_stripe_id: subscriptions?.[0]?.stripe_subscription_id ? true : false,
                status: subscriptions?.[0]?.status,
                all_customer_ids: subscriptions?.[0]?.all_stripe_customer_ids
              });
            }
            
            // Journaliser les documents pour richardwinckels@gmail.com
            if (email === 'richardwinckels@gmail.com') {
              console.log(`Documents pour ${email}:`, documentsMap.get(profile.id));
            }
            
            // Add documents to user data
            const documents = documentsMap.get(profile.id) || null;
            
            return {
              ...profile,
              email,
              subscriptions: subscriptions || null,
              trial_end: trialEnd,
              documents
            } as UserWithDetails;
          })
        );

        // Log des statistiques
        console.log("Nombre d'utilisateurs avec profil:", usersWithSubscriptions.length);
        console.log("Nombre d'utilisateurs avec email:", usersWithSubscriptions.filter(u => u.email).length);
        console.log("Nombre d'utilisateurs avec abonnement:", usersWithSubscriptions.filter(u => u.subscriptions && u.subscriptions.length > 0).length);
        console.log("Nombre d'utilisateurs avec documents:", usersWithSubscriptions.filter(u => u.documents).length);
        
        // Log des documents de richardwinckels@gmail.com après traitement
        const richardUser = usersWithSubscriptions.find(u => u.email === 'richardwinckels@gmail.com');
        if (richardUser) {
          console.log("Richard après traitement:", richardUser.documents);
        }
        
        setUsers(usersWithSubscriptions);
      } catch (error) {
        console.error("Erreur lors du traitement des données:", error);
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors du traitement des données",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    loadUsers();
  }, [userId, navigate, toast]);

  return { users, setUsers, loading };
};
