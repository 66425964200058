
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import Navbar from "@/components/Navbar";
import { Wifi, Zap, Timer, Monitor, Shield, Thermometer, Phone, Network } from "lucide-react";

const Utilisations = () => {
  const mainFeatures = [
    {
      icon: <Timer className="w-12 h-12 text-primary" />,
      title: "Internet instantané",
      description: "Branchez et connectez-vous immédiatement, sans fibre ni installation complexe. La box se connecte à Internet via la 5G sans fil.",
    },
    {
      icon: <Zap className="w-12 h-12 text-primary" />,
      title: "Ultra performante",
      description: "Jusqu'à 1,7 Gbps en download et 575 Mbps en upload. Aussi rapide que la fibre.",
    },
    {
      icon: <Wifi className="w-12 h-12 text-primary" />,
      title: "WiFi 6 double bande",
      description: "2,4 GHz et 5 GHz pour une couverture optimale sans répéteur. Jusqu'à 64 appareils connectés.",
    },
    {
      icon: <Shield className="w-12 h-12 text-primary" />,
      title: "Multi-réseaux",
      description: "Compatible 3G, 4G, 5G et prête pour la 5G Stand Alone. Une connexion stable en toutes circonstances.",
    },
  ];

  const technicalFeatures = [
    {
      icon: <Monitor className="w-8 h-8 text-primary" />,
      title: "Économe en énergie",
      description: "Conception optimisée pour une consommation réduite"
    },
    {
      icon: <Phone className="w-8 h-8 text-primary" />,
      title: "Port téléphonie RJ11",
      description: "Option téléphonie fixe disponible"
    },
    {
      icon: <Network className="w-8 h-8 text-primary" />,
      title: "Port RJ45",
      description: "Connexion filaire ultra-rapide sans fibre optique"
    },
    {
      icon: <Thermometer className="w-8 h-8 text-primary" />,
      title: "Résistance extrême",
      description: "Fonctionne de -20°C à 60°C"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
      <Navbar />
      
      <main className="container mx-auto px-4 pt-24 pb-16">
        {/* Hero Section */}
        <div className="max-w-6xl mx-auto mb-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center bg-[#D3E4FD] rounded-2xl p-8 shadow-lg">
            <div className="space-y-6">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-800 leading-tight">
                La Box qui réinvente votre connexion internet
              </h1>
              <p className="text-xl text-gray-600 leading-relaxed">
                Performance, simplicité et fiabilité. Votre nouvelle expérience internet commence ici, sans installation ni engagement.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <Link to="/commander">
                  <Button size="lg" className="w-full sm:w-auto bg-primary hover:bg-primary-dark">
                    Commander
                  </Button>
                </Link>
                <Link to="/tarifs">
                  <Button 
                    size="lg" 
                    variant="outline"
                    className="w-full sm:w-auto bg-white text-gray-900 border-gray-300 hover:bg-gray-50"
                  >
                    Voir les tarifs
                  </Button>
                </Link>
              </div>
            </div>
            <div className="relative">
              <img
                src="/lovable-uploads/0e351631-58f5-46af-ae5b-72ee98194ebc.png"
                alt="Box Oneli dans un environnement moderne"
                className="w-full rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute -top-4 -right-4 bg-primary text-white px-4 py-2 rounded-full text-sm font-semibold">
                5G Ready
              </div>
            </div>
          </div>
        </div>

        {/* Main Features Section */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Une box qui allie puissance et simplicité
          </h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto mb-12">
            Découvrez une connexion internet qui s'adapte à votre vie moderne.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {mainFeatures.map((feature, index) => (
              <Card 
                key={index} 
                className="bg-white hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
              >
                <CardContent className="p-6 text-center">
                  <div className="mx-auto w-20 h-20 bg-primary/10 rounded-full flex items-center justify-center mb-4">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        {/* Technical Features Section */}
        <div className="bg-white rounded-2xl p-8 shadow-lg mb-16">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12">
              Conçue pour durer
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
              {technicalFeatures.map((feature, index) => (
                <div key={index} className="text-center space-y-3">
                  <div className="mx-auto w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center">
                    {feature.icon}
                  </div>
                  <h3 className="font-semibold">{feature.title}</h3>
                  <p className="text-gray-600 text-sm">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-gradient-to-r from-primary/10 to-primary/20 rounded-2xl p-8">
          <h2 className="text-3xl font-bold mb-6">
            Prêt à découvrir la Box Oneli ?
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Essayez notre box pendant 30 jours et découvrez une nouvelle façon de vous connecter à internet.
          </p>
          <Link to="/commander">
            <Button size="lg" className="bg-primary hover:bg-primary-dark">
              Commander ma box
            </Button>
          </Link>
        </div>
      </main>
    </div>
  );
};

export default Utilisations;
