import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Zap, WifiOff, Rocket, Clock, CheckCircle2, AlertCircle } from "lucide-react";
import Navbar from "@/components/Navbar";
import { Card, CardContent } from "@/components/ui/card";

const SansFibre = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* Hero Section */}
      <section className="pt-20 md:pt-24 px-4 bg-gradient-to-b from-blue-50 to-white text-center">
        <div className="container mx-auto">
          <div className="flex flex-col items-center gap-8 max-w-4xl mx-auto">
            <div className="inline-flex items-center gap-2 bg-primary/10 px-4 py-2 rounded-full mb-6">
              <WifiOff className="w-4 h-4 text-primary" />
              <span className="text-primary font-medium">Pas de fibre ? Pas de problème !</span>
            </div>
            <h1 className="text-4xl md:text-6xl font-bold mb-6">
              La solution pour ceux qui n'ont pas la fibre
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Internet très haut débit sans fibre, sans travaux, sans engagement. Installation en 30 secondes chrono.
            </p>
            <Link to="/commander">
              <Button size="lg" className="bg-primary hover:bg-primary-dark text-white">
                Commander
              </Button>
            </Link>
            <p className="text-sm text-gray-600 mb-16">
              À partir de 9,90€/mois
            </p>
          </div>
        </div>
      </section>

      {/* Pain Points Section */}
      <section className="py-16 px-4 bg-blue-50">
        <div className="container mx-auto">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div className="space-y-6">
              <h2 className="text-3xl font-bold mb-6">Marre d'attendre la fibre ?</h2>
              <div className="space-y-4">
                {[
                  "Pas de fibre dans votre quartier ?",
                  "Travaux reportés encore et encore ?",
                  "Délais d'installation interminables ?",
                  "Frais de raccordement excessifs ?",
                ].map((point, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <AlertCircle className="w-6 h-6 text-red-500 shrink-0" />
                    <p className="text-lg text-gray-700">{point}</p>
                  </div>
                ))}
              </div>
              <Link to="/commander" className="inline-block mt-6">
                <Button size="lg" className="bg-primary hover:bg-primary-dark text-white">
                  Découvrir la solution
                </Button>
              </Link>
            </div>
            <div className="relative">
              <img 
                src="/lovable-uploads/0e351631-58f5-46af-ae5b-72ee98194ebc.png"
                alt="Box Oneli dans un environnement moderne"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </section>

      {/* How it Works Section */}
      <section className="py-16 px-4 bg-white">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">
            Comment ça marche ?
          </h2>
          <div className="grid md:grid-cols-4 gap-6 max-w-5xl mx-auto">
            {[
              {
                day: "Jour 0",
                title: "Réception et essai",
                description: "Recevez votre box Oneli et profitez de 30 jours d'essai gratuit avec 20 Go d'internet pour tester la qualité de connexion sans engagement.",
                icon: <Clock className="w-8 h-8 text-primary" />
              },
              {
                day: "Jour 15",
                title: "Choix de l'abonnement",
                description: "Si la connexion vous convient, souscrivez à un abonnement 5G auprès de l'opérateur de votre choix, ou contactez-nous pour des conseils personnalisés.",
                icon: <Rocket className="w-8 h-8 text-primary" />
              },
              {
                day: "Jour 30",
                title: "Profitez de votre box",
                description: "Continuez avec votre box Oneli sans engagement et bénéficiez d'un débit surpassant la fibre, pour tous vos besoins internet.",
                icon: <Zap className="w-8 h-8 text-primary" />
              },
              {
                day: "Ou...",
                title: "Retour sans frais",
                description: "Si le service ne répond pas à vos attentes, retournez simplement la box sans aucun frais. Votre satisfaction est notre priorité.",
                icon: <CheckCircle2 className="w-8 h-8 text-primary" />
              }
            ].map((step, index) => (
              <Card key={index} className="border-none shadow-md hover:shadow-lg transition-shadow">
                <CardContent className="p-6 flex flex-col items-center text-center">
                  <div className="mb-4 p-3 bg-white rounded-full shadow-sm">{step.icon}</div>
                  <span className="inline-block bg-primary/10 text-primary font-semibold px-3 py-1 rounded-full text-sm mb-3">
                    {step.day}
                  </span>
                  <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">
            Une alternative plus simple à la fibre
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Clock className="w-8 h-8 text-primary" />,
                title: "Installation instantanée",
                description: "Branchez et surfez en 30 secondes. Pas de technicien, pas d'attente.",
              },
              {
                icon: <Rocket className="w-8 h-8 text-primary" />,
                title: "Performance garantie",
                description: "Jusqu'à 1 Gb/s en download, idéal pour vos contenus en haute définition.",
              },
              {
                icon: <Zap className="w-8 h-8 text-primary" />,
                title: "Flexibilité totale",
                description: "Sans engagement, emportez votre box partout avec vous.",
              },
            ].map((benefit, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                <div className="mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Comparison */}
      <section className="py-16 px-4 bg-white">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">
            Pourquoi choisir Oneli plutôt que la fibre ?
          </h2>
          <div className="max-w-3xl mx-auto">
            <div className="space-y-4">
              {[
                "Installation en 30 secondes vs plusieurs semaines d'attente",
                "Pas de frais de raccordement ou travaux nécessaires",
                "Emportez votre connexion partout avec vous",
                "Sans engagement, testez gratuitement pendant 30 jours",
                "Support client réactif et à l'écoute",
                "Solution moins chère que les alternatives traditionnelles",
              ].map((feature, index) => (
                <div key={index} className="flex items-center gap-3 p-4 bg-gray-50 rounded-lg">
                  <CheckCircle2 className="w-6 h-6 text-green-500 shrink-0" />
                  <p className="text-lg">{feature}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 px-4 bg-primary text-white">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">
            Prêt à dire adieu aux complications de la fibre ?
          </h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Rejoignez les milliers d'utilisateurs satisfaits qui ont choisi la simplicité avec Oneli.
          </p>
          <div className="space-x-4">
            <Link to="/commander">
              <Button size="lg" variant="secondary" className="bg-white text-primary hover:bg-gray-100">
                Commander
              </Button>
            </Link>
            <Link to="/rendezvous">
              <Button 
                size="lg" 
                className="bg-transparent border-2 border-white text-white hover:bg-white/20"
              >
                Prendre Rendez-vous
              </Button>
            </Link>
          </div>
          <p className="mt-6 text-sm text-white/80">
            30 jours gratuits avec 20 Go inclus • Puis 9,90 €/mois + Abonnement 5G (À partir de 2€/mois)
          </p>
        </div>
      </section>
    </div>
  );
};

export default SansFibre;
