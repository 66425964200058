
import { useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/integrations/supabase/client";

export type PlanType = "MONTHLY" | "ANNUAL" | "PURCHASE";

// Stripe product IDs
const PRODUCTS = {
  MONTHLY: "prod_S3UIqKjc2UzmcK", // 29.90€ subscription
  PURCHASE: "prod_S3UGSTpdzGY0NX"  // 179.90€ one-time purchase
};

export const useSubscriptionHandler = () => {
  const { session } = useAuth();
  const [selectedPlan, setSelectedPlan] = useState<PlanType>("MONTHLY");
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  const handleSubscribe = async () => {
    try {
      if (!session?.access_token || isLoading) {
        return;
      }

      setIsLoading(true);
      console.log("Creating checkout session with plan:", selectedPlan);
      
      const { data, error } = await supabase.functions.invoke('create-checkout-session', {
        body: { 
          priceType: selectedPlan,
          productId: PRODUCTS[selectedPlan] 
        },
        headers: {
          Authorization: `Bearer ${session.access_token}`,
        },
      });

      if (error) {
        console.error("Checkout error:", error);
        setErrorMessage("Impossible de créer la session de paiement. Détail: " + error.message);
        setErrorDialogOpen(true);
        throw error;
      }

      if (data?.url) {
        console.log("Redirecting to checkout URL:", data.url);
        window.location.href = data.url;
      } else {
        setErrorMessage("La réponse du serveur ne contient pas d'URL de paiement");
        setErrorDialogOpen(true);
      }
    } catch (error: any) {
      console.error("Error creating checkout session:", error);
      setErrorMessage("Une erreur est survenue lors de la création de la session de paiement: " + 
        (error.message || "Erreur inconnue"));
      setErrorDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    selectedPlan,
    setSelectedPlan,
    isLoading,
    errorDialogOpen,
    setErrorDialogOpen,
    errorMessage,
    handleSubscribe
  };
};
