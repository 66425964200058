import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

const formSchema = z.object({
  videoHoursPerDay: z.number().min(0, "Le nombre d'heures ne peut pas être négatif"),
});

type VideoUsageStepProps = {
  data: {
    videoHoursPerDay: number;
  };
  onUpdate: (data: { videoHoursPerDay: number }) => void;
  onNext: () => void;
  onBack: () => void;
};

export const VideoUsageStep = ({ data, onUpdate, onNext, onBack }: VideoUsageStepProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      videoHoursPerDay: data.videoHoursPerDay || 0,
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    onUpdate({
      videoHoursPerDay: values.videoHoursPerDay,
    });
    onNext();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="videoHoursPerDay"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Combien d'heures de vidéo regardez-vous par jour et par personne en moyenne ?
              </FormLabel>
              <FormControl>
                <Input
                  type="number"
                  min={0}
                  step={0.5}
                  {...field}
                  onChange={(e) => field.onChange(parseFloat(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-between">
          <Button type="button" variant="outline" onClick={onBack}>
            Retour
          </Button>
          <Button type="submit">
            Suivant
          </Button>
        </div>
      </form>
    </Form>
  );
};