
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useOnboarding } from "@/contexts/OnboardingContext";
import { Smartphone } from "lucide-react";

export const PhoneStep = () => {
  const { data, updateData, nextStep, previousStep, saveStep } = useOnboarding();
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Reset error
    setError("");
    
    // Validation
    if (!data.phone_number) {
      setError("Veuillez saisir votre numéro de téléphone");
      return;
    }
    
    // Validate phone number format (basic French format)
    const phoneRegex = /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/;
    if (!phoneRegex.test(data.phone_number)) {
      setError("Veuillez saisir un numéro de téléphone valide");
      return;
    }
    
    await saveStep();
    nextStep();
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateData({ phone_number: e.target.value });
    if (error) setError("");
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold">Votre numéro de téléphone</h1>
        <p className="text-muted-foreground">
          Nous aurons besoin de votre numéro pour vous contacter
        </p>
      </div>

      <div className="bg-primary/10 rounded-lg p-4 flex items-center gap-3">
        <Smartphone className="h-5 w-5 text-primary" />
        <div>
          <div className="font-medium">Restez informé</div>
          <div className="text-sm text-muted-foreground">
            Nous vous tiendrons au courant de l'avancement de votre commande
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="phone" className="text-base">
            Numéro de téléphone <span className="text-red-500">*</span>
          </Label>
          <Input
            id="phone"
            type="tel"
            placeholder="Votre numéro de téléphone"
            value={data.phone_number}
            onChange={handlePhoneChange}
            className={error ? "border-red-500 ring-1 ring-red-500" : ""}
          />
          {error && <p className="text-sm text-red-500">{error}</p>}
          <p className="text-xs text-muted-foreground">
            Format: 06XXXXXXXX ou +33XXXXXXXXX
          </p>
        </div>
      </div>

      <div className="flex gap-4">
        <Button type="button" variant="outline" onClick={previousStep} className="w-full">
          Retour
        </Button>
        <Button type="submit" className="w-full">
          Continuer
        </Button>
      </div>
    </form>
  );
};
