
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import Navbar from "@/components/Navbar";
import { useEffect, useState } from "react";
import { ProfileForm } from "@/components/profile/ProfileForm";
import { SubscriptionInfo } from "@/components/profile/SubscriptionInfo";
import { DeleteAccount } from "@/components/profile/DeleteAccount";
import { DocumentsUpload } from "@/components/profile/DocumentsUpload";

const Profile = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    marketingEmails: false,
    phoneNumber: "",
    email: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    const getProfile = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from("profiles")
          .select("first_name, last_name, address, marketing_emails, phone_number")
          .eq("id", user.id)
          .single();

        if (error) {
          console.error("Error fetching profile:", error);
          throw error;
        }

        if (data) {
          setProfileData({
            firstName: data.first_name || "",
            lastName: data.last_name || "",
            address: data.address || "",
            marketingEmails: data.marketing_emails || false,
            phoneNumber: data.phone_number || "",
            email: user.email || "",
          });
        }
      } catch (error) {
        console.error("Error loading user data:", error);
        toast({
          title: "Erreur",
          description: "Impossible de charger les données du profil",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    getProfile();
  }, [user, navigate, toast]);

  if (!user) return null;

  if (loading) {
    return (
      <div className="min-h-screen bg-white">
        <Navbar />
        <div className="container mx-auto px-4 pt-32">
          <div className="max-w-2xl mx-auto">
            <p>Chargement du profil...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <div className="container mx-auto px-4 pt-32">
        <div className="max-w-2xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold">Mon Profil</h1>
            <Button variant="outline" onClick={signOut}>
              Se déconnecter
            </Button>
          </div>

          <div className="space-y-8">
            <ProfileForm
              userId={user.id}
              initialData={profileData}
            />

            <DocumentsUpload userId={user.id} />

            <SubscriptionInfo />

            <DeleteAccount userId={user.id} onDelete={signOut} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
