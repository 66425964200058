
import { createContext, useContext, useState, ReactNode } from "react";
import { supabase } from "@/integrations/supabase/client";

type MobileOperator = 
  | "Orange" | "SFR" | "Bouygues Telecom" | "Free Mobile" | "La Poste Mobile"
  | "NRJ Mobile" | "Coriolis Telecom" | "Reglo Mobile" | "Syma Mobile"
  | "Prixtel" | "youprice" | "lebara" | "Lyca" | "Autre";

interface OnboardingData {
  first_name: string;
  last_name: string;
  address: string;
  mobile_operator: MobileOperator | null;
  current_box_price: number | null;
  email: string;
  phone_number: string;
  password: string;
  // New survey fields
  has5GCoverage: "yes" | "no" | "unknown" | null;
  householdSize: string;
  tvMethod: "box" | "smart_tv" | "tnt" | "dont_watch" | "unknown" | null;
  streamingTime: string;
  playsGames: "yes" | "no" | "unknown" | null;
}

// Interface correspondant exactement au schéma de la table
interface OnboardingDbData {
  first_name?: string;
  last_name?: string;
  address?: string;
  mobile_operator?: MobileOperator;
  current_box_price?: number;
  email?: string;
  phone_number?: string;
  // New survey fields
  has_5g_coverage?: string;
  household_size?: string;
  tv_method?: string;
  streaming_time?: string;
  plays_games?: string;
}

interface OnboardingContextType {
  currentStep: number;
  data: OnboardingData;
  updateData: (newData: Partial<OnboardingData>) => void;
  nextStep: () => void;
  previousStep: () => void;
  saveStep: () => Promise<void>;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error("useOnboarding must be used within an OnboardingProvider");
  }
  return context;
};

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState<OnboardingData>({
    first_name: "",
    last_name: "",
    address: "",
    mobile_operator: null,
    current_box_price: null,
    email: "",
    phone_number: "",
    password: "",
    // Initialize new survey fields
    has5GCoverage: null,
    householdSize: "",
    tvMethod: null,
    streamingTime: "",
    playsGames: null,
  });

  const updateData = (newData: Partial<OnboardingData>) => {
    setData((prev) => ({ ...prev, ...newData }));
  };

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const previousStep = () => setCurrentStep((prev) => prev - 1);

  const saveStep = async () => {
    try {
      // Vérifie si on a déjà un enregistrement avec l'email (si l'email est défini)
      let existingId: string | null = null;
      
      if (data.email) {
        const { data: existing } = await supabase
          .from("onboarding_data")
          .select("id")
          .eq("email", data.email)
          .maybeSingle();
        
        if (existing) {
          existingId = existing.id;
        }
      }

      // Construction des données à sauvegarder, en fonction de l'étape actuelle
      const saveData: OnboardingDbData = {};
      
      // Étape 1 : Nom et prénom
      if (currentStep === 1) {
        if (data.first_name) saveData.first_name = data.first_name;
        if (data.last_name) saveData.last_name = data.last_name;
      }
      
      // Étape 2 : Adresse
      if (currentStep === 2) {
        if (data.address) saveData.address = data.address;
      }
      
      // Étape 3 : Survey data
      if (currentStep === 3) {
        if (data.has5GCoverage) saveData.has_5g_coverage = data.has5GCoverage;
        if (data.householdSize) saveData.household_size = data.householdSize;
        if (data.tvMethod) saveData.tv_method = data.tvMethod;
        if (data.streamingTime) saveData.streaming_time = data.streamingTime;
        if (data.playsGames) saveData.plays_games = data.playsGames;
      }
      
      // Étape 4 : Email
      if (currentStep === 4) {
        if (data.email) saveData.email = data.email;
      }
      
      // Étape 5 : Numéro de téléphone
      if (currentStep === 5) {
        if (data.phone_number) saveData.phone_number = data.phone_number;
      }

      // Ne sauvegarde que s'il y a des données à sauvegarder
      if (Object.keys(saveData).length > 0) {
        if (existingId) {
          // Mise à jour
          const { error: updateError } = await supabase
            .from("onboarding_data")
            .update(saveData)
            .eq("id", existingId);

          if (updateError) throw updateError;
        } else {
          // Insertion en autorisant les champs partiels
          const { error: insertError } = await supabase
            .from("onboarding_data")
            .insert(saveData);

          if (insertError) throw insertError;
        }
      }
    } catch (error) {
      console.error("Error saving onboarding data:", error);
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        currentStep,
        data,
        updateData,
        nextStep,
        previousStep,
        saveStep,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
