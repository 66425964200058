
import { Card, CardContent } from "@/components/ui/card";
import { Users2, Target, Heart, Trophy } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import Navbar from "@/components/Navbar";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

const APropos = () => {
  const values = [
    {
      icon: <Users2 className="w-12 h-12 text-primary" />,
      title: "Proximité",
      description: "Nous plaçons nos clients au cœur de nos préoccupations avec un support de qualité.",
    },
    {
      icon: <Target className="w-12 h-12 text-primary" />,
      title: "Innovation",
      description: "Nous développons constamment de nouvelles solutions pour améliorer votre expérience.",
    },
    {
      icon: <Heart className="w-12 h-12 text-primary" />,
      title: "Engagement",
      description: "Nous nous engageons à fournir un service fiable et de qualité à nos clients.",
    },
    {
      icon: <Trophy className="w-12 h-12 text-primary" />,
      title: "Excellence",
      description: "Nous visons l'excellence dans chacune de nos actions pour votre satisfaction.",
    },
  ];

  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      
      <main className="container mx-auto px-4 pt-24 pb-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">
            À propos d'Oneli
          </h1>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto mb-12">
            Découvrez notre histoire et notre mission pour révolutionner l'accès à Internet.
          </p>
          
          <div className="flex items-center justify-center gap-12 mb-16">
            <div className="flex flex-col items-center gap-3">
              <Avatar className="w-24 h-24">
                <AvatarImage src="/lovable-uploads/fbe031ed-ff3a-402d-a3d9-00a3d4e8fc11.png" alt="Photo d'Antoine" className="object-cover" />
                <AvatarFallback>A</AvatarFallback>
              </Avatar>
              <span className="font-medium">Antoine</span>
            </div>
            <div className="flex flex-col items-center gap-3">
              <Avatar className="w-24 h-24">
                <AvatarImage src="/lovable-uploads/511375aa-0d55-4b0a-8145-f06001e57da6.png" alt="Photo de Richard" className="object-cover" />
                <AvatarFallback>R</AvatarFallback>
              </Avatar>
              <span className="font-medium">Richard</span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <div className="space-y-6">
            <h2 className="text-3xl font-bold">Notre Histoire</h2>
            <p className="text-lg text-muted-foreground">
              Fondée en 2024, Oneli est née d'une vision simple : rendre l'accès à Internet plus simple et plus fiable pour tous. Notre équipe d'experts en télécommunications a développé une solution innovante qui révolutionne la manière dont nous nous connectons à Internet.
            </p>
            <p className="text-lg text-muted-foreground">
              Aujourd'hui, nous sommes fiers de servir des milliers de clients satisfaits à travers la France, en leur offrant une connexion Internet stable et performante, sans complications techniques.
            </p>
          </div>
          <div className="space-y-6">
            <h2 className="text-3xl font-bold">Notre Mission</h2>
            <p className="text-lg text-muted-foreground">
              Chez Oneli, notre mission est de démocratiser l'accès à une connexion Internet de qualité. Nous croyons que chacun devrait pouvoir profiter d'une connexion rapide et fiable, sans avoir besoin de connaissances techniques avancées.
            </p>
            <p className="text-lg text-muted-foreground">
              Notre engagement envers l'innovation et la satisfaction client guide chacune de nos actions, nous permettant de proposer des solutions toujours plus performantes et adaptées aux besoins de nos utilisateurs.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {values.map((value, index) => (
            <Card key={index} className="text-center p-6">
              <CardContent className="space-y-4">
                <div className="flex justify-center">{value.icon}</div>
                <h3 className="text-xl font-bold">{value.title}</h3>
                <p className="text-muted-foreground">{value.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>

        <div className="text-center space-y-8">
          <div className="max-w-2xl mx-auto">
            <h2 className="text-3xl font-bold mb-4">
              Rejoignez l'aventure Oneli
            </h2>
            <p className="text-lg text-muted-foreground mb-8">
              Découvrez par vous-même pourquoi des milliers de clients nous font confiance pour leur connexion Internet.
            </p>
          </div>
          <Link to="/commander">
            <Button size="lg" className="bg-primary hover:bg-primary-dark">
              Commander ma box
            </Button>
          </Link>
        </div>
      </main>
    </div>
  );
};

export default APropos;
