
import { useAuth } from "@/contexts/AuthContext";
import Navbar from "@/components/Navbar";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Loader2 } from "lucide-react";
import { useUserData } from "@/hooks/useUserData";
import { useSortableData } from "@/hooks/useSortableData";
import { UserTable } from "@/components/admin/UserTable";
import { SubscriptionSync } from "@/components/admin/SubscriptionSync";
import { DataIntegrityCheck } from "@/components/admin/DataIntegrityCheck";
import { useEffect } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { FindStripeCustomerDialog } from "@/components/admin/stripe/FindStripeCustomerDialog";

const Admin = () => {
  const { user } = useAuth();
  const { users, loading } = useUserData(user?.id);
  const { data, sortData, sortConfig, getUserSubscriptionStatus } = useSortableData(users);

  useEffect(() => {
    if (users.length > 0) {
      // Log users with multiple customer IDs for debugging
      users.forEach(user => {
        if (user.subscriptions?.[0]?.all_stripe_customer_ids?.length > 1) {
          console.log(`User ${user.email} has multiple customer IDs:`, 
            user.subscriptions[0].all_stripe_customer_ids,
            `Active ID: ${user.subscriptions[0].stripe_customer_id}`);
        }
      });
      
      // Vérifier les documents de richardwinckels@gmail.com
      const richardUser = users.find(user => user.email === 'richardwinckels@gmail.com');
      if (richardUser) {
        console.log("Documents de Richard depuis Admin:", richardUser.documents);
      }
      
      // Compter les abonnements par statut
      const statusCounts: Record<string, number> = {};
      users.forEach(user => {
        const status = getUserSubscriptionStatus(user);
        statusCounts[status] = (statusCounts[status] || 0) + 1;
      });
      
      console.log("Statuts d'abonnement:", statusCounts);
      console.log("Nombre d'utilisateurs avec documents:", users.filter(user => user.documents).length);
    }
  }, [users, getUserSubscriptionStatus]);

  if (loading) {
    return (
      <div className="min-h-screen bg-background">
        <Navbar />
        <div className="container mx-auto px-4 py-24 flex justify-center">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      </div>
    );
  }

  // Calculer des statistiques sur les abonnements
  const subscriptionStats = {
    total: data.length,
    active: data.filter(user => getUserSubscriptionStatus(user) === 'active').length,
    trialing: data.filter(user => getUserSubscriptionStatus(user) === 'trialing').length,
    canceled: data.filter(user => getUserSubscriptionStatus(user) === 'canceled').length,
    noSubscription: data.filter(user => getUserSubscriptionStatus(user) === 'no_subscription').length
  };

  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      <div className="container mx-auto px-4 py-24">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Administration</h1>
          <div className="flex gap-4 items-center">
            <FindStripeCustomerDialog />
            <div className="flex gap-2">
              <Badge variant="outline" className="text-base">
                {subscriptionStats.total} utilisateur{subscriptionStats.total > 1 ? 's' : ''}
              </Badge>
              <Badge variant="secondary" className="bg-green-50 text-green-700 border-green-200">
                {subscriptionStats.active} actif{subscriptionStats.active > 1 ? 's' : ''}
              </Badge>
              <Badge variant="secondary" className="bg-blue-50 text-blue-700 border-blue-200">
                {subscriptionStats.trialing} en essai
              </Badge>
            </div>
          </div>
        </div>
        
        <Tabs defaultValue="users">
          <TabsList className="mb-4">
            <TabsTrigger value="users">Utilisateurs</TabsTrigger>
            <TabsTrigger value="maintenance">Maintenance</TabsTrigger>
          </TabsList>
          
          <TabsContent value="users">
            <Card>
              <UserTable 
                users={data} 
                onSort={sortData}
                sortConfig={sortConfig}
                getUserSubscriptionStatus={getUserSubscriptionStatus}
              />
            </Card>
          </TabsContent>
          
          <TabsContent value="maintenance">
            <SubscriptionSync />
            <DataIntegrityCheck />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Admin;
