
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useOnboarding } from "@/contexts/OnboardingContext";
import { useState } from "react";

export const EmailStep = () => {
  const { data, updateData, nextStep, previousStep, saveStep } = useOnboarding();
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!data.email) {
      setError("Veuillez entrer votre adresse email");
      return;
    }
    await saveStep();
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold">Votre email</h1>
        <p className="text-muted-foreground">
          Pour créer votre compte et commander votre box Oneli
        </p>
      </div>

      <div>
        <Label htmlFor="email">Adresse email</Label>
        <Input
          id="email"
          type="email"
          value={data.email}
          onChange={(e) => updateData({ email: e.target.value })}
          placeholder="jean.dupont@exemple.com"
        />
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}

      <div className="flex gap-4">
        <Button type="button" variant="outline" onClick={previousStep} className="w-full">
          Retour
        </Button>
        <Button type="submit" className="w-full">
          Continuer
        </Button>
      </div>
    </form>
  );
};
