
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Footer from "./components/Footer";
import Index from "./pages/Index";
import Utilisations from "./pages/Utilisations";
import Technologie from "./pages/Technologie";
import Tarifs from "./pages/Tarifs";
import APropos from "./pages/APropos";
import Commander from "./pages/Commander";
import Auth from "./pages/Auth";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import NotFound from "./pages/NotFound";
import MentionsLegales from "./pages/MentionsLegales";
import Confidentialite from "./pages/Confidentialite";
import CGV from "./pages/CGV";
import CommentCaMarche from "./pages/CommentCaMarche";
import Success from "./pages/Success";
import Rendezvous from "./pages/Rendezvous";
import Eligibilite from "./pages/Eligibilite";
import Admin from "./pages/Admin";
import Abonnement from "./pages/Abonnement";
import SansFibre from "./pages/SansFibre";
import Professionnel from "./pages/Professionnel";
import Solutions from "./pages/pro/Solutions";
import TarifsPro from "./pages/pro/TarifsPro";
import CasClients from "./pages/pro/CasClients";

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <BrowserRouter>
        <AuthProvider>
          <div className="min-h-screen flex flex-col">
            <Toaster />
            <Sonner />
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/utilisations" element={<Utilisations />} />
              <Route path="/technologie" element={<Technologie />} />
              <Route path="/tarifs" element={<Tarifs />} />
              <Route path="/a-propos" element={<APropos />} />
              <Route path="/commander" element={<Commander />} />
              <Route path="/login" element={<Auth />} />
              <Route path="/inscription" element={<Register />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/abonnement" element={<Abonnement />} />
              <Route path="/mentions-legales" element={<MentionsLegales />} />
              <Route path="/confidentialite" element={<Confidentialite />} />
              <Route path="/cgv" element={<CGV />} />
              <Route path="/comment-ca-marche" element={<CommentCaMarche />} />
              <Route path="/success" element={<Success />} />
              <Route path="/rendezvous" element={<Rendezvous />} />
              <Route path="/eligibilite" element={<Eligibilite />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/sans-fibre" element={<SansFibre />} />
              <Route path="/professionnel" element={<Professionnel />} />
              <Route path="/professionnel/solutions" element={<Solutions />} />
              <Route path="/professionnel/tarifs" element={<TarifsPro />} />
              <Route path="/professionnel/cas-clients" element={<CasClients />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </AuthProvider>
      </BrowserRouter>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;
