import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

interface MarketingPreferencesProps {
  marketingEmails: boolean;
  onMarketingEmailsChange: (checked: boolean) => void;
}

export const MarketingPreferences = ({
  marketingEmails,
  onMarketingEmailsChange,
}: MarketingPreferencesProps) => {
  return (
    <div className="flex items-center space-x-2">
      <Switch
        id="marketing"
        checked={marketingEmails}
        onCheckedChange={onMarketingEmailsChange}
      />
      <Label htmlFor="marketing">
        Je souhaite recevoir des emails publicitaires
      </Label>
    </div>
  );
};