
import { Wifi, Zap, Package, Smartphone, Plug, House, Calendar, Command } from "lucide-react";
import Navbar from "@/components/Navbar";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";

const CommentCaMarche = () => {
  const steps = [
    "1. Vous recevez votre box chez vous",
    "2. Vous branchez la prise",
    "3. La box détecte automatiquement le réseau 4G ou 5G",
    "4. En 2 minutes, le wifi est prêt pour tous vos appareils",
    "5. Aucun technicien. Aucun délai. Aucune prise internet requise."
  ];

  const userTypes = [
    "📱 Les foyers de 1 à 3 personnes",
    "💼 Les télétravailleurs ou petits pros",
    "🏡 Les résidences secondaires ou logements étudiants",
    "🛑 Ceux qui veulent éviter les abonnements fibre classiques"
  ];

  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      
      <main className="container mx-auto px-4 pt-24 pb-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">
            Comment ça marche ?
          </h1>
          <p className="text-xl text-muted-foreground max-w-3xl mx-auto">
            Une box wifi 4G/5G prête à l'emploi, pour un internet rapide, simple et flexible.
          </p>
        </div>

        {/* Une box wifi, sans la complexité */}
        <div className="max-w-4xl mx-auto mb-20">
          <h2 className="text-3xl font-bold mb-8 text-center">Une box wifi, sans la complexité</h2>
          <div className="bg-blue-50 rounded-2xl p-8 shadow-md">
            <p className="text-lg mb-6">
              Oneli, c'est une petite box 4G/5G qui vous connecte à internet en très haut débit, sans technicien, sans câbles, sans rendez-vous.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-start gap-4">
                <div className="bg-primary/10 p-3 rounded-full">
                  <Command className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <p className="font-medium">Elle se configure automatiquement dès que vous la branchez.</p>
                </div>
              </div>
              <div className="flex items-start gap-4">
                <div className="bg-primary/10 p-3 rounded-full">
                  <Plug className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <p className="font-medium">Une prise électrique suffit.</p>
                </div>
              </div>
              <div className="flex items-start gap-4">
                <div className="bg-primary/10 p-3 rounded-full">
                  <Wifi className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <p className="font-medium">Elle capte les réseaux mobiles comme votre téléphone, mais les transforme en wifi stable pour tout le foyer.</p>
                </div>
              </div>
              <div className="flex items-start gap-4">
                <div className="bg-primary/10 p-3 rounded-full">
                  <House className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <p className="font-medium">Idéal pour la maison, un studio, une résidence secondaire ou le télétravail.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Branchée. Allumée. Connectée. */}
        <div className="max-w-5xl mx-auto mb-20">
          <h2 className="text-3xl font-bold mb-8 text-center">Branchée. Allumée. Connectée.</h2>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-stretch">
            <div className="bg-primary/5 p-8 rounded-2xl h-full flex flex-col justify-center">
              <div className="space-y-6">
                {steps.map((step, index) => (
                  <div key={index} className="flex items-center gap-4">
                    <div className="bg-primary text-white w-10 h-10 rounded-full flex items-center justify-center flex-shrink-0 text-lg font-bold">
                      {index + 1}
                    </div>
                    <p className="text-lg font-medium">{step.substring(3)}</p>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="rounded-2xl shadow-md bg-white overflow-hidden h-full flex flex-col">
              <div className="flex-grow">
                <img 
                  src="/lovable-uploads/a3678d98-57a9-427d-8eb6-ec76a02ae54e.png" 
                  alt="Processus d'installation de la box Oneli en 4 étapes" 
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-center text-gray-600 py-3 px-4 bg-white">
                Un processus simple, de la commande à l'utilisation
              </p>
            </div>
          </div>
        </div>

        {/* Deux façons d'en profiter */}
        <div className="max-w-4xl mx-auto mb-20">
          <h2 className="text-3xl font-bold mb-4 text-center">Deux façons d'en profiter</h2>
          <p className="text-xl text-center text-muted-foreground mb-8">Choisissez la formule qui vous convient</p>
          
          <Card className="border-none shadow-md hover:shadow-lg transition-shadow duration-300">
            <CardContent className="p-8">
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="bg-primary/10 p-3 rounded-full">
                    <Package className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <p className="font-bold mb-2">Avec forfait inclus</p>
                    <p>La box est prête à fonctionner dès sa réception, tout est déjà configuré pour vous.</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-4">
                  <div className="bg-primary/10 p-3 rounded-full">
                    <Zap className="w-6 h-6 text-primary" />
                  </div>
                  <div>
                    <p className="font-bold mb-2">En version libre</p>
                    <p>Vous achetez la box et utilisez votre propre forfait data, avec la flexibilité de choisir votre opérateur.</p>
                  </div>
                </div>
                
                <p className="text-center text-gray-600 mt-6">
                  Dans les deux cas, la box fonctionne exactement de la même manière : simple, rapide, et efficace.
                </p>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Où ça marche ? */}
        <div className="max-w-4xl mx-auto mb-20">
          <h2 className="text-3xl font-bold mb-8 text-center">Où ça marche ?</h2>
          <div className="bg-gray-50 p-8 rounded-2xl text-center shadow-sm hover:shadow-md transition-shadow duration-300">
            <p className="text-2xl font-medium mb-6">🌍 Partout où le réseau mobile passe.</p>
            <p className="text-lg mb-4">
              Oneli utilise les réseaux 4G/5G disponibles (Bouygues, SFR, Orange…), comme votre smartphone.
            </p>
            <p className="text-lg">
              Que vous soyez en ville, en campagne, en déplacement ou en colocation : la box trouve le signal, et vous crée un wifi stable.
            </p>
          </div>
        </div>

        {/* C'est pour qui ? */}
        <div className="max-w-4xl mx-auto mb-20">
          <h2 className="text-3xl font-bold mb-8 text-center">C'est pour qui ?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {userTypes.map((item, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow duration-300 flex items-center gap-4">
                <span className="text-2xl">{item.substring(0, 2)}</span>
                <span className="text-lg">{item.substring(3)}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Prêt à essayer ? */}
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Prêt à essayer ?</h2>
          <Link to="/commander">
            <Button size="lg" className="bg-primary hover:bg-primary-dark text-lg px-8 py-6 h-auto hover:scale-105 transition-transform duration-300">
              Commander
            </Button>
          </Link>
        </div>
      </main>
    </div>
  );
};

export default CommentCaMarche;
