import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

const formSchema = z.object({
  householdSize: z.number().min(1, "Le nombre de personnes doit être d'au moins 1"),
  isIsolated: z.boolean(),
});

type HouseholdStepProps = {
  data: {
    householdSize: number;
    isIsolated: boolean;
  };
  onUpdate: (data: { householdSize: number; isIsolated: boolean }) => void;
  onNext: () => void;
};

export const HouseholdStep = ({ data, onUpdate, onNext }: HouseholdStepProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      householdSize: data.householdSize || 1,
      isIsolated: data.isIsolated || false,
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    onUpdate({
      householdSize: values.householdSize,
      isIsolated: values.isIsolated,
    });
    onNext();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="householdSize"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Combien de personnes composent votre foyer ?</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  min={1}
                  {...field}
                  onChange={(e) => field.onChange(parseInt(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="isIsolated"
          render={({ field }) => (
            <FormItem>
              <FormLabel>La box est-elle située dans un lieu isolé ?</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={(value) => field.onChange(value === "true")}
                  defaultValue={field.value.toString()}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="false" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      Non
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="true" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      Oui
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end">
          <Button type="submit">
            Suivant
          </Button>
        </div>
      </form>
    </Form>
  );
};