
import ProNavbar from "@/components/ProNavbar";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { CheckCircle2, X } from "lucide-react";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { useState } from "react";
import NewsletterSubscribeModal from "@/components/NewsletterSubscribeModal";

const TarifsPro = () => {
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
  
  const plans = [
    {
      name: "Essentiel",
      price: "39,90€",
      priceDetail: "/mois",
      engagement: "sans engagement",
      description: "Idéal pour les TPE et les indépendants",
      features: [
        "Box 4G+ incluse",
        "Jusqu'à 150 Mbps en débit descendant",
        "Connexion de 10 appareils",
        "Data illimitée",
        "Support technique prioritaire"
      ],
      negativeFeatures: [],
      cta: "Commander",
      popular: false
    },
    {
      name: "Performance",
      price: "59,90€",
      priceDetail: "/mois",
      engagement: "sans engagement",
      description: "Pour les PME et commerces à forte affluence",
      features: [
        "Box 5G incluse",
        "Jusqu'à 600 Mbps en débit descendant",
        "Connexion de 50 appareils",
        "Data illimitée",
        "Support technique dédié",
        "Installation express garantie",
        "IP fixe"
      ],
      negativeFeatures: [],
      cta: "Commander",
      popular: true
    },
    {
      name: "Entreprise",
      price: "99,90€",
      priceDetail: "/mois",
      engagement: "sans engagement",
      description: "Solution complète pour les entreprises",
      features: [
        "Box 5G+ haute performance incluse",
        "Jusqu'à 1 Gbps en débit descendant",
        "Connexion de 100 appareils",
        "Data illimitée prioritaire",
        "Support technique 24/7",
        "Installation et configuration sur site",
        "IP fixe et VPN",
        "Redondance garantie"
      ],
      negativeFeatures: [],
      cta: "Contacter un conseiller",
      popular: false
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <ProNavbar />
      
      <div className="container mx-auto px-4 pt-28 pb-16">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Tarifs Professionnels</h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Des solutions adaptées à tous les besoins professionnels, sans engagement et sans surprise.
          </p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {plans.map((plan, index) => (
            <Card 
              key={index} 
              className={`relative ${plan.popular ? 'border-primary shadow-lg' : 'border-gray-200'}`}
            >
              {plan.popular && (
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary text-white px-4 py-1 rounded-full text-sm font-medium">
                  Recommandé
                </div>
              )}
              <CardHeader>
                <CardTitle className="text-2xl">{plan.name}</CardTitle>
                <div className="mt-2">
                  <div className="flex flex-col items-start">
                    <span className="text-3xl font-bold">{plan.price}</span>
                    <div className="flex items-center">
                      <span className="text-gray-500 ml-1">{plan.priceDetail}</span>
                    </div>
                    <div className="text-sm text-gray-500">{plan.engagement}</div>
                  </div>
                </div>
                <p className="text-gray-600 mt-2">{plan.description}</p>
              </CardHeader>
              <CardContent>
                <ul className="space-y-3">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start gap-2">
                      <CheckCircle2 className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                      <span>{feature}</span>
                    </li>
                  ))}
                  {plan.negativeFeatures && plan.negativeFeatures.map((feature, featureIndex) => (
                    <li key={`neg-${featureIndex}`} className="flex items-start gap-2">
                      <X className="h-5 w-5 text-gray-400 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                <Link to={plan.cta === "Commander" ? "/commander" : "/rendezvous"} className="w-full">
                  <Button 
                    className={`w-full ${plan.popular ? 'bg-primary' : ''}`}
                    variant={plan.popular ? "default" : "outline"}
                  >
                    {plan.cta}
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          ))}
        </div>
        
        <div className="mt-16 text-center">
          <h2 className="text-2xl font-bold mb-4">Vous avez des besoins spécifiques ?</h2>
          <p className="max-w-2xl mx-auto text-gray-600 mb-6">
            Nous proposons également des solutions sur mesure pour les événements professionnels, 
            chantiers temporaires, et besoins spécifiques.
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <Link to="/rendezvous">
              <Button size="lg">Demander un devis personnalisé</Button>
            </Link>
            <Button 
              size="lg" 
              variant="outline"
              onClick={() => setSubscribeModalOpen(true)}
            >
              Rester informé des nouveautés
            </Button>
          </div>
        </div>
      </div>
      
      <NewsletterSubscribeModal 
        open={subscribeModalOpen} 
        onOpenChange={setSubscribeModalOpen}
        title="Restez informé des nouveautés" 
        description="Laissez-nous votre email pour être parmi les premiers à connaître nos nouvelles offres pro."
      />
    </div>
  );
};

export default TarifsPro;
