
import React, { useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Mail } from "lucide-react";

interface NewsletterSubscribeModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title?: string;
  description?: string;
}

const NewsletterSubscribeModal = ({
  open,
  onOpenChange,
  title = "Restez informé",
  description = "Laissez-nous votre email pour être informé dès que notre offre TV sera disponible."
}: NewsletterSubscribeModalProps) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email || !email.includes("@")) {
      toast({
        title: "Email invalide",
        description: "Veuillez entrer une adresse email valide.",
        variant: "destructive"
      });
      return;
    }

    setIsSubmitting(true);
    
    try {
      // Call the Supabase Edge Function instead of direct DB access
      const { data, error } = await supabase.functions.invoke('newsletter-subscribe', {
        body: { email }
      });
      
      if (error) {
        throw error;
      }
      
      if (data.error) {
        if (data.error.includes("déjà inscrite")) {
          toast({
            title: "Déjà inscrit",
            description: "Cette adresse email est déjà inscrite à notre newsletter.",
            variant: "default"
          });
        } else {
          throw new Error(data.error);
        }
      } else {
        toast({
          title: "Inscription réussie",
          description: "Merci ! Vous serez informé dès que notre offre TV sera disponible.",
          variant: "default"
        });
        setEmail("");
        onOpenChange(false);
      }
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
      toast({
        title: "Une erreur est survenue",
        description: "Impossible de vous inscrire pour le moment. Veuillez réessayer plus tard.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        
        <form onSubmit={handleSubmit} className="space-y-4 py-4">
          <div className="flex items-center space-x-2">
            <Mail className="h-5 w-5 text-gray-400" />
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="votre@email.com"
              type="email"
              className="flex-1"
              required
            />
          </div>
          
          <DialogFooter className="pt-2">
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Inscription..." : "S'inscrire"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default NewsletterSubscribeModal;
