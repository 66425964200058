
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface DataPlanModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function DataPlanModal({ open, onOpenChange }: DataPlanModalProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] p-0 overflow-hidden rounded-xl border border-white">
        {/* Hidden DialogTitle for accessibility */}
        <DialogTitle className="sr-only">Grille tarifaire des données</DialogTitle>
        
        <div className="p-8 bg-white">
          <div className="w-full flex flex-col items-center space-y-4">
            {/* Visuel des paliers de consommation */}
            <div className="w-full mt-4">
              <img 
                src="/lovable-uploads/23eecfc5-15c9-47d0-879f-35043f8b7ac2.png" 
                alt="Grille tarifaire selon la consommation de données" 
                className="w-full h-auto object-contain"
              />
            </div>
          </div>
        </div>

        <div className="bg-gray-50 p-6 flex justify-end border-t border-white">
          <Button 
            onClick={() => onOpenChange(false)}
            className="px-8"
          >
            J'ai compris
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
