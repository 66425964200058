
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import Navbar from "@/components/Navbar";
import { useAuth } from "@/contexts/AuthContext";
import { useSubscriptionData } from "@/components/profile/subscription/useSubscriptionData";
import { ActiveSubscriptionDetails } from "@/components/profile/subscription/ActiveSubscriptionDetails";
import { SubscriptionPlans } from "@/components/profile/subscription/SubscriptionPlans";
import { SubscriptionErrorDialog } from "@/components/profile/subscription/SubscriptionErrorDialog";
import { useSubscriptionHandler } from "@/components/profile/subscription/useSubscriptionHandler";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Button } from "@/components/ui/button";

const Abonnement = () => {
  const { 
    selectedPlan, 
    setSelectedPlan, 
    isLoading, 
    errorDialogOpen, 
    setErrorDialogOpen, 
    errorMessage, 
    handleSubscribe 
  } = useSubscriptionHandler();
  
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscriptionData();
  const { user } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (!user) return null;

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <div className="container mx-auto px-4 pt-32">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-3xl font-bold mb-8">Mon Abonnement</h1>
          
          <Card>
            <CardHeader>
              <CardTitle>Détails de l'abonnement</CardTitle>
            </CardHeader>
            <CardContent>
              {isLoadingSubscription ? (
                <div className="flex justify-center p-4">
                  <Loader2 className="h-6 w-6 animate-spin" />
                </div>
              ) : subscription?.subscribed ? (
                <div className="space-y-6">
                  <ActiveSubscriptionDetails subscription={subscription} showDetailedInfo={true} />
                </div>
              ) : (
                <div className="space-y-6">
                  <p className="text-muted-foreground mb-4">
                    Vous n'avez pas encore d'abonnement actif. Souscrivez à notre offre pour profiter de tous les avantages.
                  </p>
                  
                  <SubscriptionPlans 
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  />
                  
                  <Button 
                    onClick={handleSubscribe}
                    disabled={isLoading}
                    className="w-full"
                  >
                    {isLoading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Chargement...
                      </>
                    ) : (
                      "Souscrire maintenant"
                    )}
                  </Button>
                </div>
              )}
            </CardContent>
          </Card>

          <SubscriptionErrorDialog
            open={errorDialogOpen}
            onOpenChange={setErrorDialogOpen}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default Abonnement;
