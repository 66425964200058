import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

const formSchema = z.object({
  usageType: z.enum(["private", "professional"]),
  housingType: z.string().optional(),
  businessType: z.string().optional(),
}).refine((data) => {
  if (data.usageType === "private" && !data.housingType) {
    return false;
  }
  return true;
}, {
  message: "Veuillez sélectionner un type de logement",
  path: ["housingType"]
}).refine((data) => {
  if (data.usageType === "professional" && !data.businessType) {
    return false;
  }
  return true;
}, {
  message: "Veuillez sélectionner un type d'activité",
  path: ["businessType"]
});

type UsageTypeStepProps = {
  data: {
    usageType: "private" | "professional" | null;
    housingType: string | null;
    businessType: string | null;
  };
  onUpdate: (data: {
    usageType: "private" | "professional";
    housingType?: string;
    businessType?: string;
  }) => void;
  onBack: () => void;
  onNext: () => void;
};

export const UsageTypeStep = ({ data, onUpdate, onBack, onNext }: UsageTypeStepProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      usageType: data.usageType || "private",
      housingType: data.housingType || undefined,
      businessType: data.businessType || undefined,
    },
  });

  const usageType = form.watch("usageType");

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    onUpdate({
      usageType: values.usageType,
      housingType: values.housingType,
      businessType: values.businessType,
    });
    onNext();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="usageType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Quel est l'usage principal de la box ?</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="private" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      Privé
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="professional" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      Professionnel
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {usageType === "private" && (
          <FormField
            control={form.control}
            name="housingType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Dans quel type de logement utilisez-vous la box ?</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex flex-col space-y-1"
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="student_housing" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        Logement étudiant
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="secondary_residence" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        Résidence secondaire
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="city_center" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        Logement en centre-ville
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {usageType === "professional" && (
          <FormField
            control={form.control}
            name="businessType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Quel est le type d'activité ?</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex flex-col space-y-1"
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="city_store" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        Commerce en centre-ville
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="small_office" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        Petit bureau
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="enterprise" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        Entreprise
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <div className="flex justify-between">
          <Button type="button" variant="outline" onClick={onBack}>
            Retour
          </Button>
          <Button type="submit">
            Vérifier l'éligibilité
          </Button>
        </div>
      </form>
    </Form>
  );
};