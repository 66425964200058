
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useOnboarding } from "@/contexts/OnboardingContext";
import { useState } from "react";
import { Check } from "lucide-react";
import { cn } from "@/lib/utils";

interface AddressFeature {
  properties: {
    label: string;
    postcode: string;
    city: string;
  };
}

export const AddressStep = () => {
  const { data, updateData, nextStep, previousStep, saveStep } = useOnboarding();
  const [error, setError] = useState("");
  const [suggestions, setSuggestions] = useState<AddressFeature[]>([]);
  const [inputValue, setInputValue] = useState(data.address || "");
  const [loading, setLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const fetchAddresses = async (query: string) => {
    const cleanQuery = query.trim();
    
    if (cleanQuery.length < 3 || !/^[a-zA-Z0-9]/.test(cleanQuery)) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(cleanQuery)}&limit=10`
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setSuggestions(data.features || []);
    } catch (error) {
      console.error("Erreur lors de la recherche d'adresses:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleAddressSelect = (address: string) => {
    updateData({ address });
    setInputValue(address);
    setShowSuggestions(false);
    setSuggestions([]);
  };

  const handleManualAddress = () => {
    setShowSuggestions(false);
    setSuggestions([]);
    // On garde la valeur actuellement saisie dans l'input
    if (inputValue) {
      updateData({ address: inputValue });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!data.address) {
      setError("Veuillez entrer votre adresse");
      return;
    }
    await saveStep();
    nextStep();
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    updateData({ address: value });
    setShowSuggestions(true);
    
    const timeoutId = setTimeout(() => {
      fetchAddresses(value);
    }, 300);

    return () => clearTimeout(timeoutId);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold">Votre adresse d'installation</h1>
        <p className="text-muted-foreground">
          Vérifions que la 5G est bien disponible chez vous ?
        </p>
      </div>

      <div className="flex flex-col space-y-2 relative">
        <Label htmlFor="address">Adresse complète</Label>
        <Input
          id="address"
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          onFocus={() => setShowSuggestions(true)}
          placeholder="123 rue des Lilas, 75001 Paris"
          className="w-full"
        />
        
        {showSuggestions && (suggestions.length > 0 || loading) && (
          <div className="absolute z-50 top-full mt-1 w-full bg-white rounded-md border border-gray-200 shadow-lg">
            {loading ? (
              <div className="p-2 text-sm text-gray-500">Recherche en cours...</div>
            ) : (
              <ul className="max-h-[300px] overflow-y-auto">
                {suggestions.map((item) => (
                  <li
                    key={item.properties.label}
                    onClick={() => handleAddressSelect(item.properties.label)}
                    className={cn(
                      "flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100",
                      data.address === item.properties.label && "bg-gray-50"
                    )}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4 flex-shrink-0",
                        data.address === item.properties.label ? "opacity-100" : "opacity-0"
                      )}
                    />
                    <span>{item.properties.label}</span>
                  </li>
                ))}
                <li
                  onClick={handleManualAddress}
                  className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 border-t border-gray-200 text-blue-600"
                >
                  Je ne trouve pas mon adresse
                </li>
              </ul>
            )}
          </div>
        )}
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}

      <div className="flex gap-4">
        <Button type="button" variant="outline" onClick={previousStep} className="w-full">
          Retour
        </Button>
        <Button type="submit" className="w-full">
          Continuer
        </Button>
      </div>
    </form>
  );
};
