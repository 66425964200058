
import ProNavbar from "@/components/ProNavbar";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Building2, Briefcase, Home, Shield, Zap, Wifi, Clock, ArrowRight } from "lucide-react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";

const Solutions = () => {
  const solutions = [
    {
      icon: <Building2 className="w-12 h-12 text-primary" />,
      title: "Internet principal",
      description: "Une connexion fiable et rapide comme alternative aux solutions filaires traditionnelles.",
      features: [
        "Installation sans travaux en quelques minutes",
        "Débit élevé et stable pour toutes vos activités",
        "Idéal pour les zones mal desservies par la fibre",
        "Solution sans délai d'activation"
      ],
      cta: "Découvrir notre offre"
    },
    {
      icon: <Shield className="w-12 h-12 text-primary" />,
      title: "Connexion de secours",
      description: "Assurez la continuité de votre activité en cas de panne de votre connexion principale.",
      features: [
        "Basculement automatique en cas de panne",
        "Garantie de service pour vos opérations critiques",
        "Idéal pour les terminaux de paiement et systèmes de sécurité",
        "Tranquillité d'esprit pour votre activité"
      ],
      cta: "En savoir plus"
    },
    {
      icon: <Home className="w-12 h-12 text-primary" />,
      title: "Chantiers & Sites temporaires",
      description: "Connectez vos sites temporaires sans attendre l'installation d'une ligne fixe.",
      features: [
        "Déploiement immédiat sur vos chantiers",
        "Solution portable et facilement déplaçable",
        "Idéal pour les agences immobilières et entreprises du BTP",
        "Sans engagement de durée"
      ],
      cta: "Voir cette solution"
    },
    {
      icon: <Briefcase className="w-12 h-12 text-primary" />,
      title: "WiFi client & événements",
      description: "Offrez un accès Internet de qualité à vos clients ou lors d'événements professionnels.",
      features: [
        "Portail captif personnalisable pour vos clients",
        "Solution évolutive pour les événements ponctuels",
        "Statistiques d'utilisation détaillées",
        "Connexion sécurisée et fiable"
      ],
      cta: "Découvrir l'offre"
    }
  ];

  const advantages = [
    {
      icon: <Clock className="w-10 h-10 text-primary" />,
      title: "Rapidité de déploiement",
      description: "Installation en quelques minutes, sans intervention technique ni travaux."
    },
    {
      icon: <Wifi className="w-10 h-10 text-primary" />,
      title: "Flexibilité maximale",
      description: "Solution sans engagement, idéale pour s'adapter à l'évolution de votre activité."
    },
    {
      icon: <Zap className="w-10 h-10 text-primary" />,
      title: "Performance garantie",
      description: "Débit élevé et stable pour tous vos usages professionnels, même les plus exigeants."
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <ProNavbar />
      
      <div className="pt-28">
        {/* Hero Section */}
        <section className="container mx-auto px-4 py-12">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl font-bold mb-6">Solutions Internet pour Professionnels</h1>
            <p className="text-xl text-gray-600 mb-8">
              Découvrez nos solutions adaptées aux besoins spécifiques des entreprises, commerces et professions libérales.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <Link to="/commander">
                <Button size="lg" className="bg-primary hover:bg-primary-dark text-white">
                  Tester Gratuitement
                </Button>
              </Link>
              <Link to="/rendezvous">
                <Button size="lg" variant="outline">
                  Demander un conseil personnalisé
                </Button>
              </Link>
            </div>
          </div>
        </section>
        
        {/* Solutions Section */}
        <section className="container mx-auto px-4 py-16">
          <h2 className="text-3xl font-bold text-center mb-12">Nos solutions professionnelles</h2>
          
          <div className="grid md:grid-cols-2 gap-8">
            {solutions.map((solution, index) => (
              <Card key={index} className="border-none shadow-lg">
                <CardHeader>
                  <div className="flex items-center gap-4">
                    <div className="bg-primary/10 p-3 rounded-lg">
                      {solution.icon}
                    </div>
                    <div>
                      <CardTitle className="text-2xl">{solution.title}</CardTitle>
                      <CardDescription className="text-gray-600 mt-1">
                        {solution.description}
                      </CardDescription>
                    </div>
                  </div>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {solution.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center gap-2">
                        <div className="h-1.5 w-1.5 rounded-full bg-primary flex-shrink-0"></div>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter>
                  <Link to="/professionnel/tarifs" className="text-primary font-medium flex items-center gap-1 hover:underline">
                    {solution.cta} <ArrowRight className="h-4 w-4" />
                  </Link>
                </CardFooter>
              </Card>
            ))}
          </div>
        </section>
        
        {/* Advantages Section */}
        <section className="bg-gray-50 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Pourquoi choisir Oneli Pro ?</h2>
            
            <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
              {advantages.map((advantage, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow text-center">
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-primary/10 rounded-full">
                      {advantage.icon}
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{advantage.title}</h3>
                  <p className="text-gray-600">{advantage.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        {/* CTA Section */}
        <section className="container mx-auto px-4 py-16">
          <div className="max-w-4xl mx-auto bg-primary rounded-xl text-white p-8 text-center">
            <h2 className="text-3xl font-bold mb-4">Prêt à améliorer votre connectivité professionnelle ?</h2>
            <p className="text-xl mb-8">
              Essayez notre solution sans engagement et constatez la différence pour votre entreprise.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <Link to="/commander">
                <Button 
                  size="lg" 
                  className="bg-white text-primary hover:bg-gray-100"
                >
                  Tester Gratuitement
                </Button>
              </Link>
              <Link to="/professionnel/cas-clients">
                <Button 
                  size="lg" 
                  variant="outline"
                  className="border-white text-white hover:bg-white/20"
                >
                  Voir nos cas clients
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Solutions;
