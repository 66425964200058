
import Navbar from "@/components/Navbar";

const MentionsLegales = () => {
  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      
      <main className="container mx-auto px-4 py-24 prose prose-slate max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">Mentions Légales</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Éditeur du site</h2>
          <p>
            Le site oneli.fr est édité par la société Oneli, SAS au capital de 10 000€,
            immatriculée au Registre du Commerce et des Sociétés (RCS) de Paris.
          </p>
          <ul className="list-none pl-0">
            <li>Siège social : 1 rue de la Paix, 75002 Paris, France</li>
            <li>Email : contact@oneli.fr</li>
            <li>Téléphone : +33 (0)6 33 88 33 88</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Directeur de la publication</h2>
          <p>
            Le directeur de la publication est M. Antoine Rigot, en sa qualité de Président 
            de la société Oneli.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Hébergement</h2>
          <p>
            Le site est hébergé par OVH SAS, société au capital de 10 174 560€,
            immatriculée au RCS de Lille Métropole sous le numéro 424 761 419 00045.
          </p>
          <p>Siège social : 2 rue Kellermann, 59100 Roubaix, France</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Propriété intellectuelle</h2>
          <p>
            L'ensemble du site, y compris sa structure et son contenu (textes, images, 
            graphismes, logos, vidéos, sons, logiciels, etc.), est protégé par le droit 
            d'auteur et le droit des marques. Toute représentation, reproduction, 
            modification ou exploitation totale ou partielle du site ou de son contenu, 
            par quelque procédé que ce soit, sans autorisation préalable, expresse et 
            écrite d'Oneli est strictement interdite et constituerait une contrefaçon 
            sanctionnée par le Code de la propriété intellectuelle.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Protection des données personnelles</h2>
          <p>
            Conformément au Règlement Général sur la Protection des Données (RGPD) et à 
            la Loi Informatique et Libertés, vous disposez d'un droit d'accès, de 
            rectification, de suppression et d'opposition aux données personnelles vous 
            concernant. Pour exercer ces droits, vous pouvez nous contacter à l'adresse 
            suivante : contact@oneli.fr
          </p>
          <p>
            Pour plus d'informations sur la gestion de vos données personnelles, 
            veuillez consulter notre Politique de Confidentialité.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Cookies</h2>
          <p>
            Le site utilise des cookies pour améliorer l'expérience utilisateur et 
            analyser le trafic. Pour plus d'informations sur l'utilisation des cookies, 
            veuillez consulter notre Politique de Confidentialité.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Droit applicable et juridiction compétente</h2>
          <p>
            Les présentes mentions légales sont régies par le droit français. En cas 
            de litige, les tribunaux français seront seuls compétents.
          </p>
          <p>
            Pour toute question relative à l'application des présentes mentions légales, 
            vous pouvez nous contacter à l'adresse suivante : contact@oneli.fr
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Modification des mentions légales</h2>
          <p>
            Oneli se réserve le droit de modifier les présentes mentions légales à 
            tout moment. Les utilisateurs du site sont donc invités à les consulter 
            de manière régulière.
          </p>
          <p>Dernière mise à jour : {new Date().toLocaleDateString('fr-FR')}</p>
        </section>
      </main>
    </div>
  );
};

export default MentionsLegales;
