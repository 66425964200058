
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import Navbar from "@/components/Navbar";
import { Zap, Shield, Wallet, Box } from "lucide-react";

const Index = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="pt-20 md:pt-24 px-4 bg-white">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row items-center gap-8">
            <div className="flex-1 text-center md:text-left">
              <h1 className="text-4xl md:text-6xl font-bold mb-8 bg-gradient-to-r from-primary to-primary-light bg-clip-text text-transparent">
                La box réinventée
              </h1>
              <p className="text-xl md:text-2xl text-gray-600 mb-8">
                Offrir un accès à internet sans fil, simple, rapide et abordable, pour tous, en quelques secondes
                <br />
                grâce à la 5G.
              </p>
            </div>
            <div className="flex-1">
              <img 
                src="/lovable-uploads/e2a32165-5dc0-4d50-ba76-567a54203d5b.png"
                alt="Oneli device next to laptop"
                className="w-full h-auto object-cover rounded-lg shadow-lg"
              />
            </div>
          </div>
          
          <div className="mt-12 mb-8">
            <div className="flex flex-col sm:flex-row justify-center gap-4 mb-8">
              <Link to="/rendezvous">
                <Button size="lg" variant="outline">
                  Prendre rendez-vous
                </Button>
              </Link>
              <Link to="/commander">
                <Button size="lg" className="bg-primary hover:bg-primary-dark text-white">
                  Commander
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Operators Trust Banner */}
      <section className="py-8 bg-gray-50">
        <div className="container mx-auto">
          <div className="text-center mb-6">
            <p className="text-sm text-gray-600">Compatible avec tous les opérateurs</p>
          </div>
          <div className="flex justify-center items-center gap-12 px-4">
            <div className="flex flex-col items-center">
              <img 
                src="/lovable-uploads/642322c4-4bd6-4ed5-aaba-76d9b3235812.png" 
                alt="Orange" 
                className="w-16 h-16 object-contain" 
              />
            </div>
            <div className="flex flex-col items-center">
              <img 
                src="/lovable-uploads/77f67ea1-b8df-4133-91dc-9e6fad06c9ac.png" 
                alt="Bouygues Telecom" 
                className="w-16 h-16 object-contain" 
              />
            </div>
            <div className="flex flex-col items-center">
              <img 
                src="/lovable-uploads/74465d5b-b19d-4c60-939d-b1a1b4a58263.png" 
                alt="SFR" 
                className="w-16 h-16 object-contain" 
              />
            </div>
            <div className="flex flex-col items-center">
              <img 
                src="/lovable-uploads/53d7af42-04f0-44d4-a170-554b363f7b2d.png" 
                alt="Free" 
                className="w-16 h-16 object-contain" 
              />
            </div>
          </div>
        </div>
      </section>

      {/* Product Image Section */}
      <section className="w-full bg-white">
        <div className="container mx-auto px-4">
          <img 
            src="/lovable-uploads/217786ec-751d-4b76-b3fa-13c8ffbfb2a2.png"
            alt="Oneli device with Marshall speaker"
            className="w-full h-auto object-cover rounded-lg shadow-lg my-8"
          />
        </div>
      </section>

      {/* Value Proposition Section */}
      <section className="py-16 px-4">
        <div className="container mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Une solution plug & play qui remplace la fibre</h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Pourquoi se compliquer la vie avec la fibre et ses frais cachés ? La 5G s'installe en un instant, sans travaux ni engagement, et coûte bien moins cher.
            </p>
          </div>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="p-6 text-center rounded-lg border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
              <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-4">
                <Box className="w-6 h-6 text-primary" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Simplicité</h3>
              <p className="text-gray-600">Installation et utilisation accessibles à tous, en quelques secondes</p>
            </div>
            
            <div className="p-6 text-center rounded-lg border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
              <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-4">
                <Zap className="w-6 h-6 text-primary" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Innovation</h3>
              <p className="text-gray-600">Technologies sans fil de pointe pour une connexion fiable et performante</p>
            </div>
            
            <div className="p-6 text-center rounded-lg border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
              <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-4">
                <Shield className="w-6 h-6 text-primary" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Honnêteté</h3>
              <p className="text-gray-600">Transparence totale sur nos offres et nos coûts</p>
            </div>
            
            <div className="p-6 text-center rounded-lg border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
              <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-4">
                <Wallet className="w-6 h-6 text-primary" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Justesse</h3>
              <p className="text-gray-600">Des solutions adaptées à vos besoins réels, ni plus, ni moins</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">
            Prêt à découvrir un internet plus simple ?
          </h2>
          <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
            Rejoignez Oneli et profitez d'une connexion internet sans fil adaptée à vos besoins, avec une installation instantanée et une transparence totale.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Link to="/commander">
              <Button size="lg" className="bg-primary hover:bg-primary-dark text-white">
                Commander
              </Button>
            </Link>
            <Link to="/rendezvous">
              <Button size="lg" variant="outline">
                Prendre rendez-vous
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
