
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Menu } from "lucide-react";
import { useState } from "react";

const ProNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="fixed w-full bg-white text-gray-900 backdrop-blur-md z-50 border-b">
      <div className="container mx-auto px-4 py-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-8">
            <Link to="/professionnel" className="flex items-center">
              <img 
                src="/lovable-uploads/66336247-5d75-4067-91cf-43e1c3bde31b.png" 
                alt="Oneli Pro" 
                className="h-12 w-auto object-contain" 
              />
              <span className="ml-2 font-bold text-gray-900 text-lg">PRO</span>
            </Link>
            
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/professionnel/solutions" className="text-gray-600 hover:text-gray-900 transition-colors">
                Solutions
              </Link>
              <Link to="/professionnel/tarifs" className="text-gray-600 hover:text-gray-900 transition-colors">
                Tarifs Pro
              </Link>
              <Link to="/professionnel/cas-clients" className="text-gray-600 hover:text-gray-900 transition-colors">
                Cas clients
              </Link>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <Link to="/" className="hidden md:block">
              <Button variant="outline" className="border-gray-900 text-gray-900 hover:bg-gray-900 hover:text-white">
                Particulier
              </Button>
            </Link>
            <Link to="/commander">
              <Button className="bg-primary hover:bg-primary-dark text-white">
                Commander
              </Button>
            </Link>
            <Button 
              variant="ghost" 
              size="icon" 
              className="md:hidden text-gray-900"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Menu className="h-6 w-6" />
            </Button>
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden py-4 space-y-4">
            <Link 
              to="/professionnel/solutions" 
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
            >
              Solutions
            </Link>
            <Link 
              to="/professionnel/tarifs" 
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
            >
              Tarifs Pro
            </Link>
            <Link 
              to="/professionnel/cas-clients" 
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
            >
              Cas clients
            </Link>
            <Link 
              to="/" 
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
            >
              Particulier
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default ProNavbar;
