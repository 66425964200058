// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://nswdmyaxfqdnfxoljcup.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5zd2RteWF4ZnFkbmZ4b2xqY3VwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg1ODI5MTMsImV4cCI6MjA1NDE1ODkxM30.WdY-13JISHDfvC58UzPp56FA_Sw1KLLVu_CnUsqLjzg";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);