import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { PersonalInfoForm } from "./PersonalInfoForm";
import { PasswordChangeModal } from "./PasswordChangeModal";
import { MarketingPreferences } from "./MarketingPreferences";

interface ProfileFormProps {
  userId: string;
  initialData: {
    firstName: string;
    lastName: string;
    address: string;
    marketingEmails: boolean;
    phoneNumber?: string;
    email: string;
  };
}

export const ProfileForm = ({ userId, initialData }: ProfileFormProps) => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(initialData.firstName);
  const [lastName, setLastName] = useState(initialData.lastName);
  const [address, setAddress] = useState(initialData.address);
  const [phoneNumber, setPhoneNumber] = useState(initialData.phoneNumber || "");
  const [email, setEmail] = useState(initialData.email);
  const [marketingEmails, setMarketingEmails] = useState(initialData.marketingEmails);

  const updateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);

      // Update email if changed
      if (email !== initialData.email) {
        const { error: emailError } = await supabase.auth.updateUser({
          email: email,
        });

        if (emailError) throw emailError;
      }

      const updates = {
        first_name: firstName,
        last_name: lastName,
        address: address,
        phone_number: phoneNumber,
        marketing_emails: marketingEmails,
        updated_at: new Date().toISOString(),
      };

      const { error } = await supabase
        .from("profiles")
        .update(updates)
        .eq("id", userId);

      if (error) throw error;

      toast({
        title: "Profil mis à jour",
        description: "Vos informations ont été mises à jour avec succès.",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la mise à jour du profil.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={updateProfile} className="space-y-6">
      <PersonalInfoForm
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        address={address}
        email={email}
        onFirstNameChange={setFirstName}
        onLastNameChange={setLastName}
        onPhoneNumberChange={setPhoneNumber}
        onAddressChange={setAddress}
        onEmailChange={setEmail}
      />

      <div className="flex justify-start">
        <PasswordChangeModal />
      </div>

      <MarketingPreferences
        marketingEmails={marketingEmails}
        onMarketingEmailsChange={setMarketingEmails}
      />

      <Button type="submit" disabled={loading} className="w-full md:w-auto">
        {loading ? "Mise à jour..." : "Mettre à jour le profil"}
      </Button>
    </form>
  );
};