
import { Button } from "@/components/ui/button";
import { Check, X } from "lucide-react";
import { Link } from "react-router-dom";

type EligibilityResultProps = {
  isEligible: boolean;
  data: {
    householdSize: number;
    isIsolated: boolean;
    videoHoursPerDay: number;
    usageType: "private" | "professional" | null;
    housingType: string | null;
    businessType: string | null;
  };
  onRestart: () => void;
  onScheduleMeeting: () => void;
};

export const EligibilityResult = ({
  isEligible,
  data,
  onRestart,
  onScheduleMeeting,
}: EligibilityResultProps) => {
  const getIneligibilityReason = () => {
    if (data.isIsolated) {
      return "Votre localisation est trop isolée pour garantir un service optimal.";
    }

    const monthlyVideoHours = data.householdSize * data.videoHoursPerDay * 30;
    if (data.householdSize > 3) {
      return "Le nombre de personnes dans votre foyer est trop élevé pour garantir une connexion optimale.";
    }
    if (monthlyVideoHours > 100) {
      return "Votre consommation vidéo est trop élevée pour garantir une expérience optimale.";
    }

    if (data.usageType === "private" && data.housingType === "secondary_residence") {
      return "Notre service n'est pas adapté aux résidences secondaires.";
    }

    if (data.usageType === "professional" && data.businessType === "enterprise") {
      return "Notre service n'est pas adapté aux grandes entreprises.";
    }

    return "Votre situation ne correspond pas aux critères d'éligibilité.";
  };

  return (
    <div className="text-center space-y-6">
      {isEligible ? (
        <>
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto">
            <Check className="w-8 h-8 text-green-600" />
          </div>
          <h2 className="text-2xl font-bold text-green-600">
            Félicitations ! Vous êtes éligible
          </h2>
          <p className="text-gray-600">
            Notre box internet est parfaitement adaptée à vos besoins.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Link to="/commander">
              <Button className="w-full sm:w-auto">
                Essayer Gratuitement
              </Button>
            </Link>
            <Button variant="outline" onClick={onRestart} className="w-full sm:w-auto">
              Recommencer
            </Button>
            <a 
              href="https://calendar.app.google/XZtP7DkLFx22WTJY7"
              target="_blank" 
              rel="noopener noreferrer"
              className="w-full sm:w-auto"
            >
              <Button variant="secondary" className="w-full">
                Prendre rendez-vous
              </Button>
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto">
            <X className="w-8 h-8 text-red-600" />
          </div>
          <h2 className="text-2xl font-bold text-red-600">
            Désolé, vous n'êtes pas éligible
          </h2>
          <p className="text-gray-600">
            {getIneligibilityReason()}
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <a 
              href="https://calendar.app.google/XZtP7DkLFx22WTJY7"
              target="_blank" 
              rel="noopener noreferrer"
              className="w-full sm:w-auto"
            >
              <Button className="w-full">
                Prendre rendez-vous pour en discuter
              </Button>
            </a>
            <Button variant="outline" onClick={onRestart} className="w-full sm:w-auto">
              Recommencer
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
