
import { RefreshCw, ShieldCheck, Loader2, Link } from "lucide-react";
import { Button } from "@/components/ui/button";
import { SyncSettings } from "./SyncSettings";
import { EmailSyncDialog } from "./EmailSyncDialog";
import { LinkCustomerIdsDialog } from "./LinkCustomerIdsDialog";
import { SyncOptions } from "../types/subscription-sync.types";

interface SyncActionButtonsProps {
  syncLoading: boolean;
  verifyLoading: boolean;
  isSyncing: boolean;
  options: SyncOptions;
  setOptions: (options: Partial<SyncOptions>) => void;
  onSync: () => void;
  onVerify: () => void;
}

export const SyncActionButtons = ({ 
  syncLoading, 
  verifyLoading, 
  isSyncing, 
  options, 
  setOptions, 
  onSync, 
  onVerify 
}: SyncActionButtonsProps) => {
  const isLoading = syncLoading || verifyLoading || isSyncing;
  
  return (
    <div className="flex flex-wrap gap-2">
      <EmailSyncDialog onSyncComplete={onSync} />
      
      <LinkCustomerIdsDialog onSyncComplete={onSync} />
      
      <SyncSettings options={options} setOptions={setOptions} />
      
      <Button 
        variant="outline" 
        onClick={onSync} 
        disabled={isLoading}
      >
        {(syncLoading || isSyncing) ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Synchronisation...
          </>
        ) : (
          <>
            <RefreshCw className="mr-2 h-4 w-4" />
            Synchroniser avec Stripe
          </>
        )}
      </Button>
      
      <Button 
        variant="outline" 
        onClick={onVerify} 
        disabled={isLoading}
      >
        {verifyLoading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Vérification...
          </>
        ) : (
          <>
            <ShieldCheck className="mr-2 h-4 w-4" />
            Vérifier l'intégrité
          </>
        )}
      </Button>
    </div>
  );
};
