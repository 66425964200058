import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Menu, LogIn } from "lucide-react";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useSubscriptionData } from "@/components/profile/subscription/useSubscriptionData";

const Navbar = () => {
  const { user } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { data: subscription } = useSubscriptionData();

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (user) {
        const { data } = await supabase
          .from("profiles")
          .select("is_admin")
          .eq("id", user.id)
          .single();
        
        setIsAdmin(!!data?.is_admin);
      }
    };

    checkAdminStatus();
  }, [user]);

  const hasActiveSubscription = subscription?.subscribed;
  const buttonText = user && hasActiveSubscription ? "Ma Box" : "Commander";
  const buttonPath = user && hasActiveSubscription ? "/abonnement" : "/commander";

  return (
    <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50 border-b">
      <div className="container mx-auto px-4 py-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-8">
            <Link to="/" className="flex items-center">
              <img 
                src="/lovable-uploads/66336247-5d75-4067-91cf-43e1c3bde31b.png" 
                alt="Oneli" 
                className="h-12 w-auto object-contain" 
              />
            </Link>
            
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/utilisations" className="text-gray-600 hover:text-primary transition-colors">
                La Box
              </Link>
              <Link to="/comment-ca-marche" className="text-gray-600 hover:text-primary transition-colors">
                Comment ça marche
              </Link>
              <Link to="/tarifs" className="text-gray-600 hover:text-primary transition-colors">
                Tarifs
              </Link>
              <Link to="/a-propos" className="text-gray-600 hover:text-primary transition-colors">
                À propos
              </Link>
              {isAdmin && (
                <Link to="/admin" className="text-gray-600 hover:text-primary transition-colors">
                  Admin
                </Link>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            {user ? (
              <Link 
                to="/profile" 
                className="hidden md:block text-sm text-gray-600 hover:text-primary transition-colors"
              >
                {user.user_metadata.first_name || 'Mon profil'}
              </Link>
            ) : (
              <Link to="/login" className="hidden md:block">
                <Button variant="ghost">Connexion</Button>
              </Link>
            )}
            <Link to={buttonPath}>
              <Button className="bg-primary hover:bg-primary-dark text-white">
                {buttonText}
              </Button>
            </Link>
            <Button 
              variant="ghost" 
              size="icon" 
              className="md:hidden"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Menu className="h-6 w-6" />
            </Button>
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden py-4 space-y-4">
            <Link 
              to="/utilisations" 
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
            >
              La Box
            </Link>
            <Link 
              to="/comment-ca-marche" 
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
            >
              Comment ça marche
            </Link>
            <Link 
              to="/tarifs" 
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
            >
              Tarifs
            </Link>
            <Link 
              to="/a-propos" 
              className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
            >
              À propos
            </Link>
            {isAdmin && (
              <Link 
                to="/admin" 
                className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
              >
                Admin
              </Link>
            )}
            {user ? (
              <Link 
                to="/profile" 
                className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
              >
                {user.user_metadata.first_name || 'Mon profil'}
              </Link>
            ) : (
              <Link 
                to="/login" 
                className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100"
              >
                <LogIn className="h-4 w-4 mr-2" />
                Connexion
              </Link>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
