import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { Check, Wifi, Tv, ShoppingCart, CreditCard, X, PlusCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import Navbar from "@/components/Navbar";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { useState } from "react";
import NewsletterSubscribeModal from "@/components/NewsletterSubscribeModal";
import { DataPlanModal } from "@/components/profile/subscription/DataPlanModal";

const Tarifs = () => {
  const [newsletterModalOpen, setNewsletterModalOpen] = useState(false);
  const [dataPlanModalOpen, setDataPlanModalOpen] = useState(false);
  
  const plans = [
    {
      name: "Achat du matériel",
      price: "179,90€",
      priceDetail: "",
      engagement: "",
      badge: "",
      icon: <ShoppingCart className="h-10 w-10 text-primary" />,
      description: "Achat du matériel sans abonnement",
      highlight: "Abonnement 5G non inclus",
      features: [
        "WiFi 6 nouvelle génération",
        "Achat définitif de la box",
        "Compatible tout opérateurs",
        {
          text: "Voir les forfaits",
          button: <Button 
            variant="ghost" 
            size="icon" 
            className="h-5 w-5 ml-1 -mr-6" 
            onClick={(e) => {
              e.stopPropagation();
              window.open("https://bonforfait.fr/forfait-mobile?5g&internet=100", "_blank");
            }}
          >
            <PlusCircle className="h-4 w-4 text-primary" />
          </Button>
        },
        "Support client standard",
        "Installation plug & play",
      ],
      negativeFeatures: [
        "Forfait à souscrire soi-même",
        "Pas de chaînes TV"
      ],
      cta: "Acheter le matériel",
      popular: false,
      link: "/commander"
    },
    {
      name: "Forfait mensuel",
      price: "29,90€",
      priceDetail: "/mois",
      engagement: "sans engagement",
      badge: "Plus populaire",
      icon: <CreditCard className="h-10 w-10 text-primary" />,
      description: "Accès internet sans fil avec data incluse",
      highlight: "Frais de démarrage 29€",
      features: [
        "WiFi 6 nouvelle génération",
        {
          text: "100 Go de data inclus par mois",
          button: <Button 
            variant="ghost" 
            size="icon" 
            className="h-5 w-5 ml-1 -mr-6" 
            onClick={(e) => {
              e.stopPropagation();
              setDataPlanModalOpen(true);
            }}
          >
            <PlusCircle className="h-4 w-4 text-primary" />
          </Button>
        },
        "Support client prioritaire",
        "Appareil en location incluse",
        "Installation plug & play",
        "Sans engagement",
      ],
      negativeFeatures: ["Pas de chaînes TV"],
      cta: "Commander",
      popular: true,
      link: "/commander"
    },
    {
      name: "Bientôt - Offre TV",
      price: "Bientôt",
      priceDetail: "",
      engagement: "",
      badge: "Bientôt",
      icon: <Tv className="h-10 w-10 text-gray-400" />,
      description: "Notre offre TV arrive bientôt",
      highlight: "Restez informés",
      features: [
        "WiFi 6 nouvelle génération",
        "Chaînes TV incluses",
        "Contenu à la demande",
        "Support client prioritaire",
        "Installation facile",
        "Sans engagement",
      ],
      negativeFeatures: [],
      cta: "Rester informé",
      popular: false,
      isNewsletter: true,
      disabled: true
    }
  ];

  const handleNewsletterClick = () => {
    setNewsletterModalOpen(true);
  };

  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      
      <main className="container mx-auto px-4 pt-24 pb-16">
        <div className="text-center mb-16">
          <div className="bg-primary text-white font-semibold py-3 px-6 rounded-full inline-block mb-8">
            <Wifi className="inline-block mr-2 h-5 w-5" /> Internet partout, simple et immédiat
          </div>
          <h1 className="text-4xl font-bold mb-4">
            Choisissez l'offre qui vous convient
          </h1>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto mb-8">
            Des solutions adaptées à vos besoins sans contrainte, avec ou sans engagement.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {plans.map((plan, index) => (
            <Card 
              key={index} 
              className={`relative overflow-hidden transition-all duration-300 ${
                plan.popular 
                  ? 'border-primary shadow-lg scale-105 md:scale-110 z-10' 
                  : 'border-gray-200 hover:border-gray-300 hover:shadow-md'
              } ${plan.name.includes('Bientôt') ? 'opacity-80' : ''}`}
            >
              {plan.badge && (
                <Badge className={`absolute top-4 right-4 ${plan.popular ? 'bg-primary' : 'bg-gray-500'}`}>
                  {plan.badge}
                </Badge>
              )}
              <CardHeader className="text-center pb-0">
                <div className="mx-auto mb-4">{plan.icon}</div>
                <CardTitle className="text-2xl">{plan.name}</CardTitle>
                <CardDescription className="text-base mt-2">
                  {plan.description}
                </CardDescription>
              </CardHeader>
              <CardContent className="pt-6">
                <div className="text-center mb-6">
                  <div className="flex flex-col items-center">
                    <div className="text-4xl font-bold">
                      {plan.price}
                      <span className="text-lg text-muted-foreground">{plan.priceDetail}</span>
                    </div>
                    {plan.engagement && (
                      <div className="text-sm text-muted-foreground">{plan.engagement}</div>
                    )}
                  </div>
                  <p className="text-sm font-medium text-primary mt-2">
                    {plan.highlight}
                  </p>
                </div>
                <Separator className="my-6" />
                <ul className="space-y-3 mb-6">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start gap-2">
                      <Check className={`h-5 w-5 flex-shrink-0 mt-0.5 ${
                        plan.name.includes('Bientôt') ? 'text-gray-400' : 'text-green-500'
                      }`} />
                      {typeof feature === 'string' ? (
                        <span className={plan.name.includes('Bientôt') ? 'text-gray-500' : ''}>
                          {feature}
                        </span>
                      ) : (
                        <div className="flex items-center">
                          <span className={plan.name.includes('Bientôt') ? 'text-gray-500' : ''}>
                            {feature.text}
                          </span>
                          {feature.button}
                        </div>
                      )}
                    </li>
                  ))}
                  {plan.negativeFeatures && plan.negativeFeatures.map((feature, featureIndex) => (
                    <li key={`neg-${featureIndex}`} className="flex items-start gap-2">
                      <X className="h-5 w-5 flex-shrink-0 mt-0.5 text-gray-400" />
                      <span className="text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter className="pt-0">
                {plan.isNewsletter ? (
                  <Button 
                    className="w-full"
                    variant="outline"
                    onClick={handleNewsletterClick}
                  >
                    {plan.cta}
                  </Button>
                ) : (
                  <Link to={plan.link} className="w-full">
                    <Button 
                      className={`w-full ${plan.popular ? 'bg-primary' : ''}`}
                      variant={plan.popular ? "default" : "outline"}
                      disabled={plan.disabled}
                    >
                      {plan.cta}
                    </Button>
                  </Link>
                )}
              </CardFooter>
            </Card>
          ))}
        </div>

        <div className="max-w-4xl mx-auto mt-24">
          <div className="relative rounded-xl overflow-hidden">
            <img 
              src="/lovable-uploads/8121f16e-5885-4007-a489-6897c95e3313.png" 
              alt="Enceinte Marshall et Google Home" 
              className="w-full h-auto object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent"></div>
            <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
              <h3 className="text-2xl font-bold mb-2">Connectez tous vos appareils</h3>
              <p className="text-lg">Profitez d'une connexion stable et rapide pour tous vos objets connectés</p>
            </div>
          </div>
        </div>

        <div className="text-center space-y-8 mt-16">
          <div className="max-w-2xl mx-auto">
            <h2 className="text-3xl font-bold mb-4">
              Des questions ?
            </h2>
            <p className="text-lg text-muted-foreground mb-8">
              Notre équipe est là pour vous accompagner et répondre à toutes vos questions.
            </p>
          </div>
          <a href="https://calendar.app.google/XZtP7DkLFx22WTJY7" target="_blank" rel="noopener noreferrer">
            <Button size="lg" variant="outline">
              Prendre rendez-vous
            </Button>
          </a>
        </div>
      </main>

      <NewsletterSubscribeModal 
        open={newsletterModalOpen} 
        onOpenChange={setNewsletterModalOpen} 
      />
      
      <DataPlanModal 
        open={dataPlanModalOpen} 
        onOpenChange={setDataPlanModalOpen} 
      />
    </div>
  );
};

export default Tarifs;
