
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import Navbar from "@/components/Navbar";
import { Progress } from "@/components/ui/progress";
import { HouseholdStep } from "@/components/eligibility/HouseholdStep";
import { VideoUsageStep } from "@/components/eligibility/VideoUsageStep";
import { UsageTypeStep } from "@/components/eligibility/UsageTypeStep";
import { EligibilityResult } from "@/components/eligibility/EligibilityResult";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

type EligibilityData = {
  householdSize: number;
  isIsolated: boolean;
  videoHoursPerDay: number;
  usageType: "private" | "professional" | null;
  housingType: string | null;
  businessType: string | null;
};

export const Eligibilite = () => {
  const navigate = useNavigate();
  const { session } = useAuth();
  const { toast } = useToast();
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [eligibilityData, setEligibilityData] = useState<EligibilityData>({
    householdSize: 1,
    isIsolated: false,
    videoHoursPerDay: 0,
    usageType: null,
    housingType: null,
    businessType: null,
  });

  const totalSteps = 3;
  const progress = (currentStep / totalSteps) * 100;

  const handleNext = () => {
    setCurrentStep((prev) => Math.min(prev + 1, totalSteps + 1));
  };

  const handleBack = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const updateEligibilityData = (data: Partial<EligibilityData>) => {
    setEligibilityData((prev) => ({ ...prev, ...data }));
  };

  const checkEligibility = async () => {
    if (!session) {
      toast({
        title: "Connexion requise",
        description: "Veuillez vous connecter pour vérifier votre éligibilité",
        variant: "destructive",
      });
      navigate("/auth");
      return;
    }

    setIsLoading(true);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error("No session");

      const response = await fetch(
        "https://nswdmyaxfqdnfxoljcup.functions.supabase.co/check-eligibility",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({
            address: "123 Test Street", // This should come from a form or geolocation
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to check eligibility");
      }

      const data = await response.json();
      console.log("Sogudo API response:", data);
      
      // Update eligibilityData with the API response if needed
      // setEligibilityData((prev) => ({ ...prev, ...newData }));
      
    } catch (error) {
      console.error("Error checking eligibility:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la vérification de l'éligibilité",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isEligible = () => {
    if (eligibilityData.isIsolated) return false;

    const monthlyVideoHours = eligibilityData.householdSize * eligibilityData.videoHoursPerDay * 30;
    if (eligibilityData.householdSize > 3 || monthlyVideoHours > 100) return false;

    if (eligibilityData.usageType === "private") {
      return ["student_housing", "city_center"].includes(eligibilityData.housingType || "");
    } else if (eligibilityData.usageType === "professional") {
      return ["city_store", "small_office"].includes(eligibilityData.businessType || "");
    }

    return false;
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <HouseholdStep
            data={eligibilityData}
            onUpdate={updateEligibilityData}
            onNext={handleNext}
          />
        );
      case 2:
        return (
          <VideoUsageStep
            data={eligibilityData}
            onUpdate={updateEligibilityData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 3:
        return (
          <UsageTypeStep
            data={eligibilityData}
            onUpdate={updateEligibilityData}
            onBack={handleBack}
            onNext={() => {
              checkEligibility();  // Call Sogudo API before showing results
              handleNext();
            }}
          />
        );
      case 4:
        return (
          <EligibilityResult 
            isEligible={isEligible()} 
            data={eligibilityData}
            onRestart={() => {
              setCurrentStep(1);
              setEligibilityData({
                householdSize: 1,
                isIsolated: false,
                videoHoursPerDay: 0,
                usageType: null,
                housingType: null,
                businessType: null,
              });
            }}
            onScheduleMeeting={() => navigate("/rendezvous")}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-24">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-3xl font-bold text-center mb-8">
            Vérifiez votre éligibilité
          </h1>

          {currentStep <= totalSteps && (
            <div className="mb-8">
              <Progress value={progress} className="h-2" />
              <div className="flex justify-between mt-2 text-sm text-gray-500">
                <span>Étape {currentStep}</span>
                <span>{currentStep} sur {totalSteps}</span>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="text-center">
              <p>Vérification de l'éligibilité en cours...</p>
            </div>
          ) : (
            renderStep()
          )}
        </div>
      </div>
    </>
  );
};

export default Eligibilite;
