
import { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Loader2 } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface EditSubscriptionStatusProps {
  userId: string;
  isActive: boolean;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: () => void;
}

export function EditSubscriptionStatus({ 
  userId, 
  isActive, 
  open, 
  onOpenChange, 
  onSuccess 
}: EditSubscriptionStatusProps) {
  const [currentStatus, setCurrentStatus] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("active"); // Default value
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // Récupérer le statut actuel au chargement
  useEffect(() => {
    const fetchCurrentStatus = async () => {
      setIsLoading(true);
      try {
        const { data: existingSubscription } = await supabase
          .from("subscriptions")
          .select("status, stripe_subscription_id")
          .eq("user_id", userId)
          .maybeSingle();
        
        if (existingSubscription) {
          // Si nous avons un subscription_id mais pas de statut, considérer comme 'active'
          const effectiveStatus = (!existingSubscription.status && existingSubscription.stripe_subscription_id) 
            ? 'active' 
            : existingSubscription.status || 'no_subscription';
          
          setCurrentStatus(effectiveStatus);
          setStatus(effectiveStatus);
        } else {
          setCurrentStatus('no_subscription');
          setStatus('active'); // Valeur par défaut pour un nouvel abonnement
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du statut:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      fetchCurrentStatus();
    }
  }, [userId, open]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      
      // Check for existing subscription
      const { data: existingSubscription } = await supabase
        .from("subscriptions")
        .select("*")
        .eq("user_id", userId)
        .maybeSingle();
      
      if (existingSubscription) {
        // Update existing subscription
        await supabase
          .from("subscriptions")
          .update({ 
            status: status,
            updated_at: new Date().toISOString()
          })
          .eq("id", existingSubscription.id);
      } else {
        // Create new subscription
        await supabase
          .from("subscriptions")
          .insert({
            user_id: userId,
            stripe_customer_id: `admin_created_${userId}`,
            status: status
          });
      }

      // Refresh data
      queryClient.invalidateQueries({ queryKey: ["users"] });
      
      toast({
        title: "Statut mis à jour",
        description: `L'abonnement a été mis à jour (${status})`,
      });
      
      if (onSuccess) onSuccess();
      onOpenChange(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut:", error);
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour le statut de l'abonnement",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const statusOptions = [
    { value: "active", label: "Actif" },
    { value: "trialing", label: "Période d'essai" },
    { value: "past_due", label: "Paiement en retard" },
    { value: "canceled", label: "Annulé" },
    { value: "incomplete", label: "Incomplet" },
    { value: "incomplete_expired", label: "Expiré" },
    { value: "unpaid", label: "Impayé" },
    { value: "pending", label: "En attente" },
    { value: "no_subscription", label: "Pas d'abonnement" }
  ];

  const getStatusLabel = (value: string) => {
    const option = statusOptions.find(opt => opt.value === value);
    return option ? option.label : value;
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Modifier le statut de l'abonnement</DialogTitle>
        </DialogHeader>
        {isLoading ? (
          <div className="flex justify-center py-4">
            <Loader2 className="h-5 w-5 animate-spin" />
          </div>
        ) : (
          <>
            <div className="grid gap-4 py-4">
              {currentStatus && (
                <div className="text-sm text-muted-foreground mb-2">
                  Statut actuel: <span className="font-medium">{getStatusLabel(currentStatus)}</span>
                </div>
              )}
              <div className="grid gap-2">
                <Label htmlFor="subscription-status">
                  Nouveau statut d'abonnement
                </Label>
                <Select
                  value={status}
                  onValueChange={setStatus}
                >
                  <SelectTrigger id="subscription-status">
                    <SelectValue placeholder="Sélectionner un statut" />
                  </SelectTrigger>
                  <SelectContent>
                    {statusOptions.map(option => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <DialogFooter>
              <Button 
                type="button" 
                variant="outline" 
                onClick={() => onOpenChange(false)}
                disabled={isSubmitting}
              >
                Annuler
              </Button>
              <Button 
                type="button" 
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                ) : null}
                Enregistrer
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
