
import { useState } from "react";
import { Link, Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { useSyncSubscriptions } from "@/components/profile/subscription/useSyncSubscriptions";

interface LinkCustomerIdsDialogProps {
  onSyncComplete: () => void;
}

export const LinkCustomerIdsDialog = ({ onSyncComplete }: LinkCustomerIdsDialogProps) => {
  const [emailToLink, setEmailToLink] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [linkLoading, setLinkLoading] = useState<boolean>(false);
  const [linkError, setLinkError] = useState<string>("");
  const { toast } = useToast();
  const { syncSubscriptions } = useSyncSubscriptions();
  
  const handleLinkCustomerIds = async () => {
    if (!emailToLink || emailToLink.trim() === "") {
      toast({
        title: "Erreur",
        description: "Veuillez entrer une adresse email valide",
        variant: "destructive",
      });
      return;
    }
    
    try {
      setLinkLoading(true);
      setLinkError("");
      
      console.log("Tentative de liaison des comptes clients pour:", emailToLink.trim());
      
      // Using the syncSubscriptions hook with specific options for linking customer IDs
      const data = await syncSubscriptions({
        showToast: false,
        specificUserEmail: emailToLink.trim(),
        // Ensure we're updating customer IDs and linking them
        updateStripeCustomerId: true,
        storeAllCustomerIds: true,
        updateAllCustomerIds: true,
        linkAllCustomerIds: true,
        // Add options to handle case differences and spaces
        normalizeEmails: true,
        // Force profile creation if needed
        createMissingProfiles: true,
        // Add debug flag
        debug: true
      });
      
      console.log("Customer IDs linking result:", data);
      
      // Only show a successful toast if we actually linked some IDs
      if (data && data.linked_customer_ids && data.linked_customer_ids > 0) {
        toast({
          title: "Liaison terminée",
          description: `${data.linked_customer_ids} comptes clients Stripe liés pour ${emailToLink}`,
        });
      } else if (data && data.error) {
        setLinkError(data.error);
        toast({
          title: "Erreur",
          description: data.error,
          variant: "destructive",
        });
        return;
      } else {
        toast({
          title: "Information",
          description: `Aucun nouveau compte client Stripe trouvé pour ${emailToLink}`,
        });
      }
      
      setDialogOpen(false);
      setEmailToLink("");
      
      // Refresh our sync results
      onSyncComplete();
      
    } catch (error) {
      console.error("Erreur lors de la liaison des comptes clients:", error);
      setLinkError(error.message || "Erreur inconnue");
      toast({
        title: "Erreur",
        description: `Impossible de lier les comptes clients pour ${emailToLink}: ${error.message || "Erreur inconnue"}`,
        variant: "destructive",
      });
    } finally {
      setLinkLoading(false);
    }
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={(open) => {
      setDialogOpen(open);
      if (!open) {
        setLinkError("");
      }
    }}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Link className="mr-2 h-4 w-4" />
          Lier comptes clients Stripe
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Lier les comptes clients Stripe</DialogTitle>
          <DialogDescription>
            Recherchez et liez tous les comptes clients Stripe associés à cet email dans Stripe.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="email" className="text-right">
              Email
            </Label>
            <Input
              id="email"
              type="email"
              placeholder="utilisateur@exemple.com"
              className="col-span-3"
              value={emailToLink}
              onChange={(e) => setEmailToLink(e.target.value)}
            />
          </div>
          
          {linkError && (
            <div className="col-span-4 p-3 bg-red-50 text-red-800 border border-red-200 rounded-md">
              <strong>Erreur :</strong> {linkError}
            </div>
          )}
        </div>
        <DialogFooter>
          <Button
            onClick={handleLinkCustomerIds}
            disabled={linkLoading || !emailToLink}
          >
            {linkLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Liaison en cours...
              </>
            ) : (
              "Lier les comptes clients"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
