import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { FileIcon, UploadIcon } from "lucide-react";

interface DocumentData {
  identity_doc_path?: string | null;
  bank_details_path?: string | null;
}

interface DocumentsUploadProps {
  userId: string;
}

export const DocumentsUpload = ({ userId }: DocumentsUploadProps) => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<DocumentData>({});

  const acceptedFileTypes = ".jpg,.jpeg,.png,.pdf";

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    const { data, error } = await supabase
      .from("user_documents")
      .select("identity_doc_path, bank_details_path")
      .eq("user_id", userId)
      .single();

    if (!error && data) {
      console.log("[DocumentsUpload] Documents fetched:", data);
      setDocuments(data);
    }
  };

  const uploadFile = async (file: File, type: 'identity' | 'bank') => {
    try {
      setLoading(true);

      if (!file.type.match(/(image\/(jpeg|png)|application\/pdf)/)) {
        throw new Error("Format de fichier non supporté. Utilisez JPG, JPEG, PNG ou PDF.");
      }

      // Determine the appropriate bucket and filename
      const bucket = type === 'identity' ? 'identity_documents' : 'bank_details';
      const fileExt = file.name.split('.').pop();
      
      // Keep consistent naming pattern with userId prefix
      const fileName = `${userId}_${type}.${fileExt}`;

      // Enhanced debugging
      console.log(`[DocumentsUpload] Uploading to bucket: ${bucket}, file: ${fileName}`);

      // Upload file with upsert to replace any existing file
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from(bucket)
        .upload(fileName, file, { upsert: true });

      if (uploadError) {
        console.error("[DocumentsUpload] Upload error:", uploadError);
        throw uploadError;
      }

      // Get the file path for database storage
      const filePath = fileName;
      console.log("[DocumentsUpload] File uploaded successfully, path:", filePath);

      // Update database with the file path
      const documentField = type === 'identity' ? 'identity_doc_path' : 'bank_details_path';
      
      // Check if user document record exists
      const { data: existingDoc } = await supabase
        .from('user_documents')
        .select()
        .eq('user_id', userId)
        .single();

      // Update or create user document record
      if (existingDoc) {
        await supabase
          .from('user_documents')
          .update({ [documentField]: filePath })
          .eq('user_id', userId);
      } else {
        await supabase
          .from('user_documents')
          .insert({ user_id: userId, [documentField]: filePath });
      }

      toast({
        title: "Document téléchargé",
        description: "Votre document a été enregistré avec succès.",
      });

      await fetchDocuments();
    } catch (error) {
      console.error("[DocumentsUpload] Erreur lors du téléchargement:", error);
      toast({
        title: "Erreur",
        description: error instanceof Error ? error.message : "Une erreur est survenue",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold">Documents</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Pièce d'identité */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <h4 className="font-medium">Pièce d'identité</h4>
              <p className="text-sm text-gray-500">Format accepté : JPG, JPEG, PNG, PDF</p>
            </div>
            {documents.identity_doc_path && (
              <FileIcon className="text-green-500" />
            )}
          </div>
          <div>
            <input
              type="file"
              accept={acceptedFileTypes}
              onChange={(e) => e.target.files?.[0] && uploadFile(e.target.files[0], 'identity')}
              className="hidden"
              id="identity-upload"
              disabled={loading}
            />
            <label htmlFor="identity-upload">
              <Button 
                variant="outline" 
                disabled={loading}
                className="w-full"
                asChild
              >
                <span>
                  <UploadIcon className="mr-2 h-4 w-4" />
                  {documents.identity_doc_path ? "Mettre à jour" : "Télécharger"}
                </span>
              </Button>
            </label>
          </div>
        </div>

        {/* RIB */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <h4 className="font-medium">RIB</h4>
              <p className="text-sm text-gray-500">Format accepté : JPG, JPEG, PNG, PDF</p>
            </div>
            {documents.bank_details_path && (
              <FileIcon className="text-green-500" />
            )}
          </div>
          <div>
            <input
              type="file"
              accept={acceptedFileTypes}
              onChange={(e) => e.target.files?.[0] && uploadFile(e.target.files[0], 'bank')}
              className="hidden"
              id="bank-upload"
              disabled={loading}
            />
            <label htmlFor="bank-upload">
              <Button 
                variant="outline" 
                disabled={loading}
                className="w-full"
                asChild
              >
                <span>
                  <UploadIcon className="mr-2 h-4 w-4" />
                  {documents.bank_details_path ? "Mettre à jour" : "Télécharger"}
                </span>
              </Button>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
