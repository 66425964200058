
import { useState } from "react";
import { Mail, Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { useSyncSubscriptions } from "@/components/profile/subscription/useSyncSubscriptions";

interface EmailSyncDialogProps {
  onSyncComplete: () => void;
}

export const EmailSyncDialog = ({ onSyncComplete }: EmailSyncDialogProps) => {
  const [emailToSync, setEmailToSync] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const { toast } = useToast();
  const { syncSubscriptions } = useSyncSubscriptions();
  
  const handleSync = async () => {
    if (!emailToSync || emailToSync.trim() === "") {
      toast({
        title: "Erreur",
        description: "Veuillez entrer une adresse email valide",
        variant: "destructive",
      });
      return;
    }
    
    try {
      setSyncLoading(true);
      
      const data = await syncSubscriptions({
        showToast: false,
        specificUserEmail: emailToSync.trim(),
        updateStripeCustomerId: true,
        storeAllCustomerIds: true,
      });
      
      console.log("Email sync result:", data);
      
      toast({
        title: "Synchronisation terminée",
        description: `Les informations de l'utilisateur ${emailToSync} ont été mises à jour`,
      });
      
      setDialogOpen(false);
      setEmailToSync("");
      
      // Refresh our sync results
      onSyncComplete();
      
    } catch (error) {
      console.error("Erreur lors de la synchronisation par email:", error);
      toast({
        title: "Erreur",
        description: `Impossible de synchroniser l'utilisateur ${emailToSync}: ${error.message}`,
        variant: "destructive",
      });
    } finally {
      setSyncLoading(false);
    }
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Mail className="mr-2 h-4 w-4" />
          Vérifier un email
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Vérifier un compte utilisateur</DialogTitle>
          <DialogDescription>
            Recherchez et mettez à jour les informations d'abonnement d'un utilisateur spécifique.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="email" className="text-right">
              Email
            </Label>
            <Input
              id="email"
              type="email"
              placeholder="utilisateur@exemple.com"
              className="col-span-3"
              value={emailToSync}
              onChange={(e) => setEmailToSync(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <Button
            onClick={handleSync}
            disabled={syncLoading || !emailToSync}
          >
            {syncLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Vérification...
              </>
            ) : (
              "Vérifier et mettre à jour"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
