import Navbar from "@/components/Navbar";

const Confidentialite = () => {
  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      
      <main className="container mx-auto px-4 py-24 prose prose-slate max-w-4xl">
        <h1>Politique de Confidentialité</h1>

        <h2>1. Collecte des données personnelles</h2>
        <p>
          Dans le cadre de son activité, Oneli collecte les données personnelles 
          suivantes :
        </p>
        <ul>
          <li>Nom et prénom</li>
          <li>Adresse email</li>
          <li>Adresse postale</li>
          <li>Informations de paiement</li>
          <li>Données de connexion et d'utilisation du service</li>
        </ul>

        <h2>2. Utilisation des données</h2>
        <p>
          Les données collectées sont utilisées pour :
        </p>
        <ul>
          <li>Fournir et gérer nos services</li>
          <li>Facturer les services</li>
          <li>Communiquer avec vous concernant votre compte</li>
          <li>Améliorer nos services</li>
          <li>Respecter nos obligations légales</li>
        </ul>

        <h2>3. Protection des données</h2>
        <p>
          Oneli met en œuvre des mesures techniques et organisationnelles appropriées 
          pour protéger vos données personnelles contre toute perte, accès non autorisé, 
          divulgation ou destruction.
        </p>

        <h2>4. Durée de conservation</h2>
        <p>
          Les données personnelles sont conservées pendant la durée nécessaire à la 
          fourniture des services et conformément aux obligations légales.
        </p>

        <h2>5. Vos droits</h2>
        <p>
          Conformément au RGPD, vous disposez des droits suivants :
        </p>
        <ul>
          <li>Droit d'accès à vos données</li>
          <li>Droit de rectification</li>
          <li>Droit à l'effacement</li>
          <li>Droit à la limitation du traitement</li>
          <li>Droit à la portabilité des données</li>
          <li>Droit d'opposition</li>
        </ul>

        <h2>6. Cookies</h2>
        <p>
          Notre site utilise des cookies pour améliorer votre expérience de navigation. 
          Les cookies sont de petits fichiers texte stockés sur votre appareil qui nous 
          aident à :
        </p>
        <ul>
          <li>Maintenir votre session connectée</li>
          <li>Mémoriser vos préférences</li>
          <li>Analyser l'utilisation de notre site</li>
        </ul>

        <h2>7. Partage des données</h2>
        <p>
          Nous ne partageons vos données personnelles qu'avec :
        </p>
        <ul>
          <li>Nos sous-traitants techniques (hébergement, paiement)</li>
          <li>Les autorités lorsque la loi l'exige</li>
        </ul>

        <h2>8. Contact</h2>
        <p>
          Pour toute question concernant notre politique de confidentialité ou pour 
          exercer vos droits, vous pouvez nous contacter à contact@oneli.fr.
        </p>

        <h2>9. Modifications</h2>
        <p>
          Nous nous réservons le droit de modifier cette politique de confidentialité 
          à tout moment. Les modifications entrent en vigueur dès leur publication 
          sur notre site.
        </p>
      </main>
    </div>
  );
};

export default Confidentialite;