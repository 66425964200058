
import { Link } from "react-router-dom";
import { Phone } from "lucide-react";

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="bg-gray-50 border-t mt-auto">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Logo et description */}
          <div className="space-y-3">
            <Link to="/" className="block">
              <img src="/lovable-uploads/66336247-5d75-4067-91cf-43e1c3bde31b.png" alt="Oneli" className="h-12" />
            </Link>
            <p className="text-gray-600 text-sm">
              Internet simplifié, rapide et abordable pour tous.
            </p>
          </div>

          {/* Navigation */}
          <div>
            <h3 className="font-semibold mb-3">Navigation</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/utilisations" className="text-gray-600 hover:text-primary text-sm">
                  La Box
                </Link>
              </li>
              <li>
                <Link to="/technologie" className="text-gray-600 hover:text-primary text-sm">
                  Technologie
                </Link>
              </li>
              <li>
                <Link to="/eligibilite" className="text-gray-600 hover:text-primary text-sm">
                  Test Éligibilité
                </Link>
              </li>
              <li>
                <Link to="/sans-fibre" onClick={handleScrollToTop} className="text-gray-600 hover:text-primary text-sm">
                  Sans Fibre ?
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="font-semibold mb-3">Contact</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/a-propos" className="text-gray-600 hover:text-primary text-sm">
                  À propos
                </Link>
              </li>
              <li>
                <a href="mailto:contact@oneli.fr" className="text-gray-600 hover:text-primary text-sm">
                  contact@oneli.fr
                </a>
              </li>
              <li className="flex items-center gap-2">
                <Phone className="h-4 w-4 text-gray-600" />
                <a href="tel:+33680909863" className="text-gray-600 hover:text-primary text-sm">
                  06 80 90 98 63
                </a>
              </li>
            </ul>
          </div>

          {/* Légal */}
          <div>
            <h3 className="font-semibold mb-3">Légal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/mentions-legales" className="text-gray-600 hover:text-primary text-sm">
                  Mentions légales
                </Link>
              </li>
              <li>
                <Link to="/confidentialite" className="text-gray-600 hover:text-primary text-sm">
                  Politique de confidentialité
                </Link>
              </li>
              <li>
                <Link to="/cgv" className="text-gray-600 hover:text-primary text-sm">
                  CGV
                </Link>
              </li>
              <li>
                <Link to="/tarifs" className="text-gray-600 hover:text-primary text-sm">
                  Tarifs
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t mt-6 pt-6 text-center text-sm text-gray-600">
          <p>© {new Date().getFullYear()} Oneli. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
