
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Gift, AlertCircle } from "lucide-react";
import { Badge } from "@/components/ui/badge";

export type PlanType = "MONTHLY" | "ANNUAL" | "PURCHASE";

interface SubscriptionPlansProps {
  selectedPlan: PlanType;
  setSelectedPlan: (plan: PlanType) => void;
}

export const SubscriptionPlans = ({
  selectedPlan,
  setSelectedPlan
}: SubscriptionPlansProps) => {
  return (
    <div className="space-y-6">
      <div className="bg-primary/10 rounded-lg p-4 flex items-center gap-3">
        <Gift className="h-5 w-5 text-primary" />
        <div>
          <div className="font-medium">Installation plug & play en quelques minutes</div>
          <div className="text-sm text-muted-foreground">
            Recevez votre box internet rapidement et sans contrainte
          </div>
        </div>
      </div>
      
      <div className="space-y-4">
        <RadioGroup
          value={selectedPlan}
          onValueChange={(value) => setSelectedPlan(value as PlanType)}
        >
          <div className="flex items-center space-x-2 rounded-lg border p-4">
            <RadioGroupItem value="MONTHLY" id="monthly" />
            <Label htmlFor="monthly" className="flex flex-col flex-1">
              <div className="flex items-center justify-between">
                <span className="font-medium">Forfait mensuel</span>
                <Badge className="bg-primary">Populaire</Badge>
              </div>
              <span className="text-sm text-muted-foreground">29,90€ par mois</span>
              <span className="text-sm text-primary">Sans engagement</span>
            </Label>
          </div>
          
          <div className="flex items-center space-x-2 rounded-lg border p-4">
            <RadioGroupItem value="PURCHASE" id="purchase" />
            <Label htmlFor="purchase" className="flex flex-col flex-1">
              <span className="font-medium">Achat du matériel</span>
              <span className="text-sm text-muted-foreground">179,90€ (paiement unique)</span>
              <span className="text-sm text-primary">Sans abonnement</span>
            </Label>
          </div>
          
          <div className="flex items-center space-x-2 rounded-lg border p-4 bg-gray-50">
            <RadioGroupItem value="ANNUAL" id="annual" disabled />
            <Label htmlFor="annual" className="flex flex-col flex-1 text-gray-500">
              <div className="flex items-center justify-between">
                <span className="font-medium">Offre TV</span>
                <Badge variant="outline" className="border-gray-300">Bientôt</Badge>
              </div>
              <span className="text-sm">Coming soon</span>
              <div className="text-sm flex items-center gap-1 mt-1">
                <AlertCircle className="h-3.5 w-3.5" />
                <span>Disponible prochainement</span>
              </div>
            </Label>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};
