
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useOnboarding } from "@/contexts/OnboardingContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

export const PasswordStep = () => {
  const { data, updateData, previousStep } = useOnboarding();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!data.password || data.password.length < 6) {
      setError("Le mot de passe doit contenir au moins 6 caractères");
      return;
    }

    setIsLoading(true);
    try {
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {
          data: {
            first_name: data.first_name,
            last_name: data.last_name,
          },
        },
      });

      if (signUpError) throw signUpError;

      if (authData.user) {
        // Mise à jour du profil
        const { error: profileError } = await supabase
          .from("profiles")
          .update({
            first_name: data.first_name,
            last_name: data.last_name,
            address: data.address,
          })
          .eq("id", authData.user.id);

        if (profileError) throw profileError;

        // Envoi de l'email de bienvenue
        const { error: emailError } = await supabase.functions.invoke("send-welcome-email", {
          body: {
            firstName: data.first_name,
            email: data.email,
          },
        });

        if (emailError) {
          console.error("Erreur lors de l'envoi de l'email de bienvenue:", emailError);
          // On ne bloque pas le processus si l'email échoue
        }

        toast({
          title: "Compte créé avec succès",
          description: "Vous allez être redirigé vers le paiement",
        });

        setTimeout(() => {
          navigate("/commander", { state: { showPayment: true } });
        }, 1500);
      }
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      setError("Une erreur est survenue lors de la création du compte");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-2xl font-bold">Dernière étape</h1>
        <p className="text-muted-foreground">
          Choisissez un mot de passe pour créer votre compte
        </p>
      </div>

      <div>
        <Label htmlFor="password">Mot de passe</Label>
        <Input
          id="password"
          type="password"
          value={data.password}
          onChange={(e) => updateData({ password: e.target.value })}
          placeholder="••••••••"
        />
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}

      <div className="flex gap-4">
        <Button type="button" variant="outline" onClick={previousStep} className="w-full">
          Retour
        </Button>
        <Button type="submit" className="w-full" disabled={isLoading}>
          {isLoading ? "Création du compte..." : "Créer mon compte"}
        </Button>
      </div>
    </form>
  );
};
