
import { Button } from "@/components/ui/button";
import Navbar from "@/components/Navbar";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

const Rendezvous = () => {
  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      
      <main className="container mx-auto px-4 pt-20 pb-8">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-4xl font-bold mb-8">
            Prenez rendez-vous avec nous
          </h1>
          
          <div className="mb-8 space-y-6">
            <p className="text-lg text-muted-foreground">
              Une question sur nos offres ? Besoin de comprendre comment notre solution peut s'adapter à vos besoins ? 
              Antoine et Richard sont là pour vous accompagner et répondre à toutes vos questions.
            </p>

            <div className="flex items-center justify-center gap-12 py-8">
              <div className="flex flex-col items-center gap-3">
                <Avatar className="w-24 h-24">
                  <AvatarImage src="/lovable-uploads/fbe031ed-ff3a-402d-a3d9-00a3d4e8fc11.png" alt="Photo d'Antoine" className="object-cover" />
                  <AvatarFallback>A</AvatarFallback>
                </Avatar>
                <span className="font-medium">Antoine</span>
              </div>
              <div className="flex flex-col items-center gap-3">
                <Avatar className="w-24 h-24">
                  <AvatarImage src="/lovable-uploads/511375aa-0d55-4b0a-8145-f06001e57da6.png" alt="Photo de Richard" className="object-cover" />
                  <AvatarFallback>R</AvatarFallback>
                </Avatar>
                <span className="font-medium">Richard</span>
              </div>
            </div>
          </div>

          <a 
            href="https://calendar.app.google/XZtP7DkLFx22WTJY7" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <Button size="lg" className="bg-primary hover:bg-primary-dark text-white">
              Prendre rendez-vous maintenant
            </Button>
          </a>
        </div>
      </main>
    </div>
  );
};

export default Rendezvous;
