

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui/alert-dialog";
import { useToast } from "@/components/ui/use-toast";
import { AlertCircle } from "lucide-react";

interface SubscriptionErrorDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  errorMessage: string;
}

export const SubscriptionErrorDialog = ({
  open,
  onOpenChange,
  errorMessage
}: SubscriptionErrorDialogProps) => {
  const { toast } = useToast();

  const handleRetry = () => {
    toast({
      title: "Nouvelle tentative",
      description: "Tentative de reconnexion au service de paiement...",
      duration: 3000
    });
    window.location.reload();
  };

  // Formater le message d'erreur pour une meilleure lisibilité
  const formatErrorMessage = (message: string) => {
    if (message.includes("Edge Function returned a non-2xx status code")) {
      return "Le service de paiement est temporairement indisponible. Veuillez réessayer dans quelques instants.";
    }
    if (message.includes("event loop error") || message.includes("Deno.core.runMicrotasks()")) {
      return "Une erreur technique est survenue avec notre service. Notre équipe a été notifiée et travaille à la résolution du problème.";
    }
    if (message.includes("TypeError")) {
      return "Une erreur technique s'est produite lors de la vérification de votre abonnement. Veuillez réessayer ou contacter le support.";
    }
    return message;
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <div className="flex items-center gap-2 text-destructive">
            <AlertCircle className="h-5 w-5" />
            <AlertDialogTitle>Erreur de paiement</AlertDialogTitle>
          </div>
          <AlertDialogDescription className="mt-2 text-base">
            {formatErrorMessage(errorMessage)}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Fermer</AlertDialogCancel>
          <AlertDialogAction onClick={handleRetry} className="bg-primary">
            Réessayer
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

