
import { useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useQueryClient } from "@tanstack/react-query";

type SyncOptions = {
  showToast?: boolean;
  onSuccess?: (data: any) => void;
  onError?: (error: Error) => void;
  limit?: number;
  processAll?: boolean;
  createMissingProfiles?: boolean;
  specificSubscriptionId?: string;
  specificUserEmail?: string;
  updateStripeCustomerId?: boolean;
  storeAllCustomerIds?: boolean;
  updateAllCustomerIds?: boolean;
  linkAllCustomerIds?: boolean;
  normalizeEmails?: boolean;
  debug?: boolean;
};

export const useSyncSubscriptions = () => {
  const { session } = useAuth();
  const [isSyncing, setIsSyncing] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const syncSubscriptions = async (options: SyncOptions = {}) => {
    const { 
      showToast = true, 
      onSuccess, 
      onError, 
      limit = 100,
      processAll = true,
      createMissingProfiles = true,
      specificSubscriptionId,
      specificUserEmail,
      updateStripeCustomerId = true,
      storeAllCustomerIds = true,
      updateAllCustomerIds = false,
      linkAllCustomerIds = false,
      normalizeEmails = false,
      debug = false
    } = options;

    if (!session?.access_token) {
      const error = new Error("Vous devez être connecté pour synchroniser les abonnements");
      if (onError) onError(error);
      throw error;
    }

    try {
      setIsSyncing(true);
      console.log("Synchronisation des abonnements...");
      console.log("Options:", { 
        limit, 
        processAll, 
        createMissingProfiles, 
        specificSubscriptionId,
        specificUserEmail,
        updateStripeCustomerId,
        storeAllCustomerIds,
        updateAllCustomerIds,
        linkAllCustomerIds,
        normalizeEmails,
        debug
      });
      
      // Si un ID d'abonnement spécifique est fourni, utiliser check-subscription
      if (specificSubscriptionId) {
        console.log(`Synchronisation d'un abonnement spécifique: ${specificSubscriptionId}`);
        const { data, error } = await supabase.functions.invoke('check-subscription', {
          body: { 
            subscriptionId: specificSubscriptionId,
            forceUpdate: true,
            updateStripeCustomerId,
            storeAllCustomerIds,
            userEmail: session.user.email // Ajouter l'email pour une vérification complète
          },
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        });

        if (error) {
          console.error("Erreur lors de la synchronisation de l'abonnement spécifique:", error);
          throw error;
        }

        console.log("Résultat de la synchronisation spécifique:", data);
        
        // Rafraîchir les données d'abonnement dans React Query
        queryClient.invalidateQueries({ queryKey: ["subscription", session.user.id] });
        
        if (showToast) {
          toast({
            title: "Synchronisation terminée",
            description: "L'abonnement a été mis à jour avec les données de Stripe",
          });
        }

        if (onSuccess) onSuccess(data);
        return data;
      } 
      // Si un email spécifique est fourni, vérifier cet utilisateur
      else if (specificUserEmail) {
        console.log(`Synchronisation pour l'email spécifique: ${specificUserEmail}`);
        const { data, error } = await supabase.functions.invoke('check-subscription', {
          body: { 
            userEmail: specificUserEmail,
            forceUpdate: true,
            updateStripeCustomerId,
            storeAllCustomerIds,
            checkByEmail: true, // Indique à l'API de rechercher par email
            linkAllCustomerIds, // Nouvel indicateur pour lier tous les comptes clients
            updateAllCustomerIds, // S'assurer que cette option est aussi transmise
            createMissingProfile: createMissingProfiles, // Ajouter l'option pour créer un profil manquant
            normalizeEmails, // Option pour gérer les différences de casse
            debug // Pour voir des informations de débogage détaillées
          },
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        });

        if (error) {
          console.error("Erreur lors de la synchronisation pour l'email spécifique:", error);
          throw error;
        }

        console.log("Résultat de la synchronisation par email:", data);
        
        // Vérifier si la réponse contient une erreur
        if (data?.error) {
          console.error("Erreur retournée par l'API:", data.error);
          throw new Error(data.error);
        }

        // Vérifier si la réponse contient un statut d'erreur
        if (data?.status === "error") {
          throw new Error(data.message || "Une erreur s'est produite");
        }
        
        // Rafraîchir toutes les données d'abonnement puisque cela pourrait affecter n'importe quel utilisateur
        queryClient.invalidateQueries({ queryKey: ["subscription"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });
        
        if (showToast) {
          let description = "Les données ont été mises à jour";
          
          if (data?.linked_customer_ids) {
            description = `${data.linked_customer_ids} comptes clients liés pour ${specificUserEmail}`;
          } else {
            description = `Les données pour ${specificUserEmail} ont été mises à jour`;
          }
          
          toast({
            title: "Synchronisation terminée",
            description: description,
          });
        }

        if (onSuccess) onSuccess(data);
        return data;
      }
      else {
        // Utiliser update-subscriptions pour synchroniser tous les abonnements
        const { data, error } = await supabase.functions.invoke('update-subscriptions', {
          body: { 
            user_id: session.user.id,
            limit,
            process_all: processAll,
            create_missing_profiles: createMissingProfiles,
            include_admin_subscriptions: true,
            update_stripe_customer_id: updateStripeCustomerId,
            store_all_customer_ids: storeAllCustomerIds,
            update_all_customer_ids: updateAllCustomerIds
          },
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        });

        if (error) {
          console.error("Erreur lors de la synchronisation des abonnements:", error);
          throw error;
        }

        console.log("Résultat de la synchronisation:", data);

        // Rafraîchir les données d'abonnement dans React Query
        queryClient.invalidateQueries({ queryKey: ["subscription"] });
        
        // Also refresh user data as we might have created new profiles or updated customer IDs
        queryClient.invalidateQueries({ queryKey: ["users"] });

        if (showToast) {
          let description = data.message || `${data.results?.processed || 0} utilisateurs traités, ${data.results?.updated || 0} abonnements mis à jour`;
          
          if (data.results?.profiles_created > 0) {
            description += `, ${data.results.profiles_created} profils créés`;
          }
          
          if (data.results?.customer_ids_updated > 0) {
            description += `, ${data.results.customer_ids_updated} customer IDs mis à jour`;
          }
          
          toast({
            title: "Synchronisation terminée",
            description: description,
          });
        }

        if (onSuccess) onSuccess(data);
        return data;
      }
    } catch (error) {
      console.error("Erreur lors de la synchronisation:", error);
      
      if (showToast) {
        toast({
          title: "Erreur de synchronisation",
          description: error.message || "Impossible de synchroniser les abonnements",
          variant: "destructive",
        });
      }
      
      if (onError) onError(error);
      throw error;
    } finally {
      setIsSyncing(false);
    }
  };

  return {
    syncSubscriptions,
    isSyncing
  };
};
