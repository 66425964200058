import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Loader2, Search, AlertCircle, Save, ExternalLink } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { useQueryClient } from "@tanstack/react-query";
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

export function FindStripeCustomerDialog() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<Array<{ 
    customer_id: string; 
    name?: string; 
    created?: number;
    email?: string;
    description?: string;
    metadata?: any;
    invoice_prefix?: string;
    default_source?: string;
    livemode?: boolean;
    subscription?: {
      id: string;
      status: string;
      current_period_end?: number;
      trial_end?: number;
      plan?: {
        id: string;
        nickname?: string;
        amount?: number;
        currency?: string;
      };
      items?: {
        data: Array<{
          id: string;
          price?: {
            id: string;
            nickname?: string;
            unit_amount?: number;
            currency?: string;
            product?: string;
          };
        }>;
      };
    } | null;
    all_subscriptions?: Array<{
      id: string;
      status: string;
      current_period_end?: number;
      trial_end?: number;
      plan?: {
        id: string;
        nickname?: string;
        amount?: number;
        currency?: string;
      };
      items?: {
        data: Array<{
          id: string;
          price?: {
            id: string;
            nickname?: string;
            unit_amount?: number;
            currency?: string;
            product?: string;
          };
        }>;
      };
    }>;
    isUpdated?: boolean;
    userId?: string;
  }>>([]);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [checkingSubscriptions, setCheckingSubscriptions] = useState(false);
  const [updatingDatabase, setUpdatingDatabase] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // États pour la dialogue de confirmation
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [customerToUpdate, setCustomerToUpdate] = useState<any>(null);
  const [emailMismatchWarning, setEmailMismatchWarning] = useState(false);
  const [updateError, setUpdateError] = useState("");

  const searchCustomer = async () => {
    if (!email.trim()) {
      setError("Veuillez saisir un email");
      return;
    }

    try {
      setError("");
      setIsLoading(true);
      
      console.log("Envoi de la requête à find-stripe-customer avec l'email:", email);
      
      const { data, error: requestError } = await supabase.functions.invoke('find-stripe-customer', {
        body: { email: email.trim() },
      });

      if (requestError) {
        console.error("Erreur lors de l'appel à l'edge function:", requestError);
        throw new Error(`Erreur lors de la recherche: ${requestError.message}`);
      }

      console.log("Réponse de l'edge function:", data);
      
      if (data && data.customers) {
        // Préparer les résultats avec les IDs d'utilisateur si disponibles
        const customersFound = data.customers.map(customer => ({
          ...customer,
          subscription: null,
          all_subscriptions: [],
          isUpdated: false,
          userId: data.userIds?.[customer.email] || null
        }));
        
        setResults(customersFound);
        
        if (customersFound.length === 0) {
          toast({
            title: "Aucun résultat",
            description: `Aucun client Stripe trouvé pour ${email}`,
          });
        } else {
          toast({
            title: "Recherche réussie",
            description: `${customersFound.length} client(s) trouvé(s)`,
          });
          
          // Maintenant, vérifions les abonnements pour chaque client
          setCheckingSubscriptions(true);
          const customersWithSubscriptions = await Promise.all(
            customersFound.map(async (customer) => {
              try {
                console.log(`Vérification des abonnements pour le client: ${customer.customer_id}`);
                
                // Utiliser check-subscription-by-customer avec des paramètres plus explicites
                const { data: subscriptionData, error: subError } = await supabase.functions.invoke('check-subscription-by-customer', {
                  body: { 
                    customerId: customer.customer_id,
                    includeAllSubscriptions: true,  // Assurez-vous que tous les abonnements sont inclus
                    includeInactive: true,  // Inclure aussi les abonnements inactifs/annulés
                  },
                });
                
                if (subError) {
                  console.error(`Erreur lors de la vérification des abonnements pour ${customer.customer_id}:`, subError);
                  return customer;
                }
                
                console.log(`Données d'abonnement pour ${customer.customer_id}:`, subscriptionData);
                
                // Vérifier si nous avons reçu des données d'abonnement
                if (!subscriptionData || (!subscriptionData.subscription && (!subscriptionData.all_subscriptions || subscriptionData.all_subscriptions.length === 0))) {
                  console.log(`Aucun abonnement trouvé pour ${customer.customer_id}`);
                  // Faire une deuxième vérification plus directe avec l'API Stripe via la fonction
                  const { data: directCheckData } = await supabase.functions.invoke('check-subscription-by-customer', {
                    body: { 
                      customerId: customer.customer_id,
                      forceDirectCheck: true,  // Force une vérification directe avec l'API Stripe
                      includeAllSubscriptions: true,
                      includeInactive: true,
                    },
                  });
                  
                  console.log(`Résultat de la vérification directe pour ${customer.customer_id}:`, directCheckData);
                  
                  if (directCheckData && (directCheckData.subscription || (directCheckData.all_subscriptions && directCheckData.all_subscriptions.length > 0))) {
                    return {
                      ...customer,
                      subscription: directCheckData.subscription || null,
                      all_subscriptions: directCheckData.all_subscriptions || []
                    };
                  }
                }
                
                return {
                  ...customer,
                  subscription: subscriptionData?.subscription || null,
                  all_subscriptions: subscriptionData?.all_subscriptions || []
                };
              } catch (err) {
                console.error(`Erreur lors de la vérification de l'abonnement pour ${customer.customer_id}:`, err);
                return customer;
              }
            })
          );
          
          setResults(customersWithSubscriptions);
          setCheckingSubscriptions(false);
        }
      } else {
        setResults([]);
        toast({
          title: "Aucun résultat",
          description: "Format de réponse inattendu",
          variant: "destructive",
        });
      }
    } catch (err) {
      console.error("Erreur:", err);
      setError(err.message || "Une erreur s'est produite");
      setResults([]);
      toast({
        title: "Erreur",
        description: err.message || "Une erreur s'est produite lors de la recherche",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
      setCheckingSubscriptions(false);
    }
  };

  const confirmUpdateCustomer = (customer) => {
    // Réinitialiser les états d'erreur
    setUpdateError("");
    
    if (!customer.email) {
      toast({
        title: "Erreur",
        description: "Email du client manquant, impossible de mettre à jour la base de données",
        variant: "destructive",
      });
      return;
    }

    // Vérifier si l'email recherché correspond à l'email du client Stripe
    if (email.toLowerCase() !== customer.email.toLowerCase()) {
      setEmailMismatchWarning(true);
    } else {
      setEmailMismatchWarning(false);
    }

    // Préparer la confirmation
    setCustomerToUpdate(customer);
    setConfirmDialogOpen(true);
  };

  const updateCustomerInDatabase = async () => {
    if (updatingDatabase || !customerToUpdate) return;
    
    const customer = customerToUpdate;
    
    try {
      setUpdatingDatabase(true);
      setUpdateError("");
      
      // Vérifier si des abonnements sont disponibles
      const hasActiveSubscription = customer.all_subscriptions?.some(sub => 
        ['active', 'trialing', 'past_due'].includes(sub.status)
      );
      
      const firstSubscriptionId = customer.all_subscriptions?.[0]?.id || null;
      const subscriptionStatus = hasActiveSubscription ? 'active' : 
                                 (customer.all_subscriptions?.[0]?.status || 'no_subscription');
      
      console.log(`Mise à jour de la base pour l'email ${customer.email} avec customer_id: ${customer.customer_id}`);
      console.log(`Statut d'abonnement: ${subscriptionStatus}, ID d'abonnement: ${firstSubscriptionId}`);
      
      // Améliorer le traitement des erreurs et fournir plus de contexte de débogage
      try {
        // Utiliser l'edge function check-subscription-by-customer avec l'option updateDatabase
        const { data, error } = await supabase.functions.invoke('check-subscription-by-customer', {
          body: { 
            customerId: customer.customer_id,
            userEmail: customer.email,
            forceDirectCheck: true,
            updateDatabase: true,
            updateSubscriptionId: firstSubscriptionId,
            updateSubscriptionStatus: subscriptionStatus,
            // Ajouter une option pour renforcer la vérification de l'email
            strictEmailCheck: false, // Désactiver la vérification stricte qui pourrait bloquer la mise à jour
            // Ajouter l'email utilisé pour la recherche pour vérifier si c'est le bon utilisateur
            searchEmail: email.trim().toLowerCase(),
            // Options supplémentaires pour gérer les différences de casse et les espaces
            normalizeEmails: true,
            // Forcer la création d'un profil si nécessaire
            createMissingProfile: true,
            // Ajouter un indicateur pour voir la version complète de la réponse
            debug: true
          },
        });
        
        console.log("Réponse complète de check-subscription-by-customer:", data);
        
        if (error) {
          console.error("Erreur lors de la mise à jour de la base de données:", error);
          setUpdateError(`Erreur de l'API: ${error.message || "Erreur inconnue"}`);
          throw new Error(`Erreur lors de la mise à jour: ${error.message}`);
        }
        
        if (data?.error) {
          console.error("Erreur retournée par la fonction:", data.error);
          setUpdateError(data.error);
          throw new Error(data.error);
        }
        
        if (data?.status === "error") {
          setUpdateError(`Statut d'erreur: ${data.message || "Erreur inconnue"}`);
          throw new Error(data.message || "Une erreur s'est produite avec statut d'erreur");
        }
        
        if (data?.userId) {
          console.log("Utilisateur trouvé avec l'ID:", data.userId);
        } else {
          console.warn("Aucun ID utilisateur n'a été retourné dans la réponse");
        }
        
        // Mettre à jour l'affichage
        setResults(prevResults => 
          prevResults.map(c => 
            c.customer_id === customer.customer_id 
              ? { ...c, isUpdated: true, userId: data?.userId || c.userId } 
              : c
          )
        );
        
        // Rafraîchir les données d'abonnement
        queryClient.invalidateQueries({ queryKey: ["subscription"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });
        
        toast({
          title: "Mise à jour réussie",
          description: `Le compte client a été associé à l'utilisateur ${customer.email}`,
        });
        
        // Fermer la dialogue de confirmation
        setConfirmDialogOpen(false);
      } catch (apiError) {
        console.error("Erreur détaillée lors de l'appel à l'API:", apiError);
        setUpdateError(apiError.message || "Erreur inconnue lors de l'appel à l'API");
        throw apiError;
      }
    } catch (err) {
      console.error("Erreur lors de la mise à jour:", err);
      toast({
        title: "Erreur",
        description: err.message || "Une erreur s'est produite lors de la mise à jour",
        variant: "destructive",
      });
    } finally {
      setUpdatingDatabase(false);
    }
  };

  const formatDate = (timestamp: number) => {
    if (!timestamp) return "N/A";
    return new Date(timestamp * 1000).toLocaleDateString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formatCurrency = (amount: number | undefined, currency: string | undefined) => {
    if (amount === undefined) return "N/A";
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency || 'EUR'
    });
    return formatter.format(amount / 100); // Stripe stores amounts in cents
  };

  const getStatusBadgeStyle = (status: string) => {
    switch (status) {
      case 'active':
        return "bg-green-50 text-green-700 border-green-200";
      case 'trialing':
        return "bg-blue-50 text-blue-700 border-blue-200";
      case 'past_due':
        return "bg-amber-50 text-amber-700 border-amber-200";
      case 'canceled':
        return "bg-red-50 text-red-700 border-red-200";
      case 'incomplete':
      case 'incomplete_expired':
        return "bg-orange-50 text-orange-700 border-orange-200";
      case 'unpaid':
        return "bg-purple-50 text-purple-700 border-purple-200";
      default:
        return "bg-gray-50 text-gray-700 border-gray-200";
    }
  };

  const openStripeCustomer = (customerId: string, livemode?: boolean) => {
    const stripeUrl = `https://dashboard.stripe.com/${livemode ? '' : 'test/'}customers/${customerId}`;
    window.open(stripeUrl, '_blank', 'noopener,noreferrer');
  };

  const openStripeSubscription = (subscriptionId: string, livemode?: boolean) => {
    const stripeUrl = `https://dashboard.stripe.com/${livemode ? '' : 'test/'}subscriptions/${subscriptionId}`;
    window.open(stripeUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" size="sm" className="gap-1">
            <Search className="h-4 w-4" />
            Rechercher client Stripe
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[800px] max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Rechercher un client Stripe par email</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4 py-4">
            <div className="flex gap-2">
              <Input
                type="email"
                placeholder="Email du client"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex-1"
              />
              <Button onClick={searchCustomer} disabled={isLoading}>
                {isLoading ? <Loader2 className="h-4 w-4 animate-spin" /> : "Rechercher"}
              </Button>
            </div>

            {error && <p className="text-destructive text-sm mt-2">{error}</p>}

            {checkingSubscriptions && (
              <div className="flex items-center justify-center gap-2 p-4 text-muted-foreground">
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Vérification des abonnements...</span>
              </div>
            )}

            {results.length > 0 && (
              <div className="border rounded-md overflow-hidden">
                <table className="w-full text-sm">
                  <thead className="bg-muted">
                    <tr>
                      <th className="text-left p-2">ID Client</th>
                      <th className="text-left p-2">Nom</th>
                      <th className="text-left p-2">Email</th>
                      <th className="text-left p-2">Description</th>
                      <th className="text-left p-2">Créé le</th>
                      <th className="text-left p-2">Mode</th>
                      <th className="text-left p-2">Utilisateur</th>
                      <th className="text-left p-2">Abonnement(s)</th>
                      <th className="text-left p-2">Plan / Prix</th>
                      <th className="text-left p-2">Fin d'abonnement</th>
                      <th className="text-left p-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.map((customer) => (
                      <tr key={customer.customer_id} className="border-t">
                        <td className="p-2 font-mono text-xs break-all">
                          <Button 
                            variant="link" 
                            size="sm" 
                            className="h-auto p-0 font-mono"
                            onClick={() => openStripeCustomer(customer.customer_id, customer.livemode)}
                          >
                            {customer.customer_id.substring(0, 10)}...
                            <ExternalLink className="h-3 w-3 ml-1" />
                          </Button>
                        </td>
                        <td className="p-2">{customer.name || "N/A"}</td>
                        <td className="p-2">{customer.email || "N/A"}</td>
                        <td className="p-2">{customer.description || "N/A"}</td>
                        <td className="p-2">{formatDate(customer.created || 0)}</td>
                        <td className="p-2">
                          <Badge variant={customer.livemode ? "default" : "outline"}>
                            {customer.livemode ? "Live" : "Test"}
                          </Badge>
                        </td>
                        <td className="p-2">
                          {customer.userId ? (
                            <Badge variant="outline" className="bg-green-50 text-green-700">
                              Associé
                            </Badge>
                          ) : (
                            <Badge variant="outline" className="bg-amber-50 text-amber-700">
                              Non associé
                            </Badge>
                          )}
                        </td>
                        <td className="p-2">
                          {customer.all_subscriptions && customer.all_subscriptions.length > 0 ? (
                            <div className="space-y-1">
                              {customer.all_subscriptions.map((sub, idx) => (
                                <div key={idx} className="flex items-center gap-1">
                                  <Badge 
                                    variant="outline" 
                                    className={getStatusBadgeStyle(sub.status)}
                                  >
                                    {sub.status}
                                  </Badge>
                                  <Button 
                                    variant="link" 
                                    size="sm" 
                                    className="h-auto p-0 font-mono text-xs"
                                    onClick={() => openStripeSubscription(sub.id, customer.livemode)}
                                  >
                                    {sub.id.substring(0, 8)}...
                                    <ExternalLink className="h-3 w-3 ml-1" />
                                  </Button>
                                </div>
                              ))}
                            </div>
                          ) : customer.subscription ? (
                            <Badge 
                              variant="outline" 
                              className={getStatusBadgeStyle(customer.subscription.status)}
                            >
                              {customer.subscription.status}
                            </Badge>
                          ) : (
                            <div className="flex items-center gap-1 text-muted-foreground">
                              <AlertCircle className="h-3 w-3" />
                              <span>Aucun</span>
                            </div>
                          )}
                        </td>
                        <td className="p-2">
                          {customer.all_subscriptions && customer.all_subscriptions.length > 0 ? (
                            <div className="space-y-1">
                              {customer.all_subscriptions.map((sub, idx) => (
                                <div key={idx} className="text-xs">
                                  {sub.plan?.nickname || (sub.items?.data?.[0]?.price?.nickname) || "N/A"}
                                  {sub.plan?.amount || sub.items?.data?.[0]?.price?.unit_amount ? 
                                    ` (${formatCurrency(
                                      sub.plan?.amount || sub.items?.data?.[0]?.price?.unit_amount, 
                                      sub.plan?.currency || sub.items?.data?.[0]?.price?.currency
                                    )})` : ""}
                                </div>
                              ))}
                            </div>
                          ) : customer.subscription?.plan?.nickname ? 
                            `${customer.subscription.plan.nickname} (${formatCurrency(customer.subscription.plan.amount, customer.subscription.plan.currency)})` : 
                            customer.subscription?.items?.data?.[0]?.price?.nickname ? 
                            `${customer.subscription.items.data[0].price.nickname} (${formatCurrency(customer.subscription.items.data[0].price.unit_amount, customer.subscription.items.data[0].price.currency)})` : 
                            "N/A"
                          }
                        </td>
                        <td className="p-2">
                          {customer.all_subscriptions && customer.all_subscriptions.length > 0 ? (
                            <div className="space-y-1">
                              {customer.all_subscriptions.map((sub, idx) => (
                                <div key={idx}>
                                  {sub.current_period_end 
                                    ? formatDate(sub.current_period_end)
                                    : sub.trial_end
                                      ? formatDate(sub.trial_end) + " (essai)"
                                      : "N/A"}
                                </div>
                              ))}
                            </div>
                          ) : customer.subscription?.current_period_end 
                            ? formatDate(customer.subscription.current_period_end)
                            : customer.subscription?.trial_end
                              ? formatDate(customer.subscription.trial_end) + " (essai)"
                              : "N/A"
                          }
                        </td>
                        <td className="p-2">
                          {customer.isUpdated ? (
                            <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
                              Mise à jour effectuée
                            </Badge>
                          ) : (
                            <Button 
                              size="sm" 
                              onClick={() => confirmUpdateCustomer(customer)}
                              disabled={updatingDatabase || !customer.email}
                              className="gap-1"
                            >
                              {updatingDatabase ? (
                                <Loader2 className="h-3 w-3 animate-spin" />
                              ) : (
                                <Save className="h-3 w-3" />
                              )}
                              Mettre à jour
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            
            {results.length > 0 && (
              <div className="mt-4">
                <h3 className="text-sm font-medium mb-2">Détails complets (pour le débogage):</h3>
                <div className="bg-muted p-4 rounded-md overflow-x-auto">
                  <pre className="text-xs">
                    {JSON.stringify(results, null, 2)}
                  </pre>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {/* Boîte de dialogue de confirmation */}
      <AlertDialog open={confirmDialogOpen} onOpenChange={setConfirmDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirmer la mise à jour</AlertDialogTitle>
            <AlertDialogDescription>
              Vous êtes sur le point d'associer le client Stripe <strong>{customerToUpdate?.customer_id}</strong> 
              à l'utilisateur avec l'email <strong>{customerToUpdate?.email}</strong>.
              
              {emailMismatchWarning && (
                <div className="mt-4 p-3 bg-amber-50 text-amber-800 border border-amber-200 rounded-md">
                  <strong>Attention :</strong> L'email du client Stripe ({customerToUpdate?.email}) 
                  ne correspond pas à l'email que vous avez recherché ({email}). 
                  Assurez-vous qu'il s'agit du bon compte utilisateur.
                </div>
              )}
              
              {updateError && (
                <div className="mt-4 p-3 bg-red-50 text-red-800 border border-red-200 rounded-md">
                  <strong>Erreur :</strong> {updateError}
                </div>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setCustomerToUpdate(null)}>Annuler</AlertDialogCancel>
            <AlertDialogAction 
              onClick={updateCustomerInDatabase} 
              className={emailMismatchWarning ? "bg-amber-600 hover:bg-amber-700" : ""}
              disabled={updatingDatabase}
            >
              {updatingDatabase ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Mise à jour en cours...
                </>
              ) : (
                emailMismatchWarning ? "Confirmer malgré l'avertissement" : "Confirmer"
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
