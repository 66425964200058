
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Building2, Briefcase, Home, Wifi, Shield, Clock, CheckCircle2, Zap } from "lucide-react";
import ProNavbar from "@/components/ProNavbar";
import { Card, CardContent } from "@/components/ui/card";

const Professionnel = () => {
  const industries = [
    {
      icon: <Building2 className="w-12 h-12 text-primary" />,
      title: "Commerces & Retail",
      description: "Assurez une connexion fiable pour vos terminaux de paiement et offrez du WiFi à vos clients."
    },
    {
      icon: <Briefcase className="w-12 h-12 text-primary" />,
      title: "Professions libérales",
      description: "Travaillez sans interruption avec une connexion de secours ou principale ultra-rapide."
    },
    {
      icon: <Home className="w-12 h-12 text-primary" />,
      title: "Immobilier & BTP",
      description: "Connectez vos chantiers et bureaux temporaires sans attendre l'installation d'une ligne fixe."
    },
    {
      icon: <Shield className="w-12 h-12 text-primary" />,
      title: "Sécurité & Services",
      description: "Connectez vos systèmes de sécurité, caméras et alarmes avec une solution fiable et rapide."
    }
  ];

  const benefits = [
    {
      icon: <Clock className="w-10 h-10 text-primary" />,
      title: "Installation en 30 secondes",
      description: "Branchez et utilisez immédiatement, sans technicien ni configuration complexe."
    },
    {
      icon: <Wifi className="w-10 h-10 text-primary" />,
      title: "Connexion multi-appareils",
      description: "Connectez jusqu'à 100 appareils simultanément sans perte de performance."
    },
    {
      icon: <Zap className="w-10 h-10 text-primary" />,
      title: "Haut débit garanti",
      description: "Jusqu'à 1 Gb/s en débit descendant, idéal pour vos applications professionnelles."
    },
    {
      icon: <CheckCircle2 className="w-10 h-10 text-primary" />,
      title: "Sans engagement",
      description: "Formules flexibles adaptées à vos besoins, sans frais cachés."
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <ProNavbar />

      {/* Hero Section */}
      <section className="pt-28 md:pt-32 px-4 bg-gradient-to-b from-gray-900 to-gray-800 text-white">
        <div className="container mx-auto pb-16">
          <div className="flex flex-col md:flex-row items-center gap-8 md:gap-16">
            <div className="md:w-1/2 space-y-6">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight">
                Internet professionnel <span className="text-white">sans contrainte</span>
              </h1>
              <p className="text-xl text-gray-300">
                La solution idéale pour les petites entreprises, commerces et professions libérales - sans installation, sans délai, sans engagement.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 pt-4">
                <Link to="/commander">
                  <Button size="lg" className="bg-primary hover:bg-primary-dark text-white w-full sm:w-auto">
                    Commander
                  </Button>
                </Link>
                <Link to="/professionnel/tarifs">
                  <Button 
                    size="lg" 
                    variant="outline" 
                    className="bg-white border-gray-900 text-gray-900 hover:bg-gray-100 w-full sm:w-auto"
                  >
                    Voir les tarifs
                  </Button>
                </Link>
              </div>
            </div>
            <div className="md:w-1/2 relative mt-8 md:mt-0">
              <img 
                src="/lovable-uploads/0e351631-58f5-46af-ae5b-72ee98194ebc.png"
                alt="Box Oneli Pro pour professionnels"
                className="w-full max-w-lg mx-auto rounded-lg shadow-2xl"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Industries Section */}
      <section className="py-16 px-4">
        <div className="container mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">
              Une solution pour tous les professionnels
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Que vous soyez commerçant, profession libérale, agent immobilier ou artisan, notre box s'adapte à vos besoins spécifiques.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {industries.map((industry, index) => (
              <Card key={index} className="border-none shadow-md hover:shadow-xl transition-all">
                <CardContent className="p-6 text-center">
                  <div className="mx-auto w-20 h-20 bg-primary/10 rounded-full flex items-center justify-center mb-4">
                    {industry.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{industry.title}</h3>
                  <p className="text-gray-600">{industry.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="md:w-1/2">
              <h2 className="text-3xl font-bold mb-6">
                Pourquoi choisir Oneli Pro ?
              </h2>
              <div className="space-y-6">
                {benefits.map((benefit, index) => (
                  <div key={index} className="flex gap-4">
                    <div className="flex-shrink-0 w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center">
                      {benefit.icon}
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-1">{benefit.title}</h3>
                      <p className="text-gray-600">{benefit.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="md:w-1/2 bg-white p-8 rounded-xl shadow-lg">
              <h3 className="text-2xl font-bold mb-6 text-center">Cas d'usage professionnels</h3>
              <ul className="space-y-4">
                {[
                  "Connexion principale pour petites entreprises",
                  "Ligne de secours en cas de panne",
                  "Connexion temporaire pour événements",
                  "Solution pour locaux temporaires et chantiers",
                  "Connectivité pour terminaux de paiement",
                  "WiFi client pour commerces et hôtellerie"
                ].map((item, index) => (
                  <li key={index} className="flex items-center gap-3">
                    <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 px-4 bg-primary text-white">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">
            Prêt à simplifier votre connectivité professionnelle ?
          </h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Nos conseillers sont à votre disposition pour vous accompagner et vous proposer une solution adaptée à vos besoins.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link to="/commander">
              <Button 
                size="lg" 
                className="bg-white text-primary hover:bg-gray-100 w-full sm:w-auto"
              >
                Commander
              </Button>
            </Link>
            <a href="tel:+33680909863">
              <Button 
                size="lg" 
                className="bg-transparent border-2 border-white text-white hover:bg-white/20 w-full sm:w-auto"
              >
                Nous appeler
              </Button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Professionnel;
