
import { CogIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { useState } from "react";
import { SyncOptions } from "../types/subscription-sync.types";

interface SyncSettingsProps {
  options: SyncOptions;
  setOptions: (options: Partial<SyncOptions>) => void;
}

export const SyncSettings = ({ options, setOptions }: SyncSettingsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localOptions, setLocalOptions] = useState<SyncOptions>({ ...options });

  const handleSave = () => {
    setOptions(localOptions);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setLocalOptions({ ...options });
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" onClick={() => setLocalOptions({ ...options })}>
          <CogIcon className="mr-2 h-4 w-4" />
          Options
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Options de synchronisation</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="limit" className="col-span-2">
              Limite d'utilisateurs
            </Label>
            <Input
              id="limit"
              type="number"
              min="1"
              max="1000"
              value={localOptions.limit}
              onChange={(e) => 
                setLocalOptions({ ...localOptions, limit: Number(e.target.value) })
              }
              className="col-span-2"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="processAll" className="col-span-3">
              Traiter tous les utilisateurs
            </Label>
            <Switch
              id="processAll"
              checked={localOptions.processAll}
              onCheckedChange={(checked) => 
                setLocalOptions({ ...localOptions, processAll: checked })
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="createMissingProfiles" className="col-span-3">
              Créer les profils manquants
            </Label>
            <Switch
              id="createMissingProfiles"
              checked={localOptions.createMissingProfiles}
              onCheckedChange={(checked) => 
                setLocalOptions({ ...localOptions, createMissingProfiles: checked })
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="updateAllCustomerIds" className="col-span-3">
              Mettre à jour tous les Customer IDs
            </Label>
            <Switch
              id="updateAllCustomerIds"
              checked={localOptions.updateAllCustomerIds}
              onCheckedChange={(checked) => 
                setLocalOptions({ ...localOptions, updateAllCustomerIds: checked })
              }
            />
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <Button variant="outline" onClick={handleCancel}>
            Annuler
          </Button>
          <Button onClick={handleSave}>
            Enregistrer
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
