
import ProNavbar from "@/components/ProNavbar";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Building2, Briefcase, Home, Shield, Quote } from "lucide-react";

const CasClients = () => {
  const testimonials = [
    {
      icon: <Building2 className="w-8 h-8 text-primary" />,
      industry: "Commerce de détail",
      name: "Boulangerie Dupain",
      testimonial: "Grâce à Oneli Pro, notre terminal de paiement fonctionne sans interruption, même quand la ligne fixe a des problèmes. Nos clients peuvent toujours payer par carte, ce qui est essentiel pour notre activité.",
      person: "Marie Dupain, Gérante",
      image: "/lovable-uploads/e2a32165-5dc0-4d50-ba76-567a54203d5b.png"
    },
    {
      icon: <Briefcase className="w-8 h-8 text-primary" />,
      industry: "Profession libérale",
      name: "Cabinet d'avocats Legrand",
      testimonial: "En tant qu'avocat, je ne peux pas me permettre de perdre la connexion lors de consultations en visioconférence. Oneli Pro me sert de solution principale dans mon nouveau cabinet et de backup dans l'ancien.",
      person: "Pierre Legrand, Avocat",
      image: "/lovable-uploads/77f67ea1-b8df-4133-91dc-9e6fad06c9ac.png"
    },
    {
      icon: <Home className="w-8 h-8 text-primary" />,
      industry: "Agence immobilière",
      name: "Immobilier Horizon",
      testimonial: "Notre agence utilise Oneli Pro pour connecter rapidement nos nouvelles boutiques sans attendre l'installation de la fibre. Nous l'utilisons aussi lors des journées portes ouvertes dans les résidences.",
      person: "Sophie Martin, Directrice",
      image: "/lovable-uploads/74465d5b-b19d-4c60-939d-b1a1b4a58263.png"
    },
    {
      icon: <Shield className="w-8 h-8 text-primary" />,
      industry: "Entreprise de sécurité",
      name: "SecurGuard",
      testimonial: "Nous avons déployé les box Oneli Pro pour connecter nos caméras de surveillance sur plusieurs sites clients. La fiabilité est au rendez-vous et l'installation se fait en quelques minutes seulement.",
      person: "Jean Lefort, Responsable technique",
      image: "/lovable-uploads/12f32b7e-5fae-498e-827f-e274411382bf.png"
    }
  ];

  const caseStudies = [
    {
      title: "Café-restaurant 'Le Central'",
      challenge: "Proposer du WiFi fiable aux clients tout en assurant la connectivité des terminaux de paiement",
      solution: "Installation d'une box Oneli Pro Performance avec configuration d'un réseau WiFi public séparé",
      results: [
        "Augmentation de 30% du temps passé par les clients",
        "Fiabilité à 100% des terminaux de paiement",
        "Installation réalisée en moins de 5 minutes"
      ],
      image: "/lovable-uploads/396f26fa-11d2-47ed-b8f4-9823fcd633bf.png"
    },
    {
      title: "Chantier de construction 'Résidence Les Oliviers'",
      challenge: "Connecter un bureau de vente temporaire sur un chantier sans infrastructure fixe",
      solution: "Déploiement d'une box Oneli Pro Essentiel pour l'équipe commerciale sur site",
      results: [
        "Signature électronique des contrats en temps réel",
        "Connexion stable pour les visites virtuelles du projet",
        "Solution déplaçable à chaque phase du chantier"
      ],
      image: "/lovable-uploads/8121f16e-5885-4007-a489-6897c95e3313.png"
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <ProNavbar />
      
      <div className="pt-28">
        {/* Hero Section */}
        <section className="container mx-auto px-4 py-12">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl font-bold mb-6">Nos clients témoignent</h1>
            <p className="text-xl text-gray-600 mb-8">
              Découvrez comment des professionnels de tous secteurs utilisent Oneli Pro au quotidien pour améliorer leur connectivité.
            </p>
          </div>
        </section>
        
        {/* Testimonials Section */}
        <section className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold text-center mb-12">Ils ont choisi Oneli Pro</h2>
          
          <div className="grid md:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <Card key={index} className="border-none shadow-md h-full">
                <CardHeader className="flex flex-row items-center gap-4">
                  <div className="bg-primary/10 p-3 rounded-full">
                    {testimonial.icon}
                  </div>
                  <div>
                    <CardTitle>{testimonial.name}</CardTitle>
                    <CardDescription>{testimonial.industry}</CardDescription>
                  </div>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="flex">
                    <Quote className="h-8 w-8 text-primary/20 mr-2 flex-shrink-0" />
                    <p className="italic text-gray-600">{testimonial.testimonial}</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <img 
                      src={testimonial.image} 
                      alt={testimonial.person}
                      className="h-10 w-10 rounded-full object-cover"
                    />
                    <span className="font-medium">{testimonial.person}</span>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </section>
        
        {/* Case Studies Section */}
        <section className="bg-gray-50 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Études de cas</h2>
            
            <div className="space-y-12">
              {caseStudies.map((study, index) => (
                <div key={index} className="bg-white rounded-xl shadow-md overflow-hidden">
                  <div className="flex flex-col md:flex-row">
                    <div className="md:w-2/5">
                      <img 
                        src={study.image} 
                        alt={study.title}
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <div className="md:w-3/5 p-8">
                      <h3 className="text-2xl font-bold mb-4">{study.title}</h3>
                      
                      <div className="space-y-4">
                        <div>
                          <h4 className="font-semibold text-gray-900">Le défi</h4>
                          <p className="text-gray-600">{study.challenge}</p>
                        </div>
                        
                        <div>
                          <h4 className="font-semibold text-gray-900">Notre solution</h4>
                          <p className="text-gray-600">{study.solution}</p>
                        </div>
                        
                        <div>
                          <h4 className="font-semibold text-gray-900">Résultats obtenus</h4>
                          <ul className="list-disc pl-5 text-gray-600">
                            {study.results.map((result, resultIndex) => (
                              <li key={resultIndex}>{result}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        {/* CTA Section */}
        <section className="container mx-auto px-4 py-16">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Vous aussi, adoptez Oneli Pro</h2>
            <p className="text-xl text-gray-600 mb-8">
              Rejoignez nos clients satisfaits et transformez votre connectivité professionnelle dès aujourd'hui.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <Link to="/commander">
                <Button size="lg">
                  Tester Gratuitement
                </Button>
              </Link>
              <Link to="/professionnel/tarifs">
                <Button size="lg" variant="outline">
                  Voir nos offres
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CasClients;
