
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CalendarIcon, CreditCard, Mail, ShieldCheck, User } from "lucide-react";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface SubscriptionProps {
  subscribed: boolean;
  status: string;
  trial_end: string | null;
  current_period_end: string;
  created_at: string;
  subscription_id: string;
  customer_id: string;
  all_customer_ids?: string[];
}

interface ActiveSubscriptionDetailsProps {
  subscription: SubscriptionProps;
  showDetailedInfo?: boolean;
}

export const ActiveSubscriptionDetails: React.FC<ActiveSubscriptionDetailsProps> = ({ subscription, showDetailedInfo = false }) => {
  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return format(date, "d MMMM yyyy", { locale: fr });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Date inconnue";
    }
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'active':
        return <Badge className="bg-green-100 text-green-800">Actif</Badge>;
      case 'trialing':
        return <Badge className="bg-blue-100 text-blue-800">Essai</Badge>;
      case 'past_due':
        return <Badge className="bg-orange-100 text-orange-800">Impayé</Badge>;
      case 'canceled':
        return <Badge className="bg-red-100 text-red-800">Annulé</Badge>;
      case 'unpaid':
        return <Badge className="bg-red-100 text-red-800">Non payé</Badge>;
      default:
        return <Badge className="bg-gray-100 text-gray-800">{status}</Badge>;
    }
  };

  return (
    <Card className="shadow-md">
      <CardContent className="grid gap-4">
        <div className="flex items-center space-x-4">
          <ShieldCheck className="h-5 w-5 text-green-500" />
          <h2 className="text-lg font-semibold">Abonnement Actif</h2>
          {getStatusBadge(subscription.status)}
        </div>

        {showDetailedInfo && (
          <div className="grid gap-2">
            <div className="flex items-center space-x-2">
              <CreditCard className="h-4 w-4 text-gray-500" />
              <span>ID de l'abonnement:</span>
              <span className="font-medium">{subscription.subscription_id}</span>
            </div>

            <div className="flex items-center space-x-2">
              <User className="h-4 w-4 text-gray-500" />
              <span>ID du client:</span>
              <span className="font-medium">{subscription.customer_id}</span>
            </div>

            <div className="flex items-center space-x-2">
              <CalendarIcon className="h-4 w-4 text-gray-500" />
              <span>Date de création:</span>
              <span className="font-medium">{formatDate(subscription.created_at)}</span>
            </div>

            {subscription.trial_end && (
              <div className="flex items-center space-x-2">
                <CalendarIcon className="h-4 w-4 text-gray-500" />
                <span>Fin de la période d'essai:</span>
                <span className="font-medium">{formatDate(subscription.trial_end)}</span>
              </div>
            )}

            <div className="flex items-center space-x-2">
              <CalendarIcon className="h-4 w-4 text-gray-500" />
              <span>Fin de la période actuelle:</span>
              <span className="font-medium">{formatDate(subscription.current_period_end)}</span>
            </div>
          </div>
        )}

        <Button>Gérer l'abonnement</Button>
      </CardContent>
    </Card>
  );
};

