
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CircuitBoard, Wifi, Signal, Network, Router, Shield, Tv, SmartphoneNfc } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import Navbar from "@/components/Navbar";

const Technologie = () => {
  const features = [
    {
      icon: <CircuitBoard className="w-12 h-12 text-primary" />,
      title: "Technologie de pointe",
      description:
        "Notre box utilise les dernières innovations en matière de connectivité sans fil pour vous offrir une connexion stable et rapide.",
      bgGradient: "bg-gradient-to-br from-purple-100 to-purple-200",
    },
    {
      icon: <Wifi className="w-12 h-12 text-primary" />,
      title: "WiFi 6 nouvelle génération",
      description:
        "Profitez d'une connexion WiFi ultra-rapide et stable, même avec plusieurs appareils connectés simultanément.",
      bgGradient: "bg-gradient-to-br from-blue-50 to-blue-100",
    },
    {
      icon: <Signal className="w-12 h-12 text-primary" />,
      title: "Couverture optimale",
      description:
        "Notre technologie assure une excellente couverture dans toute votre habitation, sans zones mortes.",
      bgGradient: "bg-gradient-to-br from-green-50 to-green-100",
    },
    {
      icon: <Network className="w-12 h-12 text-primary" />,
      title: "Multi-réseaux intelligents",
      description:
        "La box sélectionne automatiquement le meilleur réseau disponible pour garantir une connexion optimale en permanence.",
      bgGradient: "bg-gradient-to-br from-yellow-50 to-yellow-100",
    },
    {
      icon: <Router className="w-12 h-12 text-primary" />,
      title: "Installation plug & play",
      description:
        "Branchez simplement votre box et elle se configure automatiquement. Aucune compétence technique requise.",
      bgGradient: "bg-gradient-to-br from-pink-50 to-pink-100",
    },
    {
      icon: <Shield className="w-12 h-12 text-primary" />,
      title: "Sécurité renforcée",
      description:
        "Protection avancée contre les cybermenaces et chiffrement de dernière génération pour une connexion sûre et privée.",
      bgGradient: "bg-gradient-to-br from-indigo-50 to-indigo-100",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
      <Navbar />
      
      <main className="container mx-auto px-4 pt-32 pb-16">
        <div className="text-center mb-16 animate-fade-in">
          <h1 className="text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary-light py-2">
            Une technologie révolutionnaire
          </h1>
          <p className="text-xl text-muted-foreground max-w-3xl mx-auto leading-relaxed">
            Découvrez comment notre technologie innovante rend l'accès à Internet plus simple que jamais.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <Card 
              key={index} 
              className={`group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 overflow-hidden ${feature.bgGradient}`}
            >
              <CardHeader className="relative">
                <div className="mb-4 transform transition-transform group-hover:scale-110">
                  {feature.icon}
                </div>
                <CardTitle className="text-2xl mb-2 text-gray-800">
                  {feature.title}
                </CardTitle>
                <CardDescription className="text-base text-gray-600">
                  {feature.description}
                </CardDescription>
              </CardHeader>
            </Card>
          ))}
        </div>

        {/* Encart pour la connexion multiple */}
        <div className="mb-16 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-2xl p-8 shadow-lg">
          <div className="flex flex-col md:flex-row items-center gap-8">
            <div className="flex-1 space-y-4">
              <h2 className="text-3xl font-bold text-gray-800">
                Plus de 100 appareils connectés simultanément
              </h2>
              <p className="text-lg text-gray-600 leading-relaxed">
                Notre box peut gérer plus de 100 appareils connectés en même temps, sans perte de performance. 
                Connectez tous vos appareils en toute simplicité :
              </p>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                <div className="flex items-center gap-2">
                  <Tv className="w-5 h-5 text-primary" />
                  <span>Smart TV</span>
                </div>
                <div className="flex items-center gap-2">
                  <SmartphoneNfc className="w-5 h-5 text-primary" />
                  <span>Smartphones</span>
                </div>
                <div className="flex items-center gap-2">
                  <Signal className="w-5 h-5 text-primary" />
                  <span>Caméras IP</span>
                </div>
                <div className="flex items-center gap-2">
                  <Shield className="w-5 h-5 text-primary" />
                  <span>Alarmes</span>
                </div>
                <div className="flex items-center gap-2">
                  <Network className="w-5 h-5 text-primary" />
                  <span>Domotique</span>
                </div>
                <div className="flex items-center gap-2">
                  <Wifi className="w-5 h-5 text-primary" />
                  <span>Assistants vocaux</span>
                </div>
              </div>
            </div>
            <div className="flex-1 relative">
              <div className="relative w-full h-64 bg-gradient-to-br from-primary/5 to-primary/10 rounded-xl overflow-hidden">
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="relative">
                    <div className="w-24 h-24 bg-primary/10 rounded-full absolute animate-ping" />
                    <Router className="w-16 h-16 text-primary relative z-10" />
                  </div>
                  <div className="absolute inset-0">
                    {[...Array(8)].map((_, i) => (
                      <div
                        key={i}
                        className="absolute w-2 h-2 bg-primary/20 rounded-full animate-pulse"
                        style={{
                          top: `${Math.random() * 100}%`,
                          left: `${Math.random() * 100}%`,
                          animationDelay: `${i * 0.2}s`,
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center space-y-8 animate-fade-in">
          <div className="max-w-3xl mx-auto bg-white p-8 rounded-2xl shadow-lg">
            <h2 className="text-3xl font-bold mb-4 text-gray-800">
              Une solution clé en main
            </h2>
            <p className="text-lg text-gray-600 mb-8 leading-relaxed">
              Notre box internet nouvelle génération combine les meilleures technologies pour vous offrir une expérience utilisateur incomparable. Plus besoin de technicien ou de configuration complexe.
            </p>
            <Link to="/commander">
              <Button 
                size="lg" 
                className="bg-primary hover:bg-primary-dark transform transition-transform hover:scale-105"
              >
                Commander ma box
              </Button>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Technologie;
