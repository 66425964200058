import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const formSchema = z.object({
  firstName: z.string().min(1, "Le prénom est requis"),
  lastName: z.string().min(1, "Le nom est requis"),
  phoneNumber: z.string().regex(/^\d{10}$/, "Le numéro doit contenir exactement 10 chiffres"),
  address: z.string().min(1, "L'adresse est requise"),
  email: z.string().email("Format d'email invalide"),
});

interface PersonalInfoFormProps {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  email: string;
  onFirstNameChange: (value: string) => void;
  onLastNameChange: (value: string) => void;
  onPhoneNumberChange: (value: string) => void;
  onAddressChange: (value: string) => void;
  onEmailChange: (value: string) => void;
}

export const PersonalInfoForm = ({
  firstName,
  lastName,
  phoneNumber,
  address,
  email,
  onFirstNameChange,
  onLastNameChange,
  onPhoneNumberChange,
  onAddressChange,
  onEmailChange,
}: PersonalInfoFormProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName,
      lastName,
      phoneNumber,
      address,
      email,
    },
  });

  const handleFieldChange = (field: string, value: string, onChange: (value: string) => void) => {
    onChange(value);
    form.setValue(field as keyof z.infer<typeof formSchema>, value);
  };

  return (
    <Form {...form}>
      <form className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex items-center">
                  Prénom
                  <span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    value={firstName}
                    onChange={(e) => handleFieldChange('firstName', e.target.value, onFirstNameChange)}
                    placeholder="Votre prénom"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex items-center">
                  Nom
                  <span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    value={lastName}
                    onChange={(e) => handleFieldChange('lastName', e.target.value, onLastNameChange)}
                    placeholder="Votre nom"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center">
                Email
                <span className="text-red-500 ml-1">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="email"
                  value={email}
                  onChange={(e) => handleFieldChange('email', e.target.value, onEmailChange)}
                  placeholder="Votre email"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex items-center">
                  Numéro de téléphone
                  <span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => handleFieldChange('phoneNumber', e.target.value, onPhoneNumberChange)}
                    placeholder="0612345678"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex items-center">
                  Adresse postale
                  <span className="text-red-500 ml-1">*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    value={address}
                    onChange={(e) => handleFieldChange('address', e.target.value, onAddressChange)}
                    placeholder="Votre adresse postale"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
};