
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";

export const useSubscriptionData = () => {
  const { session } = useAuth();
  const { toast } = useToast();
  
  return useQuery({
    queryKey: ["subscription", session?.user?.id],
    queryFn: async () => {
      if (!session?.access_token || !session?.user?.id) {
        throw new Error("No active session or user ID");
      }

      try {
        // Récupérer directement l'abonnement de l'utilisateur à partir de la base de données
        const { data: subscriptionData, error: subError } = await supabase
          .from("subscriptions")
          .select("*")
          .eq("user_id", session.user.id)
          .order('created_at', { ascending: false })
          .limit(1)
          .maybeSingle(); // Using maybeSingle instead of single to avoid errors if no record found

        if (subError) {
          console.log("Aucun abonnement trouvé dans la base de données:", subError);
          return { 
            subscribed: false,
            status: "inactive",
            trial_end: null,
            current_period_end: new Date().toISOString(),
            created_at: new Date().toISOString(),
            subscription_id: "",
            customer_id: "",
            all_customer_ids: []
          };
        }

        console.log("Données d'abonnement de la base:", subscriptionData);
        
        // Handling null subscriptionData explicitly
        if (!subscriptionData) {
          console.log("Aucun abonnement trouvé (null data)");
          return { 
            subscribed: false,
            status: "inactive",
            trial_end: null,
            current_period_end: new Date().toISOString(),
            created_at: new Date().toISOString(),
            subscription_id: "",
            customer_id: "",
            all_customer_ids: []
          };
        }
        
        console.log("Customer IDs stockés:", subscriptionData.all_stripe_customer_ids);

        // Si on n'a pas d'ID d'abonnement Stripe, on considère qu'il n'y a pas d'abonnement actif
        if (!subscriptionData?.stripe_subscription_id || subscriptionData.status === 'canceled') {
          return { 
            subscribed: false,
            status: subscriptionData?.status || "inactive",
            trial_end: subscriptionData?.trial_end || null,
            current_period_end: subscriptionData?.updated_at || new Date().toISOString(),
            created_at: subscriptionData?.created_at || new Date().toISOString(),
            subscription_id: subscriptionData?.stripe_subscription_id || "",
            customer_id: subscriptionData?.stripe_customer_id || "",
            all_customer_ids: subscriptionData?.all_stripe_customer_ids || [subscriptionData?.stripe_customer_id].filter(Boolean)
          };
        }

        // Vérifier directement avec l'API Stripe si l'abonnement est toujours actif
        if (subscriptionData.stripe_subscription_id) {
          try {
            console.log("Vérification de l'abonnement Stripe:", subscriptionData.stripe_subscription_id);
            
            // Vérifier d'abord avec l'ID client principal
            let response;
            try {
              response = await supabase.functions.invoke('check-subscription', {
                body: { 
                  subscriptionId: subscriptionData.stripe_subscription_id,
                  forceUpdate: true, // Forcer la mise à jour des données
                  updateStripeCustomerId: true, // Cette option permet de mettre à jour l'ID client Stripe si nécessaire
                  storeAllCustomerIds: true, // Option pour stocker tous les IDs clients
                  userEmail: session.user.email // Fournir l'email pour la vérification complète
                }
              });
            } catch (invokeError) {
              console.error("Erreur lors de l'appel à check-subscription:", invokeError);
              throw new Error("Erreur de connexion au service Stripe");
            }
            
            const { data: stripeData, error: stripeError } = response || {};
            
            if (stripeError) {
              console.error("Erreur lors de la vérification Stripe:", stripeError);
            }
            
            // Si on a des problèmes avec l'abonnement principal, essayer de vérifier tous les IDs clients
            // pour trouver un abonnement actif
            if ((!stripeData || stripeData.status === 'canceled') && 
                subscriptionData.all_stripe_customer_ids?.length > 0) {
              console.log("Vérification de tous les IDs clients pour trouver un abonnement actif");
              
              // Parcourir tous les IDs clients
              for (const customerId of subscriptionData.all_stripe_customer_ids) {
                if (customerId === subscriptionData.stripe_customer_id) continue; // Déjà vérifié
                
                console.log("Vérification de l'ID client alternatif:", customerId);
                
                // Vérifier si cet ID client a un abonnement actif
                let customerResponse;
                try {
                  customerResponse = await supabase.functions.invoke('check-subscription-by-customer', {
                    body: {
                      customerId,
                      updateDatabase: true,
                      userEmail: session.user.email
                    }
                  });
                } catch (invokeError) {
                  console.error(`Erreur lors de l'appel à check-subscription-by-customer pour ${customerId}:`, invokeError);
                  continue; // Passer au prochain ID client en cas d'erreur
                }
                
                const { data: customerData, error: customerError } = customerResponse || {};
                
                if (!customerError && customerData) {
                  console.log("Données de l'ID client alternatif:", customerData);
                  
                  // Si cet ID client a un abonnement actif et a mis à jour la base de données
                  if (customerData.updateResult && 
                     customerData.subscriptions?.some(sub => ['active', 'trialing', 'past_due'].includes(sub.status))) {
                    
                    console.log("Un abonnement actif a été trouvé et la base a été mise à jour");
                    
                    // Récupérer les données mises à jour
                    const { data: updatedSubData, error: updatedError } = await supabase
                      .from("subscriptions")
                      .select("*")
                      .eq("user_id", session.user.id)
                      .order('created_at', { ascending: false })
                      .limit(1)
                      .maybeSingle();
                    
                    if (!updatedError && updatedSubData) {
                      subscriptionData.status = updatedSubData.status;
                      subscriptionData.stripe_customer_id = updatedSubData.stripe_customer_id;
                      subscriptionData.stripe_subscription_id = updatedSubData.stripe_subscription_id;
                      subscriptionData.all_stripe_customer_ids = updatedSubData.all_stripe_customer_ids;
                      subscriptionData.trial_end = updatedSubData.trial_end;
                      
                      // Utiliser les données mises à jour pour le reste du traitement
                      console.log("Utilisation des données mises à jour:", updatedSubData);
                    }
                    
                    break;
                  }
                }
              }
            }
            
            if (!stripeError && stripeData) {
              console.log("Réponse de Stripe:", stripeData);
              
              // Fusionner les données de la base avec celles de Stripe
              subscriptionData.status = stripeData.status || subscriptionData.status;
              subscriptionData.trial_end = stripeData.trial_end ? new Date(stripeData.trial_end * 1000).toISOString() : subscriptionData.trial_end;
              
              // Liste complète des IDs clients
              const allCustomerIds = stripeData.all_customer_ids || subscriptionData.all_stripe_customer_ids || [];
              
              // Mise à jour de la base de données si les données diffèrent
              if ((stripeData.status && stripeData.status !== subscriptionData.status) || 
                  (stripeData.customer_id && stripeData.customer_id !== subscriptionData.stripe_customer_id) ||
                  (stripeData.all_customer_ids && JSON.stringify(stripeData.all_customer_ids) !== JSON.stringify(subscriptionData.all_stripe_customer_ids))) {
                console.log("Mise à jour des données dans la base de données:", {
                  status: stripeData.status,
                  customer_id: stripeData.customer_id,
                  all_customer_ids: stripeData.all_customer_ids
                });
                
                const updateData: any = {
                  updated_at: new Date().toISOString()
                };
                
                if (stripeData.status && stripeData.status !== subscriptionData.status) {
                  updateData.status = stripeData.status;
                }
                
                if (stripeData.customer_id && stripeData.customer_id !== subscriptionData.stripe_customer_id) {
                  updateData.stripe_customer_id = stripeData.customer_id;
                  console.log("Mise à jour de l'ID client Stripe principal:", stripeData.customer_id);
                }
                
                if (stripeData.all_customer_ids) {
                  updateData.all_stripe_customer_ids = stripeData.all_customer_ids;
                  console.log("Mise à jour de tous les IDs clients Stripe:", stripeData.all_customer_ids);
                }
                
                if (subscriptionData.id) {
                  const { error: updateError } = await supabase
                    .from('subscriptions')
                    .update(updateData)
                    .eq('id', subscriptionData.id);
                  
                  if (updateError) {
                    console.error("Erreur lors de la mise à jour des données:", updateError);
                  }
                } else {
                  console.warn("Impossible de mettre à jour l'abonnement: ID manquant");
                }
              }
              
              // Déterminer la date de fin de période
              const periodEnd = stripeData.current_period_end ? 
                new Date(stripeData.current_period_end * 1000).toISOString() : 
                subscriptionData.updated_at;
              
              // Vérifier si l'abonnement est valide selon son statut
              const isValid = ['active', 'trialing', 'past_due'].includes(stripeData.status || subscriptionData.status);
              
              return {
                subscribed: isValid,
                status: stripeData.status || subscriptionData.status,
                trial_end: subscriptionData.trial_end,
                current_period_end: periodEnd,
                created_at: subscriptionData.created_at,
                subscription_id: subscriptionData.stripe_subscription_id,
                customer_id: stripeData.customer_id || subscriptionData.stripe_customer_id,
                all_customer_ids: stripeData.all_customer_ids || subscriptionData.all_stripe_customer_ids || [subscriptionData.stripe_customer_id].filter(Boolean)
              };
            }
          } catch (err) {
            console.error("Erreur lors de la vérification Stripe:", err);
            // En cas d'erreur, on continue avec les données de la base
          }
        }
        
        // Si on n'a pas pu vérifier avec Stripe, on utilise uniquement les données de la base
        const isValid = ['active', 'trialing', 'past_due'].includes(subscriptionData.status);
        
        return {
          subscribed: isValid,
          status: subscriptionData.status,
          trial_end: subscriptionData.trial_end,
          current_period_end: subscriptionData.updated_at, // Fallback sur updated_at
          created_at: subscriptionData.created_at,
          subscription_id: subscriptionData.stripe_subscription_id || "",
          customer_id: subscriptionData.stripe_customer_id,
          all_customer_ids: subscriptionData.all_stripe_customer_ids || [subscriptionData.stripe_customer_id].filter(Boolean)
        };
      } catch (error) {
        console.error("Error checking subscription:", error);
        toast({
          title: "Erreur",
          description: "Impossible de vérifier votre abonnement",
          variant: "destructive",
        });
        return { 
          subscribed: false,
          status: "error",
          trial_end: null,
          current_period_end: new Date().toISOString(),
          created_at: new Date().toISOString(),
          subscription_id: "",
          customer_id: "",
          all_customer_ids: []
        };
      }
    },
    enabled: !!session?.access_token && !!session?.user?.id,
    retry: 3,
    retryDelay: 1000,
    staleTime: 30000, // 30 secondes
    refetchOnWindowFocus: true,
  });
};
